import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useUser } from './UserContext';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const { user } = useUser();
    const baseUrl = window.location.hostname === "therai.me" ? process.env.REACT_APP_API_BASE_URL_THERAI : window.location.hostname === "dept.me" ? process.env.REACT_APP_API_BASE_URL_ADEPT : process.env.REACT_APP_API_BASE_URL_OWNAI;
    const wsUrl = baseUrl.replace(/^http/, 'ws') + '/ws';
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [reconnectDelay, setReconnectDelay] = useState(1000);
    const [attemptedReconnects, setAttemptedReconnects] = useState(0);

    // Heartbeat mechanism to keep the connection alive
    const sendPing = () => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ type: 'ping' }));
        }
    };

    useEffect(() => {
        let shouldReconnect = true;

        const connect = () => {
            if (!user || !user.uid || !shouldReconnect) return;

            socketRef.current = new WebSocket(`${wsUrl}/${user.uid}`);

            socketRef.current.onopen = () => {
                setInterval(sendPing, 28000); // Send ping every 28 seconds
                setIsConnected(true);
            };

            socketRef.current.onclose = (e) => {
                setIsConnected(false);
                if (e.code === 1000 || e.code === 1001) {
                    shouldReconnect = false;
                }
                if (shouldReconnect && attemptedReconnects < 5) {
                    setTimeout(connect, reconnectDelay);
                    setAttemptedReconnects(prevAttemptedReconnects => prevAttemptedReconnects + 1);
                    setReconnectDelay(prevDelay => Math.min(prevDelay * 2, 30000)); // Increase delay exponentially, but cap at 30 seconds
                }
            };

            socketRef.current.onerror = (err) => {
                // console.error('WebSocket encountered error:', err, 'Closing socket');
                // shouldReconnect = false;
                socketRef.current.close();
            };
        };

        connect();

        return () => {
            shouldReconnect = false;
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [wsUrl]);

    return (
        <WebSocketContext.Provider value={{ socket: socketRef.current, isConnected }}>
            {children}
        </WebSocketContext.Provider>
    );
};