import { db } from './firebaseConfig';
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, deleteDoc, deleteField, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const getUserWithProfiles = async (firebaseUserId) => {
    const userData = await getUser(firebaseUserId);
    const backendProfiles = await getBackendProfiles(firebaseUserId);
    return { userData, backendProfiles };
}

export const toggleUserToggle = async (firebaseUserId, toggles, isClass) => {
    const userRef = doc(db, 'users', firebaseUserId);
    if (isClass) {
        await updateDoc(userRef, { classToggles: toggles });
    } else {
        await updateDoc(userRef, { userToggles: toggles });
    }
}

const setUserCreatedAt = async (firebaseUserId) => {
    const userRef = doc(db, 'users', firebaseUserId);
    await setDoc(userRef, { createdAt: new Date() }, { merge: true });
}

// CreateDefaultUser creates a new user in Firestore with default settings
export const createDefaultUser = async (user) => {
    // create user document
    await setUserCreatedAt(user.uid);
    await setUserEmail(user.uid, user.email);
    await setTokenLimit(user.uid, 1000);
    const defaultProfile = uuidv4();
    await addBackendProfileId(user.uid, { id: defaultProfile, name: 'Default', isClass: false });
}

export const getUser = async (firebaseUserId) => {
    const userRef = doc(db, 'users', firebaseUserId);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        // Handle the case where the document does not exist
        throw new Error('No such document in Firestore!');
    }
}

export const ensureDefaultAccountSettings = async (firebaseUser) => {
    const userRef = doc(db, 'users', firebaseUser.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
        const userData = userSnap.data();
        if (!userData.tokenLimit) {
            await setTokenLimit(firebaseUser.uid, userData.tier === 'free' ? 1000 : userData.tier === 'pro' ? 5000 : userData.tier === 'unlimited' ? 15000 : 1000);
        }
    } else {
        await createDefaultUser(firebaseUser);
    }

    // ensure backend profile exists
    const profiles = await getBackendProfiles(firebaseUser.uid);
    if (profiles.length === 0) {
        await createDefaultProfile(firebaseUser.uid);
    }
}

const setTokenLimit = async (firebaseUserId, tokenLimit) => {
    const userRef = doc(db, 'users', firebaseUserId);
    await setDoc(userRef, { tokenLimit }, { merge: true });
}

// updates the last used context window for the user (0-100)
export const updateLastUsedContextWindow = async (firebaseUserId, contextWindow) => {
    if (!contextWindow) {
        throw new Error('Context window must be provided');
    }
    const userRef = doc(db, 'users', firebaseUserId);
    await setDoc(userRef, { lastUsedContextWindow: contextWindow }, { merge: true });
}

export const updateLastUsedProfile = async (firebaseUserId, profileId) => {
    const userRef = doc(db, 'users', firebaseUserId);
    await setDoc(userRef, { lastUsedProfile: profileId }, { merge: true });
}

export const addUserTokens = async (firebaseUserId, tokens) => {
    const userRef = doc(db, 'users', firebaseUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
        const userData = userSnap.data();
        const tokensUsed = userData.tokensUsed || 0;
        const newTokens = tokensUsed + tokens;
        await setDoc(userRef, { tokensUsed: newTokens }, { merge: true });
    } else {
        throw new Error('User document does not exist');
    }
}

const setUserEmail = async (firebaseUserId, userEmail) => {
    const userRef = doc(db, 'users', firebaseUserId);
    await setDoc(userRef, { userEmail }, { merge: true });
}

// Profiles
const backendProfilesCollection = collection(db, 'backendProfiles');

export const createDefaultProfile = async (firebaseUserId) => {
    const id = uuidv4(); // Generate a unique ID for the profile
    const defaultProfile = { id, name: 'Default', userId: firebaseUserId };
    await setDoc(doc(backendProfilesCollection, id), defaultProfile);
};

export const addBackendProfileId = async (firebaseUserId, backendProfile) => {
    const profileWithUser = { ...backendProfile, userId: firebaseUserId };
    await setDoc(doc(backendProfilesCollection, backendProfile.id), profileWithUser);
};

export const updateBackendProfile = async (profileId, backendProfile) => {
    const profileRef = doc(backendProfilesCollection, profileId);
    await updateDoc(profileRef, backendProfile);
};

export const deleteBackendProfile = async (profileId) => {
    const profileRef = doc(backendProfilesCollection, profileId);
    await deleteDoc(profileRef);
};

export const getBackendProfiles = async (firebaseUserId) => {
    const q = query(backendProfilesCollection, where('userId', '==', firebaseUserId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data());
}

export const newBackendProfile = async (firebaseUserId, profileName, isClass) => {
    const id = uuidv4();
    await addBackendProfileId(firebaseUserId, { id: id, name: profileName, isClass: isClass });
    return id;
};

// Conversations
const conversationsCollection = collection(db, 'conversations');

export const createOrUpdateConversation = async (firebaseUserId, conversation) => {
    const conversationId = conversation.id || uuidv4();
    const conversationRef = doc(conversationsCollection, conversationId);
    await setDoc(conversationRef, { ...conversation, userId: firebaseUserId }, { merge: true });
};

export const deleteConversation = async (conversationId) => {
    const conversationRef = doc(conversationsCollection, conversationId);
    await deleteDoc(conversationRef);
};

export const getConversations = async (firebaseUserId) => {
    const q = query(conversationsCollection, where('userId', '==', firebaseUserId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data());
};

export const onConversationUpdate = (conversationId, callback) => {
    const conversationRef = doc(conversationsCollection, conversationId);
    return onSnapshot(conversationRef, (doc) => {
        if (doc.exists()) {
            callback(doc.data());
        }
    });
};

// Migrations
export const migrateBackendProfilesToCollection = async () => {
    const usersCollectionRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersCollectionRef);

    for (const userDoc of querySnapshot.docs) {
        const userData = userDoc.data();
        const firebaseUserId = userDoc.id;
        if (userData.backendProfiles && userData.backendProfiles.length > 0) {
            for (const profile of userData.backendProfiles) {
                const newProfile = { ...profile, userId: firebaseUserId };
                await setDoc(doc(backendProfilesCollection, profile.id), newProfile);
            }
            await updateDoc(doc(db, 'users', firebaseUserId), { backendProfiles: deleteField() });
        }
    }
};

export const migrateConversationsToCollection = async () => {
    const usersCollectionRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersCollectionRef);

    for (const userDoc of querySnapshot.docs) {
        const userData = userDoc.data();
        const firebaseUserId = userDoc.id;
        if (userData.conversations && userData.conversations.length > 0) {
            for (const conversation of userData.conversations) {
                // Ensure that the conversation has a valid id before proceeding
                if (conversation.id) {
                    const newConversation = { ...conversation, userId: firebaseUserId };
                    await setDoc(doc(conversationsCollection, conversation.id), newConversation);
                } else {
                    console.error("Found a conversation without an id for user:", firebaseUserId);
                }
            }
            // Remove the conversations field from the user document after migration
            await updateDoc(doc(db, 'users', firebaseUserId), { conversations: deleteField() });
        }
    }
};
