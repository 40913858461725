import React from 'react';
import styled from 'styled-components';
import { FiFile, FiX } from 'react-icons/fi';
import {useTheme} from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";

const FileContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sidebarBackground};
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileSquareIcon = styled(FiFile)`
  color: ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.greenFade};
  border-radius: 12px;
  padding: 10px;
`;

const FileTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FileName = styled(CustomText)`
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FileType = styled(CustomText)`
  margin-left: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
  opacity: 0.6;
`;

const RemoveButton = styled(FiX)`
  cursor: pointer;
  margin-left: 10px;
  opacity: 0;
  
  ${FileContainer}:hover & {
    opacity: 1;
  }
`;

const FileIcon = ({ fileName, del, onRemove }) => {
    const { theme } = useTheme();

    // if fileName ends in . something get the file type
    const fileType = fileName.split('.').pop();

    return (
        <FileContainer theme={theme}>
            <FileSquareIcon theme={theme} style={{ color: theme.name === "dark" ? theme.colors.white : theme.colors.grey }} size={20}/>
            <FileTexts>
                <FileName style={{ color: theme.name === "dark" ? theme.colors.white : theme.colors.grey }}>{fileName}</FileName>
                <FileType style={{ color: theme.name === "dark" ? theme.colors.white : theme.colors.grey }} type="medium">{fileType}</FileType>
            </FileTexts>
            {del && <RemoveButton onClick={onRemove} theme={theme} style={{ color: theme.name === "dark" ? theme.colors.white : theme.colors.grey }} />}
        </FileContainer>
    );
};

export default FileIcon;
