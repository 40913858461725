import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";

const SwitchLabel = styled.label`
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  --background: ${(props) => props.theme.colors.border};
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: .5s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 50%;
    left: 10%;
    bottom: 15%;
    box-shadow: inset 8px -4px 0px 0px ${(props) => props.theme.colors.primary};
    background: var(--background);
    transition: .5s;
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${(props) => props.theme.colors.primary};
  }

  ${HiddenCheckbox}:checked + &:before {
    transform: translateX(130%);
    box-shadow: inset 15px -4px 0px 15px ${(props) => props.theme.colors.yellow};
  }
`;

const ThemeToggle = () => {
    const { theme, toggleTheme } = useTheme();
    const [isChecked, setIsChecked] = useState(theme.name === 'light');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        toggleTheme();
    }

    return (
        <SwitchLabel>
            <HiddenCheckbox
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <Slider theme={theme} />
        </SwitchLabel>
    );
}

export default ThemeToggle;
