import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import BouncingCircles from "../atoms/BouncingLoader";
import { PiXBold, PiPencilSimpleBold, PiCheckBold } from "react-icons/pi";
import CustomInput from "../atoms/CustomInput";
import CustomButton from "../atoms/CustomButton";
import CustomSelector from "./CustomSelector";
import CustomSlider from "../atoms/CustomSlider";
import {useLoading} from "../../contexts/LoadingContext";

const EssayMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 1;
  background-color: ${props => props.theme.colors.sidebarBackground};
`;

const StyledEssayMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: transparent;
  align-items: flex-start;
  margin: 5px 0;
  max-width: 65%;
  min-width: 65%;
  align-self: center;
  
  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const StyledEssayMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
`;

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  margin: 5px 0 10px -15px;
  background-color: ${props => props.theme.colors.background};
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 15px;
`;

const StyledEssayMessageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  
  }
`;

const StyledEssayMessageButton = styled(CustomButton)`
  margin-top: 20px;
`;

const EssayMessageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0;
`;

const EssayMessageTitle = styled(CustomText)`
  font-size: 1.15rem;
  line-height: 1.5;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.05em;
`;

const EssayMessageTextLarge = styled(CustomText)`
  font-size: 1.15rem;
`;

const EssayMessageText = styled(CustomText)`
  font-size: 1.1rem;
`;

const EssayMessageLabel = styled(CustomText)`
  font-size: .9rem;
  margin-right: 5px;
  display: inline-block;
  
  background: ${(props) => props.theme.colors.strokeFaded};
  padding: 2px 6px;
  border-radius: 5px;
  opacity: 0.6;
`;

const FieldIcons = styled.div`
  display: none;
  flex-direction: row;
  opacity: 0.8;
  margin-left: 20px;
  gap: 3px;
  z-index: 999;
  
  @media (max-width: 768px) {
    position: relative;
    justify-content: flex-end;
  }
`;

const CheckIcon = styled(PiCheckBold)`
    cursor: pointer;
    color: ${props => props.theme.colors.text};
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
`;

const EditIcon = styled(PiPencilSimpleBold)`
    cursor: pointer;
    color: ${props => props.theme.colors.text};
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
`;

const DeleteIcon = styled(PiXBold)`
    cursor: pointer;
    color: ${props => props.theme.colors.text};
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
`;

const EditableFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  
  &:hover {
    ${FieldIcons} {
      display: flex;
    }
  }
`;

const EditableFieldTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  z-index: 1;
  width: 100%;
`;

const getParagraphName = (index, total) => {
    if (total === 1) return "Content";

    switch (index) {
        case 0:
            return "Introduction";
        case total - 1:
            return "Conclusion";
        default:
            return `Body ${index}`;
    }
};

const EssayMessage = ({ response, last, handleSendEssay }) => {
    const { theme } = useTheme();
    const { loading, loadingState } = useLoading();

    const capitalizedAcademicLevel = response.academic_level.charAt(0).toUpperCase() + response.academic_level.slice(1) || "";

    const [title, setTitle] = useState(response.title || "");
    const [missionStatement, setMissionStatement] = useState(response.mission_statement || "");
    const [tone, setTone] = useState(response.tone || "");
    const [academicLevel, setAcademicLevel] = useState(capitalizedAcademicLevel);
    const [length, setLength] = useState(response.length || 500);
    const [paragraphs, setParagraphs] = useState(response.paragraphs || [""]);

    const [editMode, setEditMode] = useState({
        title: false,
        missionStatement: false,
        tone: false,
        academicLevel: false,
        length: false,
        paragraphs: new Array(paragraphs.length).fill(false)
    });

    const toneOptions = [
        { id: 'informal', name: 'Informal' },
        { id: 'formal', name: 'Formal' },
        { id: 'objective', name: 'Objective' },
        { id: 'analytical', name: 'Analytical' },
        { id: 'persuasive', name: 'Persuasive' },
        { id: 'narrative', name: 'Narrative' },
        { id: 'descriptive', name: 'Descriptive' },
        { id: 'academic', name: 'Academic' },
        { id: 'professional', name: 'Professional' },
        { id: 'casual', name: 'Casual' },
    ];

    const academicLevelOptions = [
        { id: 'high school', name: 'High School' },
        { id: 'undergraduate', name: 'Undergraduate' },
        { id: 'masters', name: 'Masters' },
        { id: 'phd', name: 'PhD' },
    ];

    const handleDeleteParagraph = (index) => {
        const updatedParagraphs = paragraphs.filter((_, i) => i !== index);
        setParagraphs(updatedParagraphs);
        // Also update the edit mode state
        let newEditMode = [...editMode.paragraphs];
        newEditMode.splice(index, 1);
        setEditMode({...editMode, paragraphs: newEditMode});
    };

    const handleSubmitClick = () => {
        handleSendEssay({
            title,
            missionStatement,
            tone,
            academic_level: academicLevel,
            length: parseInt(length),
            paragraphs
        });
    }

    const renderEditableField = (theme, field, value, setValue, Component, textStyle, label = null, isParagraph = false, index = null) => {
        const isEditing = isParagraph ? editMode.paragraphs[index] : editMode[field];

        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                setEditMode({ ...editMode, [field]: false });
            }
        };

        const handleSetEditMode = (value) => {
            if (isParagraph) {
                let newEditMode = Array.isArray(editMode.paragraphs) ? [...editMode.paragraphs] : [];
                newEditMode[index] = value;
                setEditMode({...editMode, paragraphs: newEditMode});
            } else {
                setEditMode({ ...editMode, [field]: value });
            }
        }

        // remove "introduction: " and "conclusion: " from the paragraph text
        if (isParagraph && value.toLowerCase().startsWith("introduction: ")) {
            value = value.slice(13)
        } else if (isParagraph && value.toLowerCase().startsWith("conclusion: ")) {
            value = value.slice(11)
        }

        if (field === 'tone' || field === 'academicLevel') {
            return (
                <EditableFieldContainer>
                    {label && <EssayMessageLabel type="semiBold" theme={theme}>{label}</EssayMessageLabel>}
                    <CustomSelector
                        placeholder={field === 'tone' ? 'Select Tone' : 'Select Academic Level'}
                        items={field === 'tone' ? toneOptions : academicLevelOptions}
                        onSelect={(id) => setValue(id)}
                        currentItemId={value}
                    />
                </EditableFieldContainer>
            );
        }

        if (field === 'length') {
            return (
                <EditableFieldContainer>
                    {label && <EssayMessageLabel type="semiBold" theme={theme}>{label}</EssayMessageLabel>}
                    <CustomSlider
                        min={100}
                        max={3000}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </EditableFieldContainer>
            );
        }

        return (
            <EditableFieldContainer>
                <EditableFieldTextContainer>
                    {isEditing ? (
                        <>
                            {label && <EssayMessageLabel type="semiBold" theme={theme}>{label}</EssayMessageLabel>}
                            <CustomInput
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                onBlur={() => setEditMode({ ...editMode, [field]: false })}
                                onKeyPress={handleKeyPress}
                                minRows={isParagraph ? 3 : 1}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </>

                    ) : (
                        <Component type={textStyle} onClick={() => setEditMode({ ...editMode, [field]: true })}>
                            {label && <EssayMessageLabel type="semiBold" theme={theme}>{label}</EssayMessageLabel>}
                            {value}
                        </Component>
                    )}
                </EditableFieldTextContainer>
                <FieldIcons>
                    {isEditing ? (
                        <CheckIcon onClick={() => handleSetEditMode(false)} size={20} theme={theme} />
                    ) : (
                        <EditIcon onClick={() => handleSetEditMode(true)} size={20} theme={theme} />
                    )}
                    {isParagraph && <DeleteIcon onClick={() => handleDeleteParagraph(index)} size={20} theme={theme} />}
                </FieldIcons>
            </EditableFieldContainer>
        );
    };

    return (
        <EssayMessageContainer theme={theme}>
            <StyledEssayMessageContent>
                <EssayMessageText style={{ paddingBottom: 20, marginTop: -10, borderBottom: `1px solid ${theme.colors.stroke}` }}>
                    I've prepared an outline here. Feel free to edit it as you see it.
                </EssayMessageText>
                {renderEditableField(theme, "missionStatement", missionStatement, setMissionStatement, EssayMessageTextLarge, "regular", "Mission Statement")}

                <ParagraphContainer theme={theme}>
                    {renderEditableField(theme, "title", title, setTitle, EssayMessageTitle, "semiBold", "Title")}
                    {paragraphs.map((paragraph, index) => (
                        <EssayMessageRow key={index}>
                            {renderEditableField(theme, "paragraphs", paragraph, (newText) => {
                                let newParagraphs = [...paragraphs];
                                newParagraphs[index] = newText;
                                setParagraphs(newParagraphs);
                            }, EssayMessageText, "regular", getParagraphName(index, paragraphs.length), true, index)}
                        </EssayMessageRow>
                    ))}
                </ParagraphContainer>

                <StyledEssayMessageBox theme={theme}>
                    <StyledEssayMessageRow theme={theme}>
                        {renderEditableField(theme, "tone", tone, setTone, EssayMessageText, "regular", "Tone")}
                        {renderEditableField(theme, "academicLevel", academicLevel, setAcademicLevel, EssayMessageText, "regular", "Academic Level")}
                    </StyledEssayMessageRow>
                    {renderEditableField(theme, "length", length, setLength, EssayMessageText, "regular", "Length (words)")}
                </StyledEssayMessageBox>
            </StyledEssayMessageContent>
            {!loading &&
                <StyledEssayMessageButton onClick={handleSubmitClick} type="roundedLarge">
                    Start Writing
                </StyledEssayMessageButton>
            }
            {last && loading && <BouncingCircles text={loadingState}/>}
        </EssayMessageContainer>
    );
};

export default EssayMessage;