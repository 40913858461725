import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { useUser } from '../../contexts/UserContext';
import CustomText from '../atoms/CustomText';
import {cleanNodeType} from "../../utils/utils";
import { PiBooksDuotone, PiUserDuotone } from "react-icons/pi";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  justify-content: space-between;
  background: ${props => props.theme.colors.sidebarBackground};
  border-radius: 15px;
  padding: 10px;
  
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 3px 8px;
`;

const CardTitle = styled(CustomText)`
  font-size: 1.2rem;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  gap: 5px;
`;

const CardSubtitleLabel = styled(CustomText)`
  font-size: 1rem;
  opacity: 0.7;
`;

const CardSubtitle = styled(CustomText)`
  font-size: 1.15rem;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

const CardText = styled(CustomText)`
  font-size: 1.1rem;
  opacity: 0.9;
  white-space: nowrap;        
`;

const ProfileCardItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  gap: 20px;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    width: 220px;
  }
`;

const ProfileCardItemEndContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;

const ProfileCardItemBar = styled.div`
  width: ${props => props.width}%;
  height: 14px;
  background: ${props => props.theme.colors.primary};
  border-radius: 10px;
`;

const ProfileCardItem = ({ nodeType, count, totalNodes }) => {
    const { theme } = useTheme();

    const shortedNodeType = nodeType.length > 8 ? cleanNodeType(nodeType).substring(0, 8) + '...' : cleanNodeType(nodeType);

    return (
        <ProfileCardItemContainer>
            <CardText theme={theme} type="medium" style={{opacity: 0.9}}>{shortedNodeType}</CardText>
            <ProfileCardItemEndContainer>
                <ProfileCardItemBar theme={theme} width={count / totalNodes * 150} />
                <CardSubtitle theme={theme} type="semiBold" style={{ opacity: 0.9 }}>
                    {count}
                </CardSubtitle>
            </ProfileCardItemEndContainer>
        </ProfileCardItemContainer>
    );
}

const ProfileCard = ({ profileData }) => {
    const { theme } = useTheme();
    const { user } = useUser();

    const selectedProfile = user.backendProfiles.find(profile => profile.id === user.selectedBackendProfileId);
    const selectedProfileName = selectedProfile ? selectedProfile.name || 'Unnamed Profile' : 'Unnamed Profile';
    const selectedProfileClass = selectedProfile ? selectedProfile.isClass || false : false;

    const totalNodeTypes = profileData ? Object.keys(profileData).length : 0;
    const totalNodes = profileData ? Object.values(profileData).reduce((acc, val) => acc + val.length, 0) : 0;

    const nodeTypeCounts = profileData ? Object.keys(profileData).map(nodeType => {
        return {
            nodeType,
            count: profileData[nodeType].length
        };
    }).sort((a, b) => b.count - a.count) : [];
    const topThreeNodeTypes = nodeTypeCounts.slice(0, 3);

    return (
        <Card theme={theme}>
            <CardSection>
                <CardTitle theme={theme} type="medium">
                    {selectedProfileClass ? <PiBooksDuotone /> : <PiUserDuotone />}
                    {selectedProfileName}
                </CardTitle>
                <CardSubtitle theme={theme} type="medium">
                    {totalNodeTypes}
                    <CardSubtitleLabel>Types</CardSubtitleLabel>
                </CardSubtitle>
                <CardSubtitle theme={theme} type="medium">
                    {totalNodes}
                    <CardSubtitleLabel>Nodes</CardSubtitleLabel>
                </CardSubtitle>
            </CardSection>
            <CardSection>
                {topThreeNodeTypes.map((item, index) => (
                    <ProfileCardItem
                        nodeType={item.nodeType}
                        count={item.count}
                        totalNodes={totalNodes}
                        key={index} // Use the index as the key
                    />
                ))}
            </CardSection>
        </Card>
    );
};

export default ProfileCard;