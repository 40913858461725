import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import CustomText from "../components/atoms/CustomText";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 55%;
  min-width: 55%;
  margin-top: 40px;
  position: relative;
  padding-bottom: 100px;
  
  @media (max-width: 1200px) {
    max-width: 70%;
    min-width: 70%;
  }
  
  @media (max-width: 800px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const Title = styled(CustomText)`
  font-size: 1.5em;
  margin-bottom: 20px;
  align-self: center;
`;

const Subtitle = styled(CustomText)`
  font-size: 1.1em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 3px;
`;

const DescriptionText = styled(CustomText)`
  font-size: 1.2em;
  opacity: 0.75;
  text-align: left;
`;

const BackButton = styled(IoArrowBack)`
  font-size: 1.7em;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    opacity: 1;
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 800px) {
    font-size: 1.5em;
  }
`;

const PrivacyPolicyScreen = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <ScreenContainer theme={theme}>
            <BackButton onClick={handleBack} theme={theme} />
            <InnerContainer theme={theme}>
                <Title type="medium">Privacy Policy</Title>

                <Subtitle type="medium">Introduction</Subtitle>
                <DescriptionText>
                    This Privacy Policy explains how OwnAI ("we", "us", "our") collects, uses, and protects personal information of the users ("you", "your") of our AI chat service. Your privacy is important to us.
                </DescriptionText>

                <Subtitle type="medium">Data Collection</Subtitle>
                <DescriptionText>
                    We collect personal information that you provide when creating an account, such as your name, email address, and preferences. We also collect data related to your use of the service, including your conversations with the AI.
                </DescriptionText>

                <Subtitle type="medium">Data Usage</Subtitle>
                <DescriptionText>
                    Your data is used to personalize your experience with OwnAI, improve our service, and communicate with you. We analyze usage patterns to enhance our AI algorithms.
                </DescriptionText>

                <Subtitle type="medium">Data Sharing and Disclosure</Subtitle>
                <DescriptionText>
                    We do not share your personal information with third parties, except as required by law or to protect our rights. Aggregate, anonymized data may be used for research or statistical purposes.
                </DescriptionText>

                <Subtitle type="medium">Data Security</Subtitle>
                <DescriptionText>
                    We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
                </DescriptionText>

                <Subtitle type="medium">User Rights and Choices</Subtitle>
                <DescriptionText>
                    You have the right to access, update, or delete your personal information. You can manage your account settings or contact us for specific requests.
                </DescriptionText>

                <Subtitle type="medium">Cookies and Tracking Technologies</Subtitle>
                <DescriptionText>
                    We use cookies and similar technologies to enhance your experience, gather general visitor information, and track visits to our service.
                </DescriptionText>

                <Subtitle type="medium">Policy Changes</Subtitle>
                <DescriptionText>
                    We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our service.
                </DescriptionText>

                <Subtitle type="medium">Contact Information</Subtitle>
                <DescriptionText>
                    For any questions or concerns about this policy, please contact us at [your contact email or support address].
                </DescriptionText>
            </InnerContainer>
        </ScreenContainer>
    );
};

export default PrivacyPolicyScreen;