import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "./CustomText";

const bouncingAnim = keyframes`
  0% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(-10px);
  }
`;

const Wrapper = styled.div`
  align-self: flex-start;
  position: relative;
  z-index: 1;
  margin-top: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.strokeFade};
  animation: ${bouncingAnim} .5s alternate infinite ease;
  animation-delay: ${({ delay }) => delay};
`;

const LoadingText = styled(CustomText)`
  font-size: 1.1em;
  opacity: 0.5;
`;

const BouncingCircles = ({ text }) => {
    const { theme } = useTheme();

    return (
        <Wrapper>
            {text && <LoadingText theme={theme} type="medium" style={{ color: theme.colors.text }}>{text}</LoadingText>}
            <Circle theme={theme} />
            <Circle delay=".2s" theme={theme} />
            <Circle delay=".3s" theme={theme} />
        </Wrapper>
    );
}

export default BouncingCircles;
