import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import { useUser } from "../../contexts/UserContext";
import CustomText from '../atoms/CustomText';
import { FiChevronDown } from "react-icons/fi";
import { PiTrashSimpleDuotone, PiPlusBold, PiPencilSimpleDuotone, PiCheckBold, PiBooksDuotone, PiBooksBold, PiXBold, PiUserDuotone, PiUserBold } from "react-icons/pi";
import { newBackendProfile, deleteBackendProfile, updateBackendProfile, updateLastUsedProfile } from "../../services/firebaseFirestore";
import CustomRegularInput from "../atoms/CustomRegularInput";
import CustomToggle from "../atoms/Toggle";
import InfoTooltip from "../molecules/InfoTooltip";
import CustomButton from "../atoms/CustomButton";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  z-index: 201;
  
  @media (max-width: 800px) {
    position: absolute;
    top: 13px;
    left: 55px;
    z-index: 101;
  }
`;

const CurrentProfileText = styled(CustomText)`
  display: flex;
  align-items: center;
  font-size: 1em;
`;

const CurrentProfileChevron = styled(FiChevronDown)`
  margin-left: 2px;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  
  transform: ${(props) => props.expanded === "true" ? '0' : 'rotate(-90deg)'};
  transition: transform 0.3s;
  
  ${OuterContainer}:hover & {
    transform: rotate(0);
    transition: transform 0.3s;
  }
`;

const CurrentProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(50px);
  background-color: ${(props) => props.isClass ? props.theme.colors.purple : props.theme.colors.primary};
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 12px;
  gap: 2px;
  opacity: 0.9;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(25px);
  z-index: 100;
`;

const ProfileSelectorContainerXIcon = styled(PiXBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  
  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 200px;
  
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 50px;
  }
`;

const ProfileSelectorHeader = styled(CustomText)`
  font-size: 1.4em;
  margin-top: 50px;
  margin-bottom: 30%;
  opacity: 0.7;
`;

const ProfileSelectorNewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
`;

const ProfileSelectorNewContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const ProfileSelectorRowDeleteIcon = styled(PiTrashSimpleDuotone)`
  color: ${(props) => props.theme.colors.text};
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.03);
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorRowEditIcon = styled(PiPencilSimpleDuotone)`
  color: ${(props) => props.theme.colors.text};
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.03);
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorButtons = styled.div`
  display: ${(props) => props.editing ? 'flex' : 'none'};
  align-items: center;
  gap: 5px;
  margin-left: 10px;
`;

const ProfileSelectorRowCheckIcon = styled(PiCheckBold)`
  color: ${(props) => props.theme.colors.text};
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.97);
  }
`;

const ProfileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileSelectorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  
  &:hover ${ProfileSelectorButtons} {
    display: flex;
  }
`;

const ProfileSelectorRowText = styled(CustomText)`
  font-size: 1.5em;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  align-items: center;
  display: flex;

  &:hover {
    transform: scale(1.1);
  }
  
  &:active {
    transform: scale(1.05);
  }
`;

const ProfileSelectorCheckIcon = styled(PiCheckBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-top: 30px;
    
  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorXIcon = styled(PiXBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:active {
    transform: scale(0.97);
  }
`;

const ProfileSelectorAddIcon = styled(PiPlusBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-top: 30px;
    
  &:hover {
    transform: scale(1.1);
    transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  &:active {
    transform: scale(0.97);
  }
`;

const ClassIcon = styled(PiBooksDuotone)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-right: 5px;
`;

const ClassIconBold = styled(PiBooksBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-right: 5px;
`;

const UserIcon = styled(PiUserDuotone)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-right: 5px;
`;

const UserIconBold = styled(PiUserBold)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  cursor: pointer;
  margin-right: 5px;
`;

const CancelButton = styled(CustomButton)`
  background-color: transparent;
  box-shadow: none;
  color: ${(props) => props.theme.colors.text};
  
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const renderProfileList = (header, profiles, onProfileClick, onProfileNameChange, onDeleteProfileClick, editingProfileId, setEditingProfileId, tempProfileName, setTempProfileName, theme) => {
    return (
        <ProfileListContainer>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <ProfileSelectorHeader theme={theme}>
                    {header}
                </ProfileSelectorHeader>
                <InfoTooltip size={18} text={header === "User Profiles" ? "User profiles are for personal use. They adapt to the user themselves." : "Class profiles are for educational classes. They adapt to a class to better help a user with it."} showBelow={true} />
            </div>

            {profiles.map(profile => (
                <ProfileSelectorRow key={profile.id}>
                    {editingProfileId === profile.id ? (
                        <CustomRegularInput
                            name="profileName"
                            value={tempProfileName}
                            onChange={(e) => setTempProfileName(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    onProfileNameChange(profile.id, tempProfileName, profile.isClass || false);
                                    setEditingProfileId(null);
                                }
                            }}
                            style={{fontSize: '1.3em'}}
                        />
                    ) : (
                        <ProfileSelectorRowText onClick={() => onProfileClick(profile.id)}>
                            {profile.isClass ? <ClassIcon size={24} theme={theme} /> : <UserIcon size={24} theme={theme} />}
                            {profile.name}
                        </ProfileSelectorRowText>
                    )}
                    <ProfileSelectorButtons editing={editingProfileId === profile.id}>
                        {editingProfileId === profile.id ? (
                            <ProfileSelectorRowCheckIcon onClick={() => {
                                onProfileNameChange(profile.id, tempProfileName, profile.isClass || false);
                                setEditingProfileId(null);
                            }} size={20} theme={theme} />
                        ) : (
                            <ProfileSelectorRowEditIcon onClick={() => {
                                setEditingProfileId(profile.id);
                                setTempProfileName(profile.name);
                            }} size={20} theme={theme} />
                        )}
                        <ProfileSelectorRowDeleteIcon onClick={() => onDeleteProfileClick(profile.id)} size={20} theme={theme} />
                    </ProfileSelectorButtons>
                </ProfileSelectorRow>
            ))}
        </ProfileListContainer>
    );
};

const ProfileSelector = ({ setSubscribeModalOpen }) => {
    const { theme } = useTheme();
    const { user, setUser } = useUser();
    const [isExpanded, setIsExpanded] = useState(false);

    const [allProfiles, setAllProfiles] = useState(user.backendProfiles);
    const [userProfiles, setUserProfiles] = useState(user.backendProfiles.filter(profile => !profile.isClass));
    const [classProfiles, setClassProfiles] = useState(user.backendProfiles.filter(profile => profile.isClass));
    let currentProfileId = user.selectedBackendProfileId
    let currentProfile = allProfiles ? allProfiles.find(profile => profile.id === currentProfileId) || allProfiles[0] : null;

    const [isClass, setIsClass] = useState(false);
    const [showNewProfileModal, setShowNewProfileModal] = useState(false);
    const [editingProfileId, setEditingProfileId] = useState(null);
    const [tempProfileName, setTempProfileName] = useState('');

    useEffect(() => {
        setAllProfiles(user.backendProfiles);
        setUserProfiles(user.backendProfiles.filter(profile => !profile.isClass));
        setClassProfiles(user.backendProfiles.filter(profile => profile.isClass));
    }, [user.backendProfiles]);

    const onClick = () => {
        setIsExpanded(!isExpanded);
    };

    const onProfileClick = (profileId) => {
        setUser({ ...user, selectedBackendProfileId: profileId });
        setIsExpanded(false);
        currentProfileId = profileId;
        currentProfile = allProfiles.find(profile => profile.id === profileId) || allProfiles[0];
        updateLastUsedProfile(user.uid, profileId);
    };

    const onAddProfileClick = () => {
        setTempProfileName('');
        setShowNewProfileModal(true);
    };

    const handleCreateProfile = () => {
        if (!tempProfileName) {
            alert('You must enter a profile name');
            return;
        }

        if (tempProfileName) {
            newBackendProfile(user.uid, tempProfileName, isClass).then((profileId) => {
                setUser((currentUser) => ({
                    ...currentUser,
                    backendProfiles: [...currentUser.backendProfiles, { name: tempProfileName, id: profileId, nodes: [] }]
                }));
                onProfileClick(profileId);
            });
            setTempProfileName('');
            setShowNewProfileModal(false);
            setIsClass(false);
        }
    };

    const onDeleteProfileClick = (profileId) => {
        if (window.confirm('Are you sure you want to delete this profile?')) {
            deleteBackendProfile(profileId).then(() => {
                setUser((currentUser) => ({
                    ...currentUser,
                    backendProfiles: currentUser.backendProfiles.filter(profile => profile.id !== profileId),
                }));
                onProfileClick(user.backendProfiles[0].id);
            });
        }
    };

    const onProfileNameChange = (profileId, newName, isClass) => {
        updateBackendProfile(profileId, { id: profileId, name: newName, isClass: isClass }).then(() => {
            setUser((currentUser) => ({
                ...currentUser,
                backendProfiles: currentUser.backendProfiles.map(profile => {
                    if (profile.id === profileId) {
                        return { ...profile, name: newName };
                    } else {
                        return profile;
                    }
                })
            }));
        });
    };

    if (!user || !user.backendProfiles) {
        return null;
    }

    return (
        <OuterContainer>
            <CurrentProfileContainer onClick={onClick} theme={theme} isClass={currentProfile && currentProfile.isClass}>
                <CurrentProfileText type="medium" theme={theme} style={{color: theme.colors.white}}>
                    {currentProfile && currentProfile.isClass ? <ClassIconBold size={20} theme={theme} style={{color: theme.colors.white}} /> : <UserIconBold size={20} theme={theme} style={{color: theme.colors.white}} />}
                    {currentProfile && window.innerWidth > 800 && currentProfile.name}
                </CurrentProfileText>
                <CurrentProfileChevron theme={theme} expanded={isExpanded.toString()} size={15} style={{color: theme.colors.white}} />
            </CurrentProfileContainer>
            {isExpanded && (
                <ProfileSelectorContainer>
                    <ProfileSelectorContainerXIcon onClick={() => setIsExpanded(false)} size={25} theme={theme} />
                    {showNewProfileModal ?
                        <ProfileSelectorNewContainer>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px', position: 'relative' }}>
                                {isClass && <ClassIcon size={25} theme={theme} style={{position: 'absolute', left: '-45px'}} />}
                                <CustomRegularInput
                                    name="profileName"
                                    placeholder={isClass ? "Class Name" : "Profile Name"}
                                    value={tempProfileName}
                                    onChange={(e) => setTempProfileName(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleCreateProfile();
                                            setShowNewProfileModal(false);
                                        }
                                    }}
                                    style={{fontSize: '1.3em'}}
                                />
                            </div>
                            <ProfileSelectorNewContainerRow>
                                <CustomText type="medium">Is this for a class?</CustomText>
                                <CustomToggle isToggled={isClass} onToggle={() => setIsClass(!isClass)} />
                            </ProfileSelectorNewContainerRow>
                        </ProfileSelectorNewContainer>
                     :
                        <ProfileSelectorsContainer>
                            {userProfiles.length > 0 && renderProfileList("User Profiles", userProfiles, onProfileClick, onProfileNameChange, onDeleteProfileClick, editingProfileId, setEditingProfileId, tempProfileName, setTempProfileName, theme)}
                            {classProfiles.length > 0 && renderProfileList("Class Profiles", classProfiles, onProfileClick, onProfileNameChange, onDeleteProfileClick, editingProfileId, setEditingProfileId, tempProfileName, setTempProfileName, theme)}
                        </ProfileSelectorsContainer>
                    }
                    {showNewProfileModal ?
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px', marginTop: '25px' }}>
                            <CancelButton onClick={() => setShowNewProfileModal(false)} theme={theme} type="small">Cancel</CancelButton>
                            <CustomButton onClick={handleCreateProfile} theme={theme} type="small">Create</CustomButton>
                        </div>
                        :
                        <ProfileSelectorAddIcon onClick={onAddProfileClick} theme={theme} size={35}/>
                    }
                </ProfileSelectorContainer>
            )}
        </OuterContainer>
    );
}

export default ProfileSelector;