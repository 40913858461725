import React from 'react';
import styled from 'styled-components';
import CustomText from "./CustomText";
import { useTheme } from "../../contexts/ThemeContext";

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
`;

const StyledSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.border};
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.border};
    cursor: pointer;
  }
`;

const SliderValue = styled(CustomText)`
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.text};
`;

const CustomSlider = ({ min, max, value, onChange }) => {
    const { theme } = useTheme();
    return (
        <SliderContainer>
            <StyledSlider
                type="range"
                min={min}
                max={max}
                value={value}
                step={10}
                onChange={onChange}
                theme={theme}
            />
            <SliderValue theme={theme} type="medium">{value}</SliderValue>
        </SliderContainer>
    );
};

export default CustomSlider;