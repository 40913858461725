import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useUser } from '../../contexts/UserContext';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";
import CustomButton from "../atoms/CustomButton";
import { signOutUser } from "../../services/firebaseAuth";
import ThemeToggle from "../atoms/ThemeToggle";

const MenuContainer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 50px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.strokeFaded};
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 999;
  
  @media (max-width: 800px) {
    left: 10px;
    right: 10px;
    bottom: 70px;
  }
`;

const MenuItem = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-top: 1px solid ${(props) => props.theme.colors.strokeFaded};
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    cursor: pointer;
  }
`;

const MenuItemRow = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const MenuRow = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  gap: 20px;
`;

const MenuItemText = styled(CustomText)`
  font-size: 1em;
`;

const MenuItemTextBig = styled(CustomText)`
  font-size: 1.1em;
`;

const MenuItemTextSmall = styled(CustomText)`
  font-size: 0.9em;
  opacity: 0.8;
`;

const AccountMenu = ({ show, hide }) => {
    const { user } = useUser();
    const { theme } = useTheme();

    const userName = user?.displayName;
    const userEmail = user?.email;
    const userTokenLimit = user?.tokenLimit;
    const userTokensUsed = user?.tokensUsed;
    const menuRef = useRef();

    const onLogout = async () => {
        await signOutUser();
    }

    const openStripeCustomerPortal = async () => {
        window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK, '_blank');
    }

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                hide();
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [hide]);

    if (!show) {
        return null;
    }

    return (
        <MenuContainer theme={theme} ref={menuRef} id="account-menu">
            <MenuRow theme={theme}>
                <MenuItemText type="semiBold">Theme</MenuItemText>
                <ThemeToggle />
            </MenuRow>
            <MenuItem theme={theme} onClick={openStripeCustomerPortal}>
                <MenuItemRow theme={theme}>
                    <MenuItemTextBig type="semiBold" style={{color: theme.colors.primary}}>{userTokensUsed}/{userTokenLimit} </MenuItemTextBig>
                    <MenuItemTextSmall type="medium">tokens</MenuItemTextSmall>
                </MenuItemRow>
                <MenuItemText type="semiBold">{userName}</MenuItemText>
                <MenuItemText>{userEmail}</MenuItemText>
            </MenuItem>
            <MenuItem theme={theme}>
                <CustomButton type="small" onClick={onLogout}>Log out</CustomButton>
            </MenuItem>
        </MenuContainer>
    );
};

export default AccountMenu;
