import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styled from 'styled-components';
import TextareaAutosize from "react-textarea-autosize";

const Input = styled(TextareaAutosize)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.name === "dark" ? props.theme.colors.strokeFaded : props.theme.colors.strokeFade};
  padding: 10px;
  align-self: center;
  color: ${(props) => props.theme.colors.text};
  font-size: 1em;
  font-family: 'PoppinsMedium', serif;
  resize: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }
`;

const CustomInput = ({ type, name, value, onChange, minRows, placeholder, style }) => {
    const { theme } = useTheme();

    const handleChange = (event) => {
        if (type === 'number') {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <Input
            type={type || 'text'}
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            theme={theme}
            maxRows={10}
            minRows={minRows}
            style={style}
        />
    );
};

export default CustomInput;