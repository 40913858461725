import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import {createCheckoutSession} from "../../api/api";
import CustomText from "../atoms/CustomText";
import CheckoutCard from "../molecules/CheckoutCard";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  margin: 0 auto;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.background};
  
  @media (max-width: 800px) {
    overflow-y: scroll;
    height: 80vh
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //gap: 10px;
  width: 100%;
  padding-top: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //gap: 30px;
  height: 100%;
  
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }
`;

const SubscribeModal = ({ open, handleClose, missingTokens }) => {
    const { theme } = useTheme();
    const modalRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClose]);

    if (!open) {
        return null;
    }

    return (
        <ModalBackdrop>
            <Container theme={theme} ref={modalRef}>
                {missingTokens > 0 && (
                    <CountdownContainer>
                        <CustomText size="1.2em" type="bold">You are missing {missingTokens} tokens.</CustomText>
                    </CountdownContainer>
                )}
                <InnerContainer>
                    <CheckoutCard
                        title="Monthly"
                        description="The flexible option."
                        features={[
                            "Your OwnAI",
                            "Image Generation",
                            "Text Humanization",
                            "Unlimited Profiles",
                            "Email Support",
                        ]}
                        onCreateCheckoutSession={createCheckoutSession}
                        monthly
                    />
                    <CheckoutCard
                        title="Yearly"
                        description="The best value."
                        features={[
                            "Your OwnAI",
                            "Image Generation",
                            "Text Humanization",
                            "Unlimited Profiles",
                            "Email Support",
                        ]}
                        onCreateCheckoutSession={createCheckoutSession}
                    />
                </InnerContainer>
            </Container>
        </ModalBackdrop>
    );
};

export default SubscribeModal;