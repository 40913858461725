import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import welcomeLogo from '../../assets/images/mascot/welcomeLogo.png';
import CustomButton from "../atoms/CustomButton";
import CustomText from "../atoms/CustomText";
import { useTheme } from "../../contexts/ThemeContext";
import {
    signInWithGooglePopup,
    signUpWithEmailAndPassword,
    loginWithEmailAndPassword
} from "../../services/firebaseAuth";
import CustomRegularInput from "../atoms/CustomRegularInput";
import { useUser } from "../../contexts/UserContext";
import { getFirebaseAuthErrorMessage } from "../../utils/utils";
import DividerWithText from "../atoms/Divider";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const ModalContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  
  @media (max-width: 800px) {
    padding: 15px 5px;
    //margin-left: -10px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px;
  
  @media (max-width: 800px) {
    padding: 0 15px;
    border-left: none;
    position: relative;
  }
`;

const ModalLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  //padding-right: 20px;
  border-right: 1px solid ${(props) => props.theme.colors.border};
  //border-left: 1px solid ${(props) => props.theme.colors.border};
  padding: 10px 62.5px 10px 30px;
  
  @media (max-width: 800px) {
    display: none;
  }
`;

const ModalLeftImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ModalLeftBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 25px
`;

const MobileModalTopRight = styled.div`
    display: none;
  
    @media (max-width: 800px) {
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px;
    }
`;

const ModalHeader = styled(CustomText)`
  font-size: 30px;
`;

const ModalSubheader = styled(CustomText)`
  font-size: 16px;
  opacity: 0.8;
`;

const ModalList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
`;

const ModalListNumber = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.primaryFade};
  font-family: 'Poppins', serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  margin-right: 10px;
`;

const ModalListItem = styled.li`
  margin-bottom: 14px;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  min-width: 300px;
`;

const TextBanner = styled.div`
  background-color: ${(props) => props.theme.colors.greenFade};
  border: 1px solid ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.green};
  font-family: 'Poppins', serif;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 50px;
`;

const MascotImage = styled.img`
  width: 140px;
  height: 100%;
  object-fit: contain;
  opacity: 1;
`;

const SignUpButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SignUpButton = styled(CustomButton)`
  width: 100%;
`;

const Divider = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
    font-family: 'PoppinsMedium', serif;
    font-size: 14px;
    margin: 0 10px;
`;

const ManualSignUpButton = styled.button`
  background-color: ${(props) => props.theme.colors.background};
  border: none;
  box-shadow: none;
  color: ${(props) => props.theme.colors.text};
  font-family: 'PoppinsMedium', serif;
  font-size: 14px;
  
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

const RegisterModal = ({ open, handleClose, login }) => {
    const { theme } = useTheme();
    const { refreshUser } = useUser();
    const navigate = useNavigate();
    const modalRef = useRef();
    // if login is passed, automatically open the login form
    const [showSignUpForm, setShowSignUpForm] = useState(!login);
    const [signingUp, setSigningUp] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClose]);

    const handleGoogleSignIn = async () => {
        await signInWithGooglePopup();
        refreshUser();
    };

    const handleSignUp = async () => {
        const resp = await signUpWithEmailAndPassword(email, password);
        if (!resp.success) {
            setErrorMessage(getFirebaseAuthErrorMessage(resp.error));
            return;
        }
        refreshUser();
    }

    const handleLogin = async () => {
        const resp = await loginWithEmailAndPassword(email, password);
        if (!resp.success) {
            setErrorMessage(getFirebaseAuthErrorMessage(resp.error));
            return;
        }
        refreshUser();
    }

    const toggleSignUpForm = () => {
        setShowSignUpForm(!showSignUpForm);
    };

    useEffect(() => {
        // Update `showSignUpForm` when `login` prop changes.
        setShowSignUpForm(!login);
    }, [login]);

    if (!open) {
        return null;
    }

    return (
        <ModalBackdrop>
            <ModalContainer ref={modalRef} theme={theme}>
                <ModalLeft theme={theme}>
                    <ModalLeftImageContainer>
                        <MascotImage src={welcomeLogo} alt="Welcome Mascot" />
                    </ModalLeftImageContainer>
                    <ModalLeftBottom>
                        <TextBanner theme={theme}>
                            Used by 1000+ people
                        </TextBanner>
                    </ModalLeftBottom>
                </ModalLeft>
                <ModalContent theme={theme}>
                    <MobileModalTopRight>
                        <TextBanner theme={theme}>
                            Beta
                        </TextBanner>
                    </MobileModalTopRight>
                    <ModalHeader type="bold">Welcome!</ModalHeader>
                    <ModalSubheader type="medium">Let's get you started.</ModalSubheader>
                    {!showSignUpForm ? (
                        <ModalList>
                            <ModalListItem theme={theme}>
                                <ModalListNumber theme={theme}>1</ModalListNumber>
                                Chat away, just like talking to GPT.
                            </ModalListItem>
                            <ModalListItem theme={theme}>
                                <ModalListNumber theme={theme}>2</ModalListNumber>
                                OwnAI learns with every message.
                            </ModalListItem>
                            <ModalListItem theme={theme}>
                                <ModalListNumber theme={theme}>3</ModalListNumber>
                                Adapts and tailors responses just for you.
                            </ModalListItem>
                        </ModalList>
                    ) : (
                        <ModalList>
                            <ModalListItem theme={theme}>
                                <CustomRegularInput placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </ModalListItem>
                            <ModalListItem theme={theme}>
                                <CustomRegularInput
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    secure={!isPasswordVisible}
                                    toggleSecure={togglePasswordVisibility}
                                />
                            </ModalListItem>
                            {signingUp && (
                                <ModalListItem theme={theme}>
                                    <CustomRegularInput
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={passwordConfirm}
                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                        secure={!isPasswordVisible}
                                        toggleSecure={togglePasswordVisibility}
                                    />
                                </ModalListItem>
                            )}
                            {!signingUp ?
                                <ManualSignUpButton onClick={() => setSigningUp(!signingUp)} theme={theme} style={{marginLeft: -10}}>Don't have an account?</ManualSignUpButton>
                            :
                                <ManualSignUpButton onClick={() => setSigningUp(!signingUp)} theme={theme} style={{marginLeft: -10}}>Already have an account?</ManualSignUpButton>

                            }
                        </ModalList>
                    )}
                    <SignUpButtonsContainer>
                        {!showSignUpForm ? (
                            <>
                                <SignUpButton isGoogleButton onClick={handleGoogleSignIn} theme={theme}>Sign in with Google</SignUpButton>
                                <ManualSignUpButton onClick={toggleSignUpForm} theme={theme}>Sign up manually</ManualSignUpButton>
                            </>
                        ) : (
                            <>
                                <SignUpButton onClick={signingUp ? handleSignUp : handleLogin} theme={theme}>{signingUp ? 'Sign Up' : 'Log In'}</SignUpButton>
                                <DividerWithText text="OR" />
                                {/*<ManualSignUpButton onClick={toggleSignUpForm} theme={theme}>Sign up with Google</ManualSignUpButton>*/}
                                <SignUpButton isGoogleButton onClick={handleGoogleSignIn} theme={theme}>{signingUp ? 'Sign up with Google' : 'Log in with Google'}</SignUpButton>
                            </>
                        )}
                    </SignUpButtonsContainer>
                </ModalContent>
            </ModalContainer>
        </ModalBackdrop>
    );
};

export default RegisterModal;
