import React from "react";
import styled from "styled-components";
import CustomText from "../atoms/CustomText";
import { useTheme } from "../../contexts/ThemeContext";
import { Link as RouterLink } from "react-router-dom";

const SidebarActionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 7px;
  overflow: hidden;
  background-color: transparent;
  border-radius: 10px;
  transition: background-color 0.3s;
  margin: 2px 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.sidebarBackgroundFade};
    cursor: pointer;
  }
`;

const SidebarItemText = styled(CustomText)`
  font-size: 1rem;
  white-space: nowrap;
  margin-left: 10px;
  transition: opacity 0.3s;
  color: ${(props) => props.theme.colors.text};
  
  @media (max-width: 800px) {
    font-size: 1.3rem;
  }
`;

const SidebarIcon = styled.div`
  font-size: 26px;
  color: ${(props) => props.theme.colors.sidebarIcon};
  
  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

const SidebarImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 50%;
`;

const StyledText = styled(CustomText)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const SidebarAction = ({ onClick, icon, children, isExpanded, show }) => {
    const { theme } = useTheme();

    if (!isExpanded && !show) return;

    return (
        <SidebarActionContainer
            theme={theme}
        >
            <StyledText onClick={onClick}>
                {icon && <SidebarIcon as={icon} theme={theme} />}
                <SidebarItemText type="medium" theme={theme}>
                    {children}
                </SidebarItemText>
            </StyledText>
        </SidebarActionContainer>
    );
};

export default SidebarAction;