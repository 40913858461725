import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import { useConversation } from "../../contexts/ConversationContext";
import { useTheme } from "../../contexts/ThemeContext";
import { PiTrashSimpleDuotone, PiPencilSimpleDuotone, PiCheckBold, PiPlusBold } from "react-icons/pi";
import CustomText from "../atoms/CustomText";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.isexpanded ? 'flex-start' : 'center')};
  justify-content: center;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? '13px' : '0px')};
  right: ${(props) => (props.absolute ? '13px' : '0px')};
  gap: 10px;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  background-color: ${(props) => (props.isExpanded ? props.theme.colors.background : 'transparent')};
  border: 1px solid ${(props) => (props.theme.name === "dark" ? props.theme.colors.strokeFaded : props.theme.colors.strokeFade)};
  border-radius: 10px;
  overflow: hidden;
  z-index: 99;
  position: relative;
`;

const SelectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 6px;
  cursor: pointer;
`;

const SelectionList = styled.div`
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  max-height: ${(props) => (props.absolute ? '400px' : '200px')};
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.strokeFaded};
    border-radius: 10px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ChevronIcon = styled(FiChevronDown)`
  transition: transform 0.3s ease;
  transform: rotate(${(props) => (props.isexpanded === "true" ? '180deg' : '0deg')});
    color: ${(props) => (props.theme.colors.border)};
`;

const StyledText = styled(CustomText)`
  color: ${(props) => (props.theme.colors.border)};
  margin-left: 5px;
  max-width: 100%;
  white-space: nowrap;
`;

const PlusIcon = styled(PiPlusBold)`
  color: ${(props) => (props.theme.colors.text)};
  opacity: 0.8;
  backdrop-filter: blur(50px);
  border-radius: 10px;
  padding: 5px;
  margin-top: ${(props) => (props.isexpanded === "true" ? '5px' : '0px')};
  z-index: 99;
  
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;

const EditIcon = styled(PiPencilSimpleDuotone)`
  color: ${(props) => (props.theme.colors.border)};
  margin-right: 5px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(0.8);

  &:hover {
    transform: scale(1);
    cursor: pointer;
  }
`;

const ConfirmEditIcon = styled(PiCheckBold)`
  color: ${(props) => (props.theme.colors.border)};
  margin-right: 5px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(0.8);

  &:hover {
    transform: scale(1);
    cursor: pointer;
  }
`;

const SelectionItemDeleteIcon = styled(PiTrashSimpleDuotone)`
  color: ${(props) => (props.theme.colors.border)};
  margin-right: 5px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(0.8);
  
  &:hover {
    transform: scale(1);
    cursor: pointer;
  }
`;

const SelectionItemButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  max-width: 100%;
  white-space: nowrap;
  margin: 2px;
  border-radius: 10px;
  gap: 5px;
  background-color: ${(props) => (props.selected ? props.theme.colors.strokeFaded : 'transparent')};
  
  &:hover {
    background-color: ${(props) => (props.theme.colors.strokeFaded)};
    cursor: pointer;
    
    ${SelectionItemDeleteIcon} {
      color: ${(props) => (props.theme.colors.text)};
    }
    
    ${EditIcon} {
        color: ${(props) => (props.theme.colors.text)};
    }
    
    ${ConfirmEditIcon} {
        color: ${(props) => (props.theme.colors.text)};
    }
  }
`;

const SelectionItemText = styled(CustomText)`
  white-space: nowrap;
`;

const SelectionItemInput = styled.input`
  border: none;
  background-color: transparent;
  align-self: center;
  color: ${(props) => props.theme.colors.text};
  font-size: 1.1em;
  font-family: 'Poppins', serif;
  font-weight: 500;
  resize: none;
  overflow: hidden;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`;

const CustomSelector = ({ placeholder, items, onSelect, onNewItem, currentItemId, createDelete, allowEdit, absolute }) => {
    const { theme } = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const selectorRef = useRef(null);
    const [editingId, setEditingId] = useState(null);
    const [editedName, setEditedName] = useState("");
    const { deleteUserConversation, setConversations, updateConversationName } = useConversation();
    const currentConversationText = items.find(item => item.id === currentItemId)?.name || placeholder;

    const handleSelect = (id) => {
        onSelect(id);
        setIsExpanded(false);
    };

    const handleNewConversation = () => {
        onNewItem();
        setIsExpanded(false);
    };

    const handleDeleteConversation = (e, id) => {
        e.stopPropagation();
        setConversations(prevConversations => prevConversations.filter(convo => convo.id !== id));
        deleteUserConversation(id);
    };

    const handleEdit = (e, id, currentName) => {
        e.stopPropagation();
        setEditingId(id);
        setEditedName(currentName);
    };

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleNameSubmit = async (e, id) => {
        e.stopPropagation();
        await updateConversationName(id, editedName);
        setEditingId(null);
    };

    const handleClickOutside = (event) => {
        if (selectorRef.current && !selectorRef.current.contains(event.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <OuterContainer isexpanded={isExpanded} absolute={absolute}>
            {createDelete && <PlusIcon onClick={handleNewConversation} size={20} theme={theme} isexpanded={isExpanded.toString()} />}
            <SelectorContainer theme={theme} isExpanded={isExpanded} ref={selectorRef}>
                <SelectionHeader onClick={() => setIsExpanded(!isExpanded)}>
                    <StyledText theme={theme}> {currentConversationText} </StyledText>
                    <ChevronIcon isexpanded={isExpanded.toString()} size={20} theme={theme} />
                </SelectionHeader>
                <SelectionList isExpanded={isExpanded} absolute={absolute} theme={theme} >
                    {items.map(item => (
                        <SelectionItem key={item.id} onClick={() => handleSelect(item.id)} theme={theme} selected={item.id === currentItemId}>
                            {editingId === item.id ? (
                                <form onSubmit={(e) => handleNameSubmit(e, item.id)}>
                                    <SelectionItemInput value={editedName} onChange={handleNameChange} theme={theme} autoFocus />
                                </form>
                            ) : (
                                <SelectionItemText theme={theme}>{item.name ? item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name : `Untitled`}</SelectionItemText>
                            )}
                            <SelectionItemButtons>
                                {allowEdit &&
                                    (editingId === item.id ? (
                                        <ConfirmEditIcon size={22} theme={theme} onClick={(e) => handleNameSubmit(e, item.id)} />
                                    ) : (
                                        <EditIcon size={22} theme={theme} onClick={(e) => handleEdit(e, item.id, item.name)} />
                                    ))
                                }
                                {/*{allowEdit && <EditIcon size={22} theme={theme} onClick={(e) => handleEdit(e, item.id, item.name)} />}*/}
                                {createDelete && <SelectionItemDeleteIcon size={21} theme={theme} onClick={(e) => handleDeleteConversation(e, item.id)} />}
                            </SelectionItemButtons>
                        </SelectionItem>
                    ))}
                </SelectionList>
            </SelectorContainer>
        </OuterContainer>
    );
};

export default CustomSelector;