import React from "react";
import {
    PiBrainBold,
    PiCalendarBlankBold, PiChartPolarBold,
    PiChatCircleBold,
    PiGearBold, PiMagnifyingGlassBold,
    PiPathBold,
    PiQuotesBold,
    PiRepeatBold,
    PiSmileyBold, PiSmileyMehBold
} from "react-icons/pi";
import { LuLanguages } from "react-icons/lu";

export const FIREBASE_AUTH_ERROR_MAP = {
    "auth/invalid-email": "Invalid email address.",
    "auth/invalid-password": "Invalid password.",
    "auth/email-already-exists": "This email address is already in use.",
    "auth/internal-error": "Please try again later.",
    "auth/invalid-display-name": "Invalid display name.",
    "auth/user-disabled": "This account has been disabled.",
    "auth/user-not-found": "No account found with this email address.",
    "auth/wrong-password": "Incorrect password.",
    "auth/email-already-in-use": "This email address is already in use.",
    "auth/weak-password": "Password must be at least 6 characters.",
    "default": "An error occurred. Please try again later.",
}

export const FAQS = [
    {
        question: "What is OwnAI?",
        answer: "OwnAI is a dynamic AI chatbot, similar to ChatGPT, that learns about and adapts to you over time. It's designed to offer a conversational experience that feels contextually rich and engaging.",
    },
    {
        question: "How does OwnAI work?",
        answer: "OwnAI employs advanced AI, including aspects of OpenAI's API, to build unique evolving profiles for each user. This enables tailored responses for more effective and individualized interactions.",
    },
    // {
    //     question: "What is the personal toggle?",
    //     answer: "The personal toggle lets you enable or disable personalization. When on, OwnAI uses your profile for tailored responses. When off, it provides standard, non-personalized replies.",
    // },
    {
        question: "What are profiles?",
        answer: "Profiles in OwnAI are compilations of memories and contexts used for personalizing responses. They come in two forms: user profiles for individual personalization, and class profiles for educational assistance.",
    },
    {
        question: "How do I manage my subscription?",
        answer: "Manage your subscription via the account menu. We monitor token usage to ensure cost-effectiveness, with no performance difference across tiers. Upgrade only when usage limits are reached."
    },
    {
        question: "What are the built-in tools?",
        answer: "OwnAI currently offers a two built-in tools to help you write essays and sound more human. These are used fluidly within a conversation when enabled. We plan to add more tools in the future.",
    },
    {
        question: "Can OwnAI remember past conversations?",
        answer: "Absolutely, OwnAI is designed to recall past conversations, enhancing its contextual understanding and making interactions more meaningful.",
    },
    {
        question: "How does OwnAI differ from other chatbots?",
        answer: "OwnAI stands out through its unique ability to develop a deep understanding of each user. Unlike many chatbots that offer generic responses, OwnAI's evolving user profile system allows for truly personalized and adaptive conversations.",
    },
];

export const CONVO_STARTER_SUGGESTIONS = {
    "Create a short story": "about discovering a hidden world",
    "Draft an opening statement": "for a debate on space exploration",
    "Design a healthy weekly meal plan": "for someone trying to build muscle",
    "Craft an inspirational speech": "for an environmental awareness event",
    "Invent a new game": "for a family game night",
    "Describe the journey of a raindrop": "as a narrative adventure",
    "Construct a timeline of evolution": "as a tour guide at a museum",
    "Write a travel itinerary": "for a history enthusiast visiting Rome",
    "Tell me a fun fact": "about the Roman Empire",
    "Come up with concepts": "for a retro-style arcade game",
    "Compose a poem": "about seasons in a whimsical style",
    "Generate a workout routine": "to help lose weight",
    "Suggest innovative tech startups": "based on trends in renewable energy",
    "Debate the pros and cons": "of social media in a socratic manner",
    "Plan a sustainable city": "highlighting eco-friendly transportation",
    "Create a fantasy character": "for a novel, with magical abilities",
    "Explain quantum computing": "as if talking to a high school student",
    "Suggest 5 book recommendations": "relating to ancient civilizations",
    "Describe the life of a honeybee": "as a day-in-the-life story",
    "Outline how to start a small business": "focusing on handmade crafts",
    "Discuss the impact of social media": "on mental health in an informative way",
    "Design a concept for a mobile app": "that helps reduce food waste",
    "Explain the Renaissance": "for a school history class",
    "Propose ideas for a community project": "that promotes local arts and culture",
    "Summarize a famous": "Greek myth in a humorous way",
    "Plan a day trip": "for a nature lover in a National Park",
    "Create a superhero": "with powers and an origin story",
    "Debate whether time travel": "could ever be possible",
    "Design an eco-friendly house": "using only renewable materials",
    "Explain the importance of bees": "in a simple, yet informative manner",
    "Compose a lullaby": "for a newborn baby",
    "Invent a futuristic gadget": "that could change everyday life",
    "Describe a day in the life": "of an astronaut on the ISS",
    "Suggest three podcasts": "for learning a new language",
    "Explain the aurora borealis": "as a phenomenon in a poetic way",
    "Design a board game": "based on history and exploration",
    "Draft a motivational message": "for someone on their fitness journey",
    "Develop a plot twist": "for a thriller set in a ghost town.",
    "Compose a haiku": "about a peaceful mountain sunrise",
    "Explain the theory of relativity": "as if talking to a curious teenager",
    "Outline a day as a medieval knight": "during a grand tournament",
    "Discuss the future of AI": "in an optimistic yet realistic tone",
    "Predict the future of space travel": "in the next 50 years",
    "Design a tiny home": "that maximizes space and comfort",
    "Draft a letter": "from a soldier in a historical war",
    "Plan a culinary tour through Italy": "focusing on local delicacies",
    "Explain black holes": "as an astrophysicist to 5 year olds",
    "Invent a new holiday": "and describe its traditions",
    "Debate the effectiveness": "of online vs in-person education",
    "Write a scene": "for a romance set in Paris",
    "Suggest eco-friendly brands": "for a sustainable wardrobe",
    "Compose a song": "about traveling the world",
    "Explain the life of a star": "in an easy-to-understand way",
    "Design a fitness challenge": "for people working from home",
    "Summarize the plot": "of a classic novel in a funny way",
    "Propose a solution": "to reduce traffic in big cities",
    "Draft a script": "for a spy movie in the future",
    "Plan a weekend retreat": "focused on mindfulness and relaxation",
    "Explain the value in recycling": "in a fun and engaging manner",
    "Invent a new sport": "and explain how it's played",
    "Create a guide": "for stargazing beginners",
    "Compose a motivational speech": "for athletes before a big game",
    "Suggest ways to improve memory": "using fun and easy techniques",
    "Write a diary entry": "from infamous a historical figure",
    "Explain the science behind rainbows": "in a colorful and vivid way",
    "Plan a road trip across the US": "focusing on hidden gems",
    "Design a futuristic city": "with technology and green spaces",
    "Create a new holiday tradition": "that brings families together",
    "Debate the role of technology": "in enhancing human creativity",
    "Write an essay": "about the importance of education",
    "Outline an essay": "about the impact of social media",
    "Compose an essay": "about the future of AI",
    "Draw a comic": "about a day in the life of a superhero",
    "Draw a puppy": "in a cute and adorable way",
    "Make a photo": "of a beautiful sunset",
    "Create a photo": "of a majestic mountain",
    "Draw a picture": "of a peaceful lake",
    "Photo of a teddy": "bear skateboarding in NYC",
    "Abstract oil painting": "of artificial intelligence",
    "Draw a diagram": "of the solar system",
    "Draw a hand drawn": "sketch of a porsche 911",
    "Create a 3D render": "of a futuristic computer",
    "Design a logo": "with a minimalist style",
    "Give me five": "mind-blowing facts",
    "Give me 3": "intriguing conversation starters",
    "Give me 5": "fun facts about the universe",
    "Give me three": "interesting facts about the brain",
};

export const classToggleTypes = [
    "Info",
    "Syllabus",
    "Rules",
    "Teacher Profile",
    "Readings",
    "Lectures",
    "Assignments",
    "Educational Resources",
    "Learning Objectives",
    "Interactions",
    "Context",
    "Announcements",
];


export const userToggleTypes = [
    "Traits",
    "Interests",
    "Hobbies",
    "Quotes",
    "Understandings",
    "Language Patterns",
    "Emotional States",
    "Events",
    "Goals",
    "Learning Styles",
    "Social Dynamics",
    "Preferences",
    "Behavioural Patterns",
    "Cognitive Biases",
    "Value Systems",
    "Morals",
    "Ethical Beliefs",
    "Contextual Memories",
    "Health & Wellness",
    "Wellness Activities",
    "Wellness Goals",
];

export const toggles = [
    {
        toggleText: "Traits",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Traits toggle clicked"); }
    },
    {
        toggleText: "Interests",
        toggleIcon: <PiPathBold size={30} />,
        onToggleClick: () => { console.log("Interests toggle clicked"); }
    },
    {
        toggleText: "Hobbies",
        toggleIcon: <PiPathBold size={30} />,
        onToggleClick: () => { console.log("Hobbies toggle clicked"); }
    },
    {
        toggleText: "Quotes",
        toggleIcon: <PiQuotesBold size={30} />,
        onToggleClick: () => { console.log("Quotes toggle clicked"); }
    },
    {
        toggleText: "Understandings",
        toggleIcon: <PiChatCircleBold size={30} />,
        onToggleClick: () => { console.log("Understandings toggle clicked"); }
    },
    {
        toggleText: "Language Patterns",
        toggleIcon: <LuLanguages size={30} />,
        onToggleClick: () => { console.log("Language Patterns toggle clicked"); }
    },
    {
        toggleText: "Emotional States",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Emotional States toggle clicked"); }
    },
    {
        toggleText: "Events",
        toggleIcon: <PiCalendarBlankBold size={30} />,
        onToggleClick: () => { console.log("Events toggle clicked"); }
    },
    {
        toggleText: "Goals",
        toggleIcon: <PiPathBold size={30} />,
        onToggleClick: () => { console.log("Goals toggle clicked"); }
    },
    {
        toggleText: "Learning Styles",
        toggleIcon: <PiBrainBold size={30} />,
        onToggleClick: () => { console.log("Learning Styles toggle clicked"); }
    },
    {
        toggleText: "Social Dynamics",
        toggleIcon: <PiChatCircleBold size={30} />,
        onToggleClick: () => { console.log("Social Dynamics toggle clicked"); }
    },
    {
        toggleText: "Preferences",
        toggleIcon: <PiGearBold size={30} />,
        onToggleClick: () => { console.log("Preferences toggle clicked"); }
    },
    {
        toggleText: "Behavioural Patterns",
        toggleIcon: <PiRepeatBold size={30} />,
        onToggleClick: () => { console.log("Behavioural Patterns toggle clicked"); }
    },
    {
        toggleText: "Cognitive Biases",
        toggleIcon: <PiSmileyMehBold size={30} />,
        onToggleClick: () => { console.log("Cognitive Biases toggle clicked"); }
    },
    {
        toggleText: "Value Systems",
        toggleIcon: <PiChartPolarBold size={30} />,
        onToggleClick: () => { console.log("Value Systems toggle clicked"); }
    },
    {
        toggleText: "Morals",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Morals toggle clicked"); }
    },
    {
        toggleText: "Ethical Beliefs",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Ethical Beliefs toggle clicked"); }
    },
    {
        toggleText: "Contextual Memories",
        toggleIcon: <PiMagnifyingGlassBold size={30} />,
        onToggleClick: () => { console.log("Contextual Memories toggle clicked"); }
    },
    {
        toggleText: "Health & Wellness",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Health & Wellness toggle clicked"); }
    },
    {
        toggleText: "Wellness Activities",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Wellness Activities toggle clicked"); }
    },
    {
        toggleText: "Wellness Goals",
        toggleIcon: <PiSmileyBold size={30} />,
        onToggleClick: () => { console.log("Wellness Goals toggle clicked"); }
    },
];

export const allowedFileTypes = ".pdf, .txt, .go, .js, .py, .java, .html, .css, .json, .xml";