import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.8em;
  font-size: 15px;

  @media (max-width: 800px) {
    width: 2.5em;
    height: 1.6em;
    font-size: 15px;
  }
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -0.3em;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.isToggled ? props.theme.colors.primary : props.theme.colors.strokeFade)};
  transition: .4s;
  border-radius: 10px;

  &:before {
    position: absolute;
    content: "";
    height: 1.2em;
    width: 1.2em;
    border-radius: 8px;
    left: 0.3em;
    bottom: 0.35em;
    background-color: ${(props) => props.theme.colors.white};
    transition: .4s;
    transform: ${(props) => (props.isToggled ? 'translateX(1.5em)' : 'rotate(270deg)')};
    
    @media (max-width: 800px) {
      height: 1.1em;
      width: 1.1em;
      bottom: 0.28em;
      transform: ${(props) => (props.isToggled ? 'translateX(1.25em)' : 'rotate(270deg)')};
      border-radius: 6px;
    }
  }
  
  @media (max-width: 800px) {
    border-radius: 8px;
    left: -0.5em;
  }

  ${Checkbox}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }
`;

const CustomToggle = ({ isToggled, onToggle }) => {
    const { theme } = useTheme();

    const handleToggle = (e) => {
        if (onToggle) {
            onToggle(e);
        }
    }

    return (
        <Switch theme={theme}>
            <Checkbox
                type="checkbox"
                checked={isToggled}
                onChange={handleToggle}
            />
            <Slider isToggled={isToggled} theme={theme} />
        </Switch>
    );
};

export default CustomToggle;
