import styled from "styled-components";
import {useEffect, useState} from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useUser } from "../../contexts/UserContext";
import CustomText from "../atoms/CustomText";
import {getTypeColor} from "../../utils/utils";
import { FaChevronDown, FaRegTrashCan } from "react-icons/fa6";
import {deleteAllTypeNodes, deleteNode} from "../../api/api";

const VerticalListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    width: 100%;
    position: relative;
`;

const VerticalListItemInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    position: relative;
`;

const VerticalTypeItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    position: relative;
    background-color: ${props => props.theme.colors.sidebarBackground};
    border-radius: 8px;
    padding: 10px;
    width: calc(100% - 20px);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
     
    &:hover {
       transform: translateY(-2px) scale(1.01);
       background-color: ${props => props.theme.colors.primaryFaded};
    }
`;

const VerticalTypeItemLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const VerticalTypeItemButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const VerticalNodeItem = styled.div`
    margin-left: 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: relative;
    background-color: ${props => props.theme.colors.sidebarBackground};
    border-radius: 8px;
    padding: 10px;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    
    &:hover {
        transform: translateY(-2px) scale(1.01);
        background-color: ${props => props.theme.colors.primaryFaded};
    }
`;

const NodeText = styled(CustomText)`
    font-size: 1rem;
`;

const HighlightedText = styled.span`
    font-size: 1rem;
    background-color: ${props => props.theme.colors.primaryFade};
`;

const NumberOfText = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.6;
`;

const OuterItemText = styled(CustomText)`
    font-size: 1.2rem;
`;

const ChevronIcon = styled(FaChevronDown)`
    font-size: 1.1rem;
    color: ${props => props.theme.colors.border};
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: rotate(${props => props.expanded ? '180deg' : '0deg'});
`;

const DeleteIcon = styled(FaRegTrashCan)`
    font-size: 1rem;
    color: ${props => props.theme.colors.border};
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
    opacity: 0.5;
    
    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
`;

const ExpandableScrollList = ({ outerItems, innerItems, handleNodeClick, searchInput }) => {
    const { theme } = useTheme();
    const { user } = useUser();

    const [expandedTypes, setExpandedTypes] = useState([]);
    const [typesCounts, setTypesCounts] = useState(Object.keys(innerItems).reduce((acc, type) => ({ ...acc, [type]: innerItems[type].length }), {}));
    const [randomColors, setRandomColors] = useState(Object.keys(outerItems).reduce((acc, type) => ({ ...acc, [type]: getTypeColor(type, theme.colors, theme.randomColors) }), {}));

    const [searchFilterOuterItems, setSearchFilterOuterItems] = useState(outerItems);
    const [searchFilterInnerItems, setSearchFilterInnerItems] = useState(innerItems);

    useEffect(() => {
        setSearchFilterOuterItems(outerItems);
        setSearchFilterInnerItems(innerItems);
        setTypesCounts(Object.keys(innerItems).reduce((acc, type) => ({ ...acc, [type]: innerItems[type].length }), {}));
        setRandomColors(Object.keys(outerItems).reduce((acc, type) => ({ ...acc, [type]: getTypeColor(type, theme.colors, theme.randomColors) }), {}));
    }, [innerItems, outerItems]);

    useEffect(() => {
        if (!searchInput) {
            setSearchFilterOuterItems(outerItems);
            setSearchFilterInnerItems(innerItems);
            return;
        }
        const filteredOuterItems = outerItems.filter((item) => item.toLowerCase().includes(searchInput.toLowerCase()));
        setSearchFilterInnerItems(Object.keys(innerItems).reduce((acc, type) => ({ ...acc, [type]: innerItems[type].filter((item) => item.content.toLowerCase().includes(searchInput.toLowerCase())) }), {}));

        const filteredInnerOuterItems = outerItems.filter((item) => {
            return !!innerItems[item].find((innerItem) => innerItem.content.toLowerCase().includes(searchInput.toLowerCase()));
        });
        // combine filteredInnerOuterItems and filteredOuterItems
        const newOuterItems = [...new Set([...filteredOuterItems, ...filteredInnerOuterItems])];
        setSearchFilterOuterItems(newOuterItems);

    }, [searchInput]);

    const handleOnTypeClick = (type) => {
        setExpandedTypes(expandedTypes.includes(type) ? expandedTypes.filter((t) => t !== type) : [...expandedTypes, type]);
    }

    const handleDeleteByType = async (type) => {
        if (window.confirm(`Are you sure you want to delete every ${type}?`)) {
            await deleteAllTypeNodes(user.uid, user.selectedBackendProfileId, type);
            window.location.reload();
        }
    }

    const handleDeleteNode = async (event,item, type) => {
        if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
            event.stopPropagation();
            if (!item.id) {
                return;
            }
            await deleteNode(user.uid, user.selectedBackendProfileId, item.id);
        }
    }

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return <>{text}</>;
        }
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? <HighlightedText key={i} theme={theme}>{part}</HighlightedText> : part
        );
    };

    return (
        <VerticalListContainer>
            {searchFilterOuterItems.map((item, index) => (
                <VerticalListItemInner>
                    <VerticalTypeItem key={index} theme={theme} onClick={() => handleOnTypeClick(item)}>
                        <VerticalTypeItemLeft>
                            <OuterItemText>{item}</OuterItemText>
                            <NumberOfText>{typesCounts[item]}{window.innerWidth > 800 ? ' items' : ''}</NumberOfText>
                        </VerticalTypeItemLeft>
                        <VerticalTypeItemButtons>
                            <DeleteIcon theme={theme} onClick={() => handleDeleteByType(item)} />
                            <ChevronIcon theme={theme} expanded={expandedTypes.includes(item)} />
                        </VerticalTypeItemButtons>
                    </VerticalTypeItem>
                    {expandedTypes.includes(item) && searchFilterInnerItems[item].map((innerItem, innerIndex) => (
                        <VerticalListItemInner key={innerIndex}>
                            <VerticalNodeItem theme={theme} onClick={() => handleNodeClick(innerItem)}>
                                <NodeText>{highlightText(innerItem.content, searchInput)}</NodeText>
                                <VerticalTypeItemButtons>
                                    <DeleteIcon theme={theme} onClick={(event) => handleDeleteNode(event, innerItem, item)} />
                                </VerticalTypeItemButtons>
                            </VerticalNodeItem>
                        </VerticalListItemInner>
                    ))}
                </VerticalListItemInner>
            ))}
        </VerticalListContainer>
    );
}

export default ExpandableScrollList;