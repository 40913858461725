import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChange } from '../services/firebaseAuth';
import {
    createDefaultProfile,
    ensureDefaultAccountSettings,
    getUser,
    getUserWithProfiles
} from "../services/firebaseFirestore";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChange(async (firebaseUser) => {
            if (firebaseUser) {
                try {
                    await ensureDefaultAccountSettings(firebaseUser);
                    const { userData, backendProfiles } = await getUserWithProfiles(firebaseUser.uid);
                    setUser({
                        ...firebaseUser,
                        backendProfiles: backendProfiles,
                        selectedBackendProfileId: userData.lastUsedProfile || backendProfiles[0].id,
                        tokensUsed: userData.tokensUsed || 0,
                        tokenLimit: userData.tokenLimit || 1000,
                        stripeId: userData.stripeId || "",
                        lastUsedProfile: userData.lastUsedProfile || backendProfiles[0].id,
                        contextWindow: userData.lastUsedContextWindow || 10,
                    });
                } catch (error) {
                    setUser(firebaseUser);
                    console.error('Error getting user data:', error.message);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const refreshUser = async () => {
        if (user && user.emailVerified && !user.backendProfileIds) {
            try {
                const { userData, backendProfiles } = await getUserWithProfiles(user.uid);
                setUser({
                    ...user,
                    backendProfiles: backendProfiles,
                    selectedBackendProfileId: backendProfiles[0].id,
                    tokensUsed: userData.tokensUsed || 0,
                    tokenLimit: userData.tokenLimit || 1000,
                    stripeId: userData.stripeId || "",
                });
            } catch (error) {
                console.error('Error getting user data:', error.message);
            }
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, loading, refreshUser }}>
            {children}
        </UserContext.Provider>
    );
};