import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useUser } from '../contexts/UserContext';
import CustomText from "../components/atoms/CustomText";
import CustomButton from "../components/atoms/CustomButton";
import RegisterModal from "../components/organisms/RegisterModal";
import homeInactiveDark from "../assets/images/welcomeImages/HomeInactiveDark.png";
import homeInactiveLight from "../assets/images/welcomeImages/HomeInactiveLight.png";
import homeImageDark from "../assets/images/welcomeImages/HomeImageDark.png";
import homeImageLight from "../assets/images/welcomeImages/HomeImageLight.png";
import homeHistoryDark from "../assets/images/welcomeImages/HomeHistoryDark.png";
import homeHistoryLight from "../assets/images/welcomeImages/HomeHistoryLight.png";
import homeMathDark from "../assets/images/welcomeImages/HomeMathDark.png";
import homeMathLight from "../assets/images/welcomeImages/HomeMathLight.png";
import homeCodeDark from "../assets/images/welcomeImages/HomeCodeDark.png";
import homeCodeLight from "../assets/images/welcomeImages/HomeCodeLight.png";
import youDark from "../assets/images/welcomeImages/YouDark.png";
import youLight from "../assets/images/welcomeImages/YouLight.png";
import youHistoryDark from "../assets/images/welcomeImages/YouHistoryDark.png";
import youHistoryLight from "../assets/images/welcomeImages/YouHistoryLight.png";
import togglesDark from "../assets/images/welcomeImages/TogglesDark.png";
import togglesLight from "../assets/images/welcomeImages/TogglesLight.png";
import {
    PiBrainBold, PiCalendarBlankBold,
    PiChatCircleBold, PiGearBold, PiMagnifyingGlassBold, PiNewspaperBold,
    PiPathBold, PiQuotesBold, PiRepeatBold, PiSmileyBold
} from "react-icons/pi";
import { LuLanguages } from "react-icons/lu";
import SettingToggleButtons from "../components/molecules/SettingToggleButton";
import ExampleComponent from "../components/molecules/ExampleComponent";
import SpinningCircleText from "../components/atoms/SpinningCircleText";
import {FAQS} from "../utils/constants";
import FAQ from "../components/molecules/FAQ";
import {TbPodium} from "react-icons/tb";

const WelcomeScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  width: 100vw;
  overflow-x: hidden;
  
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.stroke};
  }
`;

const Header = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.backgroundFade};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 15px 15px 15px 30px;

  position: fixed;
  left: -10px;
  right: 0;
  z-index: 2;
  
  @media (max-width: 768px) {
    padding: 15px 25px;
    padding-right: 10px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const LogoText = styled(CustomText)`
  font-size: 24px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px 60px 30px;
  margin-bottom: 40px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.stroke};
  }
  
  @media (max-width: 768px) {
    padding: 150px 30px 30px;
  }
`;

const BigTextGradient = styled(CustomText)`
  font-size: 70px;
  text-align: center;
  margin-bottom: 10px;
  max-width: 80%;
  background: linear-gradient(135deg, ${props => props.theme.colors.primaryDark} 70%, ${props => props.theme.colors.text} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 1200px) {
    max-width: 90%;
  }
  
  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 1.2;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const BigTextSub = styled(CustomText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  
  font-size: 20px;
  text-align: center;
  color: ${(props) => props.theme.colors.mediumGray};
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BigTextContent = styled(CustomText)`
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.mediumGray};
  max-width: 60%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 80%;
  }
`;

const ColoredSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, ${props => props.theme.colors.primaryFaded} 40%, ${props => props.theme.colors.primaryFade} 100%);
  border-top: .1px solid ${(props) => props.theme.colors.primaryFade};
  border-bottom: .1px solid ${(props) => props.theme.colors.primaryFade};
  padding-top: 70px;
  border-top-right-radius: 125px;
  position: relative;
  width: 100%;
  
  @media (max-width: 768px) {
    border-top-right-radius: 100px;
  }
`;

const ColoredSectionTopRight = styled.div`
  position: absolute;
  top: 60px;
  right: 160px;
  z-index: 1;
  
  @media (max-width: 768px) {
    top: 50px;
    right: 100px;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 60px 30px;
  background-color: ${(props) => props.theme.colors.backgroundFade};
  border-top-right-radius: 100px;
  border: .5px solid ${(props) => props.theme.colors.strokeFaded};
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 768px) {
    padding: 25px 0 30px;
    border-top-right-radius: 70px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  margin-bottom: 50px;
`;

const FooterPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-right: 2px solid ${(props) => props.theme.colors.stroke};
  padding: 0 30px;
  gap: 12px;
  
  @media (max-width: 768px) {
    padding: 0 20px;
    border-right: 2px solid ${(props) => props.theme.colors.strokeFaded};
  }
`;

const FooterTitle = styled(CustomText)`
  font-size: 20px;
  margin-bottom: 10px;
  opacity: 0.8;
`;

const FooterText = styled(CustomText)`
  font-size: 16px;
  opacity: 0.8;
  
  &:hover {
    cursor: pointer;
    opacity: 1;
    text-decoration: underline;
  }
`;

const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const ToggleContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin-top: 30px;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ExampleImage = styled.img`
  width: 60%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  border-radius: 10px 10px 0 0;
  z-index: 1;
  
  @media (max-width: 1400px) {
    width: 60%;
  }
  
  @media (max-width: 768px) {
    width: 70%;
    align-self: center;
  }
`;

const TextBanner = styled.div`
  background-color: ${(props) => props.theme.colors.primaryFaded};
  color: ${(props) => props.theme.colors.primary};
  font-family: 'Poppins', serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px 10px 0;
  padding: 6px 14px;
  border-radius: 50px;
  align-self: center;
  text-align: center;
`;

const TextBannerSmall = styled.div`
  background-color: ${(props) => props.theme.colors.primaryFaded};
  color: ${(props) => props.theme.colors.primary};
  font-family: 'Poppins', serif;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0 10px 10px 0;
  padding: 6px 12px;
  border-radius: 50px;
  align-self: center;
  text-align: center;
`;

const toggles = [
    {
        toggleText: "Traits",
        toggleIcon: <PiBrainBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Interests",
        toggleIcon: <PiGearBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Hobbies",
        toggleIcon: <PiRepeatBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Quotes",
        toggleIcon: <PiQuotesBold size={30} />,
        toggled: true,
        onToggleClick: () => null,
    },
    {
        toggleText: "Understandings",
        toggleIcon: <PiChatCircleBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Language Patterns",
        toggleIcon: <LuLanguages size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Readings",
        toggleIcon: <PiNewspaperBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Lectures",
        toggleIcon: <TbPodium size={30} />,
        toggled: true,
        onToggleClick: () =>null
    },
    {
        toggleText: "Syllabus",
        toggleIcon: <PiPathBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Emotional States",
        toggleIcon: <PiSmileyBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Events",
        toggleIcon: <PiCalendarBlankBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Goals",
        toggleIcon: <PiPathBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Learning Styles",
        toggleIcon: <PiBrainBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Social Dynamics",
        toggleIcon: <PiChatCircleBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Preferences",
        toggleIcon: <PiGearBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Behavioural Patterns",
        toggleIcon: <PiRepeatBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Learning Objectives",
        toggleIcon: <PiMagnifyingGlassBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
    {
        toggleText: "Morals",
        toggleIcon: <PiSmileyBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Contextual Memories",
        toggleIcon: <PiMagnifyingGlassBold size={30} />,
        toggled: false,
        onToggleClick: () => null
    },
    {
        toggleText: "Health & Wellness",
        toggleIcon: <PiSmileyBold size={30} />,
        toggled: true,
        onToggleClick: () => null
    },
];

const WelcomeScreen = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { user } = useUser();

    const customToggles = window.innerWidth < 768 ? toggles.slice(0, 10) : toggles;

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);

    const handleRegisterClick = (isLogin) => {
        setShowLoginForm(isLogin);
        setIsRegisterModalVisible(true);
    };

    const navigateToTerms = () => {
        navigate('/terms');
    }

    const navigateToPrivacy = () => {
        navigate('/privacy');
    }

    const handleContactClick = () => {
        window.open('mailto:nevstads@gmail.com');
    }

    const handleContactFeedbackClick = () => {
        window.open('mailto:nevstads@gmail.com');
    }

    return (
        <WelcomeScreenContainer theme={theme}>
            <Header theme={theme}>
                <LogoText type="bold">
                    OwnAI
                </LogoText>
                <HeaderSection>
                    {/*{window.innerWidth > 768 &&*/}
                    {/*    <a href="https://www.producthunt.com/posts/adept-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-adept&#0045;2" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=435916&theme=neutral" alt="OwnAI - Your&#0032;evolving&#0032;supercharged&#0032;AI | Product Hunt" width="230" height="54" style={{ marginRight: 5 }} /></a>*/}
                    {/*}*/}
                    <CustomButton
                        style={{ backgroundColor: theme.colors.background, color: theme.colors.text, border: `.5px solid #2E3D493C`}}
                        onClick={() => handleRegisterClick(false)}
                    >
                        Login
                    </CustomButton>
                    <CustomButton
                        onClick={() => handleRegisterClick(true)}
                    >
                        Get Started
                    </CustomButton>
                </HeaderSection>
            </Header>
            <MainContent theme={theme}>
                <BigTextGradient type="bold" theme={theme}>
                    Your personal, evolving, supercharged AI
                </BigTextGradient>
                <BigTextSub theme={theme} type="medium">
                    OwnAI <CustomText style={{ color: theme.colors.primary }} type="semiBold">remembers</CustomText> and
                    <CustomText style={{ color: theme.colors.primary }} type="semiBold">tailors</CustomText> itself to you over time.
                </BigTextSub>
                <CustomButton
                    style={{ marginTop: 50 }}
                    onClick={() => handleRegisterClick(true)}
                >
                    Get Started for Free
                </CustomButton>
            </MainContent>
            <ColoredSection theme={theme}>
                <ColoredSectionTopRight>
                    <SpinningCircleText text="FREE  ✦  BETA  ✦  FREE  ✦  BETA  ✦  " theme={theme} />
                </ColoredSectionTopRight>
                <ExampleImage
                    src={theme.name === 'light' ? homeCodeLight : homeCodeDark}
                    alt="OwnAI Example"
                    theme={theme}
                />
            </ColoredSection>
            {/*<GreenTextBanner theme={theme} style={{ marginTop: 70 }}>*/}
            {/*    Trusted by students at*/}
            {/*</GreenTextBanner>*/}
            {/*<LogoSlider />*/}
            <ExampleContainer style={{ marginTop: 40 }}>
                {/*<BigTextContent theme={theme} type="medium">*/}
                {/*    Create profiles and watch your AI evolve, tailoring its intelligence to your unique needs.*/}
                {/*    Personal profiles deepen the AI's familiarity with you while class profiles allow the AI to understand the needs and dynamics of your classroom.*/}
                {/*</BigTextContent>*/}
                <TextBanner theme={theme} style={{ backgroundColor: theme.colors.greenFade, color: theme.colors.green, marginBottom: 40 }}>
                    Used by 1000+ people
                </TextBanner>
                <ExampleComponent
                    image={theme.name === 'light' ? youLight  : youDark}
                    title="Your own AI"
                    tag="Memory"
                    description="Your personal AI can remember and evolves with every interaction. It's more than an AI, it's a companion that gets to know you."
                    color={theme.colors.primary}
                    colorFade={theme.colors.primaryFaded}
                />
                <ExampleComponent
                    image={theme.name === 'light' ? homeImageLight : homeImageDark}
                    title="Image Generation"
                    tag="Free"
                    description="Bring your creative visions to life with state-of-the-art image generation. Unlocking endless possibilities for creativity and innovation."
                    color={theme.colors.purple}
                    colorFade={theme.colors.purpleFade}
                />
                <ExampleComponent
                    image={theme.name === 'light' ? youHistoryLight : youHistoryDark}
                    title="GPT with Memory"
                    tag="Personalized"
                    description="OwnAI's evolving memory system lets it remember and learn from past interactions, ensuring a continuously personalized and enriched experience."
                    // With OwnAI's evolving memory system, your AI remembers and learns from past interactions, ensuring a continuously personalized and enriched experience.
                    color={theme.colors.green}
                    colorFade={theme.colors.greenFade}
                />
                <ExampleComponent
                    image={theme.name === 'light' ? homeMathLight : homeMathDark}
                    title="Supercharged"
                    tag="Innovative"
                    description="Leverage supercharged tools such as humanization and web search to enhance your AI's capabilities. Our innovative features are designed to elevate your experience."
                    color={theme.colors.orange}
                    colorFade={theme.colors.orangeFade}
                />
                <ExampleComponent
                    image={theme.name === 'light' ? homeHistoryLight : homeHistoryDark}
                    title="Multi-Modal"
                    tag="Adaptive"
                    description="Engage with our AI through multiple modes of communication. Whether it's text, voice, or other interactive methods, our system is equipped to understand and respond effectively."
                    color={theme.colors.yellow}
                    colorFade={theme.colors.yellowFade}
                />
                <ExampleComponent
                    image={theme.name === 'light' ? togglesLight : togglesDark}
                    title="Your Data, Your Control"
                    tag="Secure"
                    description="We prioritize your privacy. You have complete control over your data, with transparent settings to manage your preferences and interactions."
                    color={theme.colors.purple}
                    colorFade={theme.colors.purpleFade}
                />
                {/*<ExampleComponent*/}
                {/*    image={theme.name === 'light' ? homeInactiveLight : homeInactiveDark}*/}
                {/*    title="Designed for You"*/}
                {/*    tag="Intuitive"*/}
                {/*    description="Experience a user-centric design that makes interactions seamless and enjoyable. Our interface is intuitive, facilitating easy navigation and engagement."*/}
                {/*    color={theme.colors.primary}*/}
                {/*    colorFade={theme.colors.primaryFaded}*/}
                {/*/>*/}
            </ExampleContainer>

            {/*<ToggleContainer>*/}
            {/*    <TextBanner theme={theme} style={{ marginBottom: 50 }}>*/}
            {/*        Toggle what you want to learn about*/}
            {/*    </TextBanner>*/}
            {/*    <SettingToggleButtons toggleButtons={customToggles} />*/}
            {/*    {window.innerWidth < 768 &&*/}
            {/*        <TextBannerSmall theme={theme} style={{ backgroundColor: theme.colors.greenFade, color: theme.colors.green, marginTop: 20 }}>*/}
            {/*            Plus more!*/}
            {/*        </TextBannerSmall>*/}
            {/*    }*/}
            {/*</ToggleContainer>*/}

            <ToggleContainer style={{ marginBottom: 100, marginTop: 50 }}>
                <TextBanner theme={theme} style={{ marginBottom: 20 }}>
                    FAQs
                </TextBanner>
                {FAQS.map((faq, index) => (
                    <FAQ key={index} question={faq.question} answer={faq.answer} />
                ))}
            </ToggleContainer>

            <FooterSection theme={theme}>
                <FooterContent>
                    <FooterPart theme={theme}>
                        <FooterTitle>
                            Contact
                        </FooterTitle>
                        <FooterText type="medium" onClick={handleContactClick}>
                            Press inquiries
                        </FooterText>
                        <FooterText type="medium" onClick={handleContactFeedbackClick}>
                            Support
                        </FooterText>
                        <FooterText type="medium" onClick={handleContactClick}>
                            Contact
                        </FooterText>
                    </FooterPart>
                    <FooterPart theme={theme}>
                        <FooterTitle>
                            Legal
                        </FooterTitle>
                        <FooterText type="medium" onClick={navigateToTerms}>
                            Terms & Conditions
                        </FooterText>
                        <FooterText type="medium" onClick={navigateToPrivacy}>
                            Privacy Policy
                        </FooterText>
                    </FooterPart>
                </FooterContent>
                <FooterText type="medium">
                    © 2023 OwnAI by SN
                </FooterText>
            </FooterSection>
            <RegisterModal open={isRegisterModalVisible} handleClose={() => setIsRegisterModalVisible(false)} login={showLoginForm} />
        </WelcomeScreenContainer>
    );
};

export default WelcomeScreen;