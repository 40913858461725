import React, {useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.primaryFaded};
  border: 1px solid ${props => props.theme.colors.primaryFade};
  gap: 8px;
  margin: 1px;
`;

const ContextItem = ({ context }) => {
    const { theme } = useTheme();
    const [showMediumText, setShowMediumText] = useState(false);
    const [showFullText, setShowFullText] = useState(false);

    const shortedText = context.metadata.text.length > 50 ? context.metadata.text.substring(0, 50) + "..." : context.metadata.text;
    const mediumText = context.metadata.text.length > 100 ? context.metadata.text.substring(0, 100) + "..." : context.metadata.text;
    const fullText = context.metadata.text;

    return (
        <ItemContainer
            theme={theme}
            onMouseEnter={() => setShowMediumText(true)}
            onMouseLeave={() => setShowMediumText(false)}
            onClick={() => setShowFullText(!showFullText)}
        >
            <CustomText type="medium">{showFullText ? fullText : showMediumText ? mediumText : shortedText}</CustomText>
        </ItemContainer>
    );
}

export default ContextItem;