import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "./CustomText";

const DividerContainer = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.strokeFaded};
    margin: 5px 0;
`;

const Divider = () => {
    const { theme } = useTheme();

    return (
        <DividerContainer theme={theme}/>
    );
}

const DividerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`;

const DividerText = styled(CustomText)`
  margin: 0 10px;
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.textFaded};
`;

const DividerWithText = ({ text }) => {
    const { theme } = useTheme();

    return (
        <DividerRow>
            <DividerContainer theme={theme}/>
            <DividerText theme={theme} type="semiBold">{text}</DividerText>
            <DividerContainer theme={theme}/>
        </DividerRow>
    );
}

export default DividerWithText;