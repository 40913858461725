import React, {useState, useEffect, useRef} from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styled, { keyframes } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import '../../assets/fonts/fonts.css'
import { allowedFileTypes } from "../../utils/constants";
import { speechToText } from "../../api/api";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import SuggestionButton from "../molecules/SuggestionButton";
import FileIcon from "../molecules/FileIcon";
import { PiPaperclipBold, PiMicrophoneBold } from 'react-icons/pi';
import { FaArrowUp } from 'react-icons/fa6';
import { FaRegStopCircle } from 'react-icons/fa';
import StatusIndicator from "./StatusIndicator";

const fadeInDropIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDropOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  position: absolute;
  bottom: 23px;
  
  @media (max-width: 1750px) {
    width: 60%;
  }
  
  @media (max-width: 1600px) {
    width: 62%;
  }
  
  @media (max-width: 1400px) {
    width: 66%;
  }
  
  @media (max-width: 1100px) {
    width: 70%;
  }
  
  @media (max-width: 1000px) {
    width: 75%;
  }
  
  @media (max-width: 850px) {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    width: 85%;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const InnerContainer = styled.div`
  border: 1px solid ${(props) => (props.theme.name === "dark" ? props.theme.colors.strokeFaded : props.theme.colors.strokeFade)};
  border-radius: 12px;
  margin: 5px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ConnectionStatusContainer = styled.div`
    position: absolute;
    left: -25px;
`;

const Input = styled(TextareaAutosize)`
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  font-size: 1.07rem;
  font-family: 'Poppins', serif;
  font-weight: 500;
  resize: none;
  width: 100%;
  padding: 10px;

  &:focus {
    outline: none;
  }
`;

const SendButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.strokeFade};
  opacity: 0.6;
  border-radius: 12px;
  padding: 7px;
  margin-right: 6px;
`;

const SendButton = styled(FaArrowUp)`
  color: ${(props) => props.theme.colors.background};
`;

const PaperClipButton = styled(PiPaperclipBold)`
  color: ${(props) => props.theme.name === "dark" ? props.theme.colors.stroke : props.theme.colors.border};
  opacity: 0.8;
  cursor: pointer;
  margin-left: 12px;
  margin-top: 12px;
  align-self: flex-start;
  transform: rotate(45deg) scaleX(-1);
`;

const MicrophoneButtonContainer = styled.div`
  position: absolute;
  bottom: 13px;
  right: -43px;
  display: flex;
`;

const MicrophoneButton = styled(PiMicrophoneBold)`
  color: ${(props) => props.isrecording === "true" ? props.theme.colors.red : props.theme.colors.border};
  opacity: 1;
  cursor: pointer;
  // background-color: ${(props) => props.isrecording === "true" ? props.theme.colors.redFade : props.theme.colors.strokeFaded};
  border-radius: 25px;
  padding: 5px;
  
  &:hover {
    color: ${(props) => props.isrecording === "true" ? props.theme.colors.red : props.theme.colors.primary};
    background-color: ${(props) => props.isrecording === "true" ? props.theme.colors.redFade : props.theme.colors.primaryFaded};
    transform: scale(1.05);
  }
  
  &:active {
    transform: scale(0.97);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const SuggestionButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 5px;
  animation: ${props => props.isRefreshing ? fadeOutDropOut : fadeInDropIn} 0.5s ease forwards;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 5px;
  max-width: 100%;
`;

const MessageInput = ({
    placeholder, onSend,
    selectedFiles, handleFileSelect, handleRemoveFile, handleAudioFileSelect,
    loading, suggestions, showSuggestions, isRefreshing, isConnected }) => {
    const { theme } = useTheme();
    const [message, setMessage] = useState('');

    const fileInputRef = useRef(null);

    const handleFileIconClick = () => {
        fileInputRef.current.click();
    };

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording
    } = useAudioRecorder();

    useEffect(() => {
        if (recordingBlob) {
            handleOnRecordingComplete(recordingBlob);
        }
    }, [recordingBlob]);

    const handleOnRecordingComplete = async (blob) => {
        const audioFile = new File([blob], "audio.mp3", { type: 'audio/mp3' });
        try {
            const response = await speechToText(audioFile);
            setMessage(response.transcription);
        } catch (error) {
            console.error('Error converting speech to text:', error);
            // Handle the error appropriately in the UI
        }
    };

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        onSend(message);
        setMessage('');
    }

    const handleSendSuggestion = async (suggestion) => {
        onSend(suggestion);
        setMessage('');
    };

    const handleMicrophoneClick = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    return (
        <OuterContainer>
            {showSuggestions && (
                <SuggestionButtonContainer isRefreshing={isRefreshing} theme={theme}>
                    {Object.entries(suggestions).map(([suggestionHook, suggestionFollowUp], index) => (
                        <SuggestionButton
                            key={index}
                            suggestionHook={suggestionHook}
                            suggestionFollowUp={suggestionFollowUp}
                            onSuggestionClick={handleSendSuggestion}
                        />
                    ))}
                    {/*<SuggestionRefreshButton size={24} onClick={refreshSuggestions} theme={theme}/>*/}
                </SuggestionButtonContainer>
            )}
            <InnerContainer theme={theme}>
                {selectedFiles.length > 0 &&
                    <FilesContainer>
                        {Array.from(selectedFiles).map((file, index) => (
                            <FileIcon
                                key={index}
                                fileName={file.name}
                                onRemove={() => handleRemoveFile(index)}
                                del
                            />
                        ))}
                    </FilesContainer>
                }
                <InputContainer>
                    <ConnectionStatusContainer>
                        <StatusIndicator isConnected={isConnected} />
                    </ConnectionStatusContainer>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        multiple
                        accept={allowedFileTypes}
                    />
                    <PaperClipButton onClick={handleFileIconClick} size={24} theme={theme} />
                    <Input
                        placeholder={isConnected ? placeholder : "Please refresh..." }
                        value={message}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        maxRows={12}
                        theme={theme}
                    />
                    {window.innerWidth <= 1000 ?
                        <SendButtonContainer theme={theme} style={{ backgroundColor: 'transparent' }}>
                            <MicrophoneButton
                                onClick={handleMicrophoneClick}
                                size={24}
                                isrecording={isRecording.toString()}
                                theme={theme}
                            />
                        </SendButtonContainer>
                    :
                        <SendButtonContainer theme={theme}>
                            <SendButton onClick={handleSend} size={20} theme={theme} />
                        </SendButtonContainer>
                    }
                </InputContainer>
                {window.innerWidth > 1000 &&
                    <MicrophoneButtonContainer>
                        <MicrophoneButton
                            onClick={handleMicrophoneClick}
                            size={24}
                            isrecording={isRecording.toString()}
                            theme={theme}
                        />
                    </MicrophoneButtonContainer>
                }
            </InnerContainer>
        </OuterContainer>
    );
};

export default MessageInput;
