import {FIREBASE_AUTH_ERROR_MAP} from "./constants";

export function cleanString(input) {
    // Add a space before capital letters if there isn't one, excluding the first character
    let result = input[0];
    for (let i = 1; i < input.length; i++) {
        if (input[i] === input[i].toUpperCase() && input[i] !== input[i].toLowerCase()) {
            // Check if the previous character is not a space and the current character is not a space or digit and the next letter is not a capital letter
            if (input[i - 1] !== ' ' && !input[i].match(/[0-9]/)) {
                result += ' ';
            }
        }
        result += input[i];
    }

    return result;
}

// cleanNodeType capitalizes the first letter of the node type and replaces underscores with spaces
export const cleanNodeType = (nodeType) => {
    return nodeType.charAt(0).toUpperCase() + nodeType.slice(1).replace(/_/g, ' ');
}

export const genRandomColor = (colors) => {
    return colors[Math.floor(Math.random() * colors.length)];
}

export const getTypeColor = (type, colorMap, randomColors) => {
    switch (type) {
        case 'Interest':
            return colorMap.greenFade;
        case 'Hobby':
            return colorMap.purpleFade;
        case 'Trait':
            return colorMap.greenFade;
        default:
            return genRandomColor(randomColors);
    }
}

export const convertSentimentAnalysisToString = (analysis) => {
    let analysisString = "Sentiment:\n";
    if (analysis) {
        Object.keys(analysis).forEach(key => {
            analysisString += `${key}: ${analysis[key]}, `;
        });
    }
    return analysisString;
}

export const convertEssayConstructionToString = (construction) => {
    let constructionString = "Essay Construction:\n";
    if (construction) {
        Object.keys(construction).forEach(key => {
            if (key === "paragraphs") {
                constructionString += `${key}: ${construction[key].length} paragraphs, `;
            } else {
                constructionString += `${key}: ${construction[key]}, `;
            }
        });
    }
    return constructionString;
}

export const getErrorMessage = (err) => {
    if (!err.response) {
        return 'Sorry, I could not understand that. Please try reloading the conversation and submitting your message again.';
    }
    switch (err.response.data.error) {
        case "Failed to handle image tool call":
            return 'Sorry, I\'m having trouble creating your image. Please try reloading the conversation and submitting your message again.';
        case "Failed to handle essay tool call":
            return 'Sorry, I\'m having trouble creating your essay. Please try reloading the conversation and submitting your message again.';
        case "Failed to handle humanize tool call":
            return 'Sorry, I\'m having trouble humanizing your message. Please try reloading the conversation and submitting your message again.';
        case "Failed to retrieve class profile":
            return 'Sorry, I\'m having trouble with you message. Please try reloading the conversation and submitting again.';
        case "Failed to retrieve user profile":
            return 'Sorry, I\'m having trouble with you message. Please try reloading the conversation and submitting again.';
        case "failed to process pdf":
            return 'Sorry, the PDF you submitted is in image format and cannot be processed. Please try reloading the conversation and submitting a different file.';
        case "failed to process files":
            return 'Sorry, the files you submitted could not be processed. Please try reloading the conversation and submitting them again.';
        case 'Failed to send message':
            switch (err.response.data.details) {
                case "rate limiting, engine overloading":
                    return 'Sorry, the server is overloaded. Please try again in a few minutes.';
                case "internal server error":
                    return 'Sorry, there was an internal server error. Please try reloading the conversation and submitting your message again.';
                case "unauthorized":
                    return 'Sorry, you are not authorized to send messages. Please try reloading the conversation and submitting your message again.';
                case "service unavailable":
                    return 'Sorry, the server is unavailable. Please try again in a few minutes.';
                default:
                    return 'Sorry, I could not understand that. Please try reloading the conversation and submitting your message again.';
            }
        case "Failed to send message, not connected":
            return 'Sorry, you\'ve been disconnected. Please reload the conversation and submit your message again.';
        case 'Input limit exceeded':
            return 'Sorry, the message you submitted is too long. Please try reloading the conversation and submitting something shorter.';
        default:
            return 'Sorry, I could not understand that. Please try reloading the conversation and submitting your message again.';
    }
};

// Helper function to get message limit based on tier
export const getTierMessageLimit = (tier) => {
    switch (tier.toLowerCase()) {
        case 'free': return 3;
        case 'pro': return 30;
        case 'unlimited': return 0;
        default: return 3;
    }
};

// Helper function to return the firebase auth error message
export const getFirebaseAuthErrorMessage = (errorCode) => {
    return FIREBASE_AUTH_ERROR_MAP[errorCode] || FIREBASE_AUTH_ERROR_MAP['default'];
};

export const checkTokenLimit = async (tokensUsed, tokenLimit) => {
    if (tokensUsed >= tokenLimit) {
        // User has reached their limit
        const difference = tokensUsed - tokenLimit;
        return { canSend: false, difference };
    } else {
        // Update the count without resetting the window
        return { canSend: true, difference: 0 };
    }
}