import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
// import { useNavigate } from "react-router-dom";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100vh;
`;

const SupportScreen = () => {
    const { theme } = useTheme();
    // const navigate = useNavigate();
    //
    // const handleBack = () => {
    //     navigate(-1);
    // }

    return (
        <ScreenContainer theme={theme}>

        </ScreenContainer>
    );
};

export default SupportScreen
