import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";
import CustomToggle from "../atoms/Toggle";
import { PiInfoBold, PiSliders } from "react-icons/pi";
import { FaChevronRight } from "react-icons/fa6";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsPersonBoundingBox } from "react-icons/bs";
import { PiGlobeSimple, PiImageDuotone } from "react-icons/pi";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContextModeText = styled(CustomText)`
    font-size: ${props => props.isselected === "true" ? '0.95rem' : '0.85rem'};
    opacity: ${props => props.isselected === "true" ? '1' : '0.7'};
`;

const ContextModeButton = styled.button`
  padding: 6px 15px;
  margin: 0 5px;
  // background-color: ${props => props.theme.colors.background};
  background-color: ${props => props.isselected === "true" ? props.theme.colors.primary : props.theme.colors.background};
  color: ${props => props.isselected === "true" ? '#fff' : props.theme.colors.text};
  border-radius: 25px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const AdvancedText = styled(CustomText)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.8rem;
  opacity: 0.8;
`;

const DownChevron = styled(FaChevronRight)`
    color: ${(props) => props.theme.colors.text};
    opacity: 0.8;
    transform: ${(props) => props.show === "true" ? 'rotate(90deg)' : 'rotate(0deg)'};
    font-size: .8rem;
`;

const SegmentSliderContainer = styled.div`
  width: 100%;
  display: ${props => props.show === "true" ? 'flex' : 'none'};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
`;

const SlidersButton = styled(PiSliders)`
  font-size: 1.5rem;
  padding: 10px;
  color: ${(props) => (props.theme.colors.text)};
  opacity: 0.8;
  z-index: 99;
  cursor: pointer;
  align-self: flex-end;
  background-color: ${(props) => props.expanded === "true" ? props.theme.colors.sidebarBackgroundFade : 'transparent'};
  border-radius: 10px;
  
  &:hover {
    font-size: 1.6rem;
    transition: font-size 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  background-color: ${(props) => props.theme.colors.background};
  border: .8px solid ${(props) => props.theme.colors.strokeFade};
  border-radius: 10px;
  gap: 3px;
  z-index: 100;
  box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.1);
`;

const InfoText = styled(CustomText)`
  font-size: 0.9rem;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border-radius: 5px;
  padding: 4px 5px;
  display: ${(props) => props.show === "true" ? 'block' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  max-width: 200px;
  transform: translateX(-100%);
  // opacity: 0.8;
`;

const ModalColumnHeader = styled(CustomText)`
  font-size: 1rem;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px 10px 12px;
  position: relative;
`;

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  position: relative;
  opacity: ${(props) => (props.isOn ? '1' : '0.5')};
`;

const ModalRowHoverEffect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 1px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  opacity: ${(props) => (props.isOn ? '1' : '0.5')};
  position: relative;
  
  &:hover {
    cursor: pointer;
    //background-color: ${(props) => props.theme.colors.sidebarBackgroundFade};
  }
`;

const ModalRowText = styled(CustomText)`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const ModalRowInfoIcon = styled(PiInfoBold)`
  color: ${(props) => props.theme.colors.border};
  opacity: 1;
  margin-left: 5px;
`;

const ModalRowSubText = styled(CustomText)`
  font-size: 0.8rem;
  opacity: 0.8;
  min-width: 140px;
  width: 100%;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ModalToolsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
`;

const ModalToolLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ModalToolTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  width: 100%;
`;

const ToolInfoText = styled(CustomText)`
  font-size: 0.9rem;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border-radius: 5px;
  padding: 4px 5px;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  display: ${(props) => props.show ? 'block' : 'none'};
  opacity: 0.8;
`;

const ModalTool = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px 10px 12px;
  border-radius: 10px;
  gap: 20px;
  opacity: ${(props) => (props.isOn ? '1' : '0.5')};
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border: 1px solid ${(props) => (props.theme.name === "dark" ? props.theme.colors.border : props.theme.colors.stroke)};
  width: 90%;
  
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.sidebarBackgroundFade};

    ${ToolInfoText} {
      animation-play-state: running;
    }
  }
  
  &:active {
    ${ToolInfoText} {
      display: none;
    }
    
    transform: scale(0.97);
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

const ModalToolEssayIcon = styled(HiOutlineDocumentText)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.9;
`;

const ModalToolHumanizerIcon = styled(BsPersonBoundingBox)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.9;
`;

const ModalToolGlobeIcon = styled(PiGlobeSimple)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.9;
`;

const ModalToolImageIcon = styled(PiImageDuotone)`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.9;
`;

const Segment = styled.div`
  flex: 1;
  margin: .5px;
  background-color: ${props => props.isBeforeHovered ? props.theme.colors.primary : props.theme.name === "dark" ? props.theme.colors.border : props.theme.colors.stroke};
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    // background-color: ${props => props.active ? props.theme.colors.stroke : props.theme.colors.primary};
    transform: scale(1.05);
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

const SegmentedSlider = ({ value, onChange }) => {
    const { theme } = useTheme();
    const [hoveredIndex, setHoveredIndex] = useState(-1); // State to track hovered segment
    const segments = [];

    for (let i = 1; i <= 20; i++) {
        const isBeforeHovered = hoveredIndex >= 0 ? i <= hoveredIndex : i <= value;
        segments.push(
            <Segment
                key={i}
                active={i <= value}
                isBeforeHovered={isBeforeHovered}
                onClick={() => onChange(i)}
                theme={theme}
                onMouseEnter={() => setHoveredIndex(i)} // Set hovered index
                onMouseLeave={() => setHoveredIndex(-1)} // Reset on mouse leave
            />
        );
    }

    return <div style={{ display: 'flex', width: '100%' }}>
        {segments}
        <CustomText type="medium" style={{ marginLeft: 10 }}>{value}</CustomText>
    </div>;
};

const ContextModeSelector = ({ selectedMode, setSelectedMode, contextWindow, setContextWindow, setIsPersonal, handleMouseEnter, handleMouseLeave }) => {
    const { theme } = useTheme();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [showContextWindowInfoText, setShowContextWindowInfoText] = useState(false);

    const modeNames = ['Off', 'Turbo', 'Balanced', 'All'];
    const modeValues = {'Off': 0, 'Turbo': 1, 'Balanced': 10, 'All': 20 };

    const handleModeChange = (mode) => {
        setSelectedMode(mode);
        setContextWindow(modeValues[mode]);
        if (mode === 'Off') {
            setIsPersonal(false);
        } else {
            setIsPersonal(true);
        }
    };

    const handleAdvancedModeChange = (value) => {
        setIsPersonal(true);
        const mode = modeNames.find(mode => modeValues[mode] === value);
        if (mode) {
            setSelectedMode(mode);
        } else {
            setSelectedMode('Custom');
        }
        setContextWindow(value);
    }

    return (
        <ModalColumn style={{ marginTop: 5 }}>
            <ModalColumnHeader type="medium">
                Memory Recall
                <ModalRowInfoIcon
                    size={20}
                    onMouseEnter={() => handleMouseEnter(setShowContextWindowInfoText)}
                    onMouseLeave={() => handleMouseLeave(setShowContextWindowInfoText)}
                    style={{ marginLeft: 0 }}
                    theme={theme}
                />
            </ModalColumnHeader>
            <InfoText show={showContextWindowInfoText.toString()} theme={theme}>
                Higher settings offer more relevant and accurate responses by using more memory, but take longer. Lower settings provide faster responses with less context.
            </InfoText>
            <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0' }}>
                {modeNames.map(mode => (
                    <ContextModeButton
                        key={mode}
                        isselected={(selectedMode === mode).toString()}
                        onClick={() => handleModeChange(mode)}
                        theme={theme}
                    >
                        <ContextModeText type="medium" isselected={(selectedMode === mode).toString()} style={{ color: selectedMode === mode ? '#fff' : theme.colors.text }}>{mode}</ContextModeText>
                    </ContextModeButton>
                ))}
            </div>
            <AdvancedText onClick={() => setShowAdvanced(!showAdvanced)} theme={theme}>
                Advanced
                <DownChevron show={showAdvanced.toString()} theme={theme} />
            </AdvancedText>
            <SegmentSliderContainer show={showAdvanced.toString()}>
                <SegmentedSlider value={contextWindow} onChange={handleAdvancedModeChange} />
            </SegmentSliderContainer>
        </ModalColumn>
    );
};

const ToolsSelectorModal = ({
    isPersonal, setIsPersonal,
    isEssayWriterOn, setIsEssayWriterOn,
    isHumanizerOn, setIsHumanizerOn,
    isWebSearchOn, setIsWebSearchOn,
    isImageGenOn, setIsImageGenOn,
    contextWindow, setContextWindow
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { theme } = useTheme();
    const modalRef = useRef(null);

    const [selectedMode, setSelectedMode] = useState('Balanced');

    const handleMouseEnter = (setShowText) => {
        setShowText(true);
    };

    const handleMouseLeave = (setShowText) => {
        setShowText(false);
    };

    const handleOpenClick = () => {
        setIsExpanded(!isExpanded);
    }

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('svg')) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    return (
        <OuterContainer>
            <SlidersButton
                onClick={handleOpenClick}
                theme={theme}
                expanded={isExpanded.toString()}
            />
            {isExpanded && (
                <ModalContainer theme={theme}  ref={modalRef}>
                    <ContextModeSelector
                        selectedMode={selectedMode}
                        setSelectedMode={setSelectedMode}
                        contextWindow={contextWindow}
                        setContextWindow={setContextWindow}
                        setIsPersonal={setIsPersonal}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                    />
                    <ModalColumn>
                        <ModalColumnHeader type="medium">Built-In Tools</ModalColumnHeader>
                        <ModalToolsContainer>
                            <ModalTool
                                theme={theme}
                                onClick={() => setIsImageGenOn(!isImageGenOn)}
                                isOn={isImageGenOn}
                            >
                                <ModalToolLeftContainer>
                                    <ModalToolImageIcon size={35} theme={theme} />
                                    <ModalToolTextContainer>
                                        <ModalRowText>Image Generation</ModalRowText>
                                        <ModalRowSubText>Illustrate your ideas</ModalRowSubText>
                                    </ModalToolTextContainer>
                                </ModalToolLeftContainer>
                                <ToggleContainer onClick={(e) => e.preventDefault()}>
                                    <CustomToggle isToggled={isImageGenOn} onToggle={(e) => setIsImageGenOn(e)} />
                                </ToggleContainer>
                            </ModalTool>
                            <ModalTool
                                theme={theme}
                                onClick={() => setIsWebSearchOn(!isWebSearchOn)}
                                isOn={isWebSearchOn}
                            >
                                <ModalToolLeftContainer>
                                    <ModalToolGlobeIcon size={35} theme={theme} />
                                    <ModalToolTextContainer>
                                        <ModalRowText>Internet Access</ModalRowText>
                                        <ModalRowSubText>Browse the web</ModalRowSubText>
                                    </ModalToolTextContainer>
                                </ModalToolLeftContainer>
                                <ToggleContainer onClick={(e) => e.preventDefault()}>
                                    <CustomToggle isToggled={isWebSearchOn} onToggle={(e) => setIsWebSearchOn(e)} />
                                </ToggleContainer>
                            </ModalTool>
                            <ModalTool
                                theme={theme}
                                onClick={() => setIsHumanizerOn(!isHumanizerOn)}
                                isOn={isHumanizerOn}
                            >
                                <ModalToolLeftContainer>
                                    <ModalToolHumanizerIcon size={35} theme={theme} />
                                    <ModalToolTextContainer>
                                        <ModalRowText>Humanizer</ModalRowText>
                                        <ModalRowSubText>Sound more human</ModalRowSubText>
                                    </ModalToolTextContainer>
                                </ModalToolLeftContainer>
                                <ToggleContainer onClick={(e) => e.preventDefault()}>
                                    <CustomToggle isToggled={isHumanizerOn} onToggle={(e) => setIsHumanizerOn(e)} />
                                </ToggleContainer>
                            </ModalTool>
                        </ModalToolsContainer>
                    </ModalColumn>
                </ModalContainer>
            )}
        </OuterContainer>
    );
}

export default ToolsSelectorModal;