import React, { useEffect, useState } from 'react';
import './assets/fonts/fonts.css';
import FontFaceObserver from 'fontfaceobserver';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { ConversationProvider } from './contexts/ConversationContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { WebSocketProvider } from "./contexts/WebSocketContext";
import PrivateRoute from './screens/PrivateRoute';
import HomeScreen from './screens/HomeScreen';
import YouScreen from './screens/YouScreen';
import FilterScreen from './screens/FilterScreen';
import WelcomeScreen from "./screens/WelcomeScreen";
import LoaderScreenNoTheme from "./screens/FullLoaderScreenNoTheme";
import SupportScreen from "./screens/SupportScreen";
import TermsConditionsScreen from "./screens/TermsConditionsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import AccountScreen from "./screens/AccountScreen";

const App = () => {
    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        const loadFonts = async () => {
            const fontObserver = new FontFaceObserver('Poppins');
            try {
                await fontObserver.load();
                setFontsLoaded(true);
            } catch (error) {
                console.error('Font loading error:', error);
            }
        };

        loadFonts();
    }, []);

    if (!fontsLoaded) {
        return <LoaderScreenNoTheme />;
    }

    return (
        <Router>
            <UserProvider>
                <ConversationProvider>
                    <ThemeProvider>
                        <Routes>
                            <Route path="/welcome" element={<WelcomeScreen />} />
                            <Route path="/" element={<PrivateRoute><HomeScreen /></PrivateRoute>} />
                            <Route path="/c/:conversationId" element={<PrivateRoute><HomeScreen /></PrivateRoute>} />
                            <Route path="/profiles" element={<PrivateRoute><YouScreen /></PrivateRoute>} />
                            <Route path="/account" element={<PrivateRoute><AccountScreen /></PrivateRoute>} />
                            {/*<Route path="/toggles" element={<PrivateRoute><FilterScreen /></PrivateRoute>} />*/}
                            <Route path="/support" element={<PrivateRoute><SupportScreen /></PrivateRoute>} />
                            <Route path="/terms" element={<TermsConditionsScreen />} />
                            <Route path="/privacy" element={<PrivacyPolicyScreen />} />
                        </Routes>
                    </ThemeProvider>
                </ConversationProvider>
            </UserProvider>
        </Router>
    );
};

export default App;
