import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "./CustomText";
import BouncingCircles from "./BouncingLoader";
import FileIcon from "../molecules/FileIcon";
import { useLoading } from "../../contexts/LoadingContext";
import {PiArrowClockwiseBold, PiClipboardBold, PiPencilDuotone, PiThumbsUpBold, PiXBold, PiInfoBold} from "react-icons/pi";
import { FaChevronDown } from "react-icons/fa6";
import TextareaAutosize from "react-textarea-autosize";
import ContextItems from "../molecules/ContextItems";
import CustomButton from "./CustomButton";
import MessageButton from "./MessageButton";
import { WebSearchInfo, WebSearchInfoHeader } from "./WebSearchMessage";

const MessageContainer = styled.div.attrs(props => ({
    'data-isuser': props.isUser
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.isUser ? '30px 20px' : '20px 20px'};
  z-index: 1;
  gap: 12px;
  background-color: ${props => props.isUser ? 'transparent' : props.theme.colors.sidebarBackground};
`;

const OuterMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;
  min-width: 60%;
  align-self: center;
  gap: 10px;
  position: relative;
   
  @media (max-width: 1750px) {
    max-width: 65%;
    min-width: 65%;
  }
  
  @media (max-width: 1400px) {
    max-width: 70%;
    min-width: 70%;
  }
  
  @media (max-width: 1100px) {
    max-width: 75%;
    min-width: 75%;
  }
  
  @media (max-width: 1000px) {
    max-width: 80%;
    min-width: 80%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ButtonsContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 65%;
  min-width: 65%;
  gap: 2px;
  margin-top: 2px;

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const IconArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

const InfoIconPopup = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    bottom: ${props => props.contextUsed ? '-60px;' : '-50px'};
    left: 10px;
    transform: translateX(-50%);
    background-color: ${props => props.theme.colors.background};
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    white-space: nowrap;
`;

const InfoIcon = styled(PiInfoBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const ClipboardIcon = styled(PiClipboardBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const RetryIcon = styled(PiArrowClockwiseBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
`;

const ThumbsUpIcon = styled(PiThumbsUpBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const ThumbsDownIcon = styled(ThumbsUpIcon)`
    transform: rotate(180deg);
`;

const StyledMessage = styled(CustomText)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  text-align: left;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  align-self: center;
  word-wrap: break-word;
  width: 100%;
`;

const Input = styled(TextareaAutosize)`
    border: none;
    border-radius: 10px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.text};
    font-family: 'Poppins', serif;
    font-size: 1.1em;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    line-height: 1.5;
    word-wrap: break-word;
    align-self: center;
    text-align: left;
    outline: none;
`;

const SendButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};;
  border: 1px solid ${(props) => props.theme.colors.strokeFade};
  font-family: 'PoppinsMedium', serif;
  font-size: 0.9em;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.2);
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primaryLight};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transform: scale(0.97);
  }
`;

const CancelButton = styled.button`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};;
  border: 1px solid ${(props) => props.theme.colors.strokeFade};
  font-family: 'PoppinsMedium', serif;
  font-size: 0.9em;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    background-color: ${(props) => props.theme.colors.sidebarBackground};
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.2);
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primaryLight};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transform: scale(0.97);
  }
`;

const CancelIcon = styled(PiXBold)`
  color: ${(props) => props.theme.colors.text};
  font-size: 1.5rem;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  
  &:active {
    transform: scale(0.95);
  }
`;

const EditIcon = styled(PiPencilDuotone)`
  color: ${(props) => props.theme.colors.text};
  font-size: 1.2rem;
  align-self: flex-end;
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  
  // ${OuterMessageContainer}:hover & {
  //   display: block;
  // }
`;

const FilesContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 17%;
  margin-bottom: 5px;
  
  @media (max-width: 768px) {
    margin-left: 3%;
    margin-bottom: 0;
  }
`;

const Message = ({  message, last, messageId, onEditSubmit, onFeedback, onRetry, error }) => {
    const { theme } = useTheme();
    const { loading, streaming, loadingState } = useLoading();
    const [isEditing, setIsEditing] = useState(false);
    const isUser = message.type === 'user' || message.isUser;
    const text = message.text;
    const files = message.files;
    const numberOfContexts = message.contextUsed ? message.contextUsed.length : 0;
    const [editedText, setEditedText] = useState(text);
    const [showInfoExpanded, setShowInfoExpanded] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [copyText, setCopyText] = useState("Copy");

    const handleInfoClick = () => {
        if (numberOfContexts > 0) {
            setShowInfoExpanded(!showInfoExpanded);
        }
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    }

    const handleEdit = () => setIsEditing(!isEditing);

    const handleSendEdit = async () => {
        if (editedText !== text) {
            onEditSubmit(messageId, editedText);
        }
        setIsEditing(false);
    };

    return (
        <MessageContainer theme={theme} isUser={isUser}>
            {isUser && files && files.length > 0 &&
                <FilesContainer>
                    {Array.from(files).map((file, index) => (
                        <FileIcon
                            key={index}
                            fileName={file}
                        />
                    ))}
                </FilesContainer>
            }
            {isEditing ? (
                <OuterMessageContainer>
                    <Input
                        type="text"
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        theme={theme}
                    />
                    <ButtonsContainer>
                        <SendButton onClick={() => handleSendEdit(editedText)} theme={theme}>{window.innerWidth > 800 ? "Save & Submit" : "Send"}</SendButton>
                        <CancelButton onClick={handleEdit} theme={theme}>Cancel</CancelButton>
                        {/*<CancelIcon onClick={handleEdit} theme={theme} />*/}
                    </ButtonsContainer>
                </OuterMessageContainer>
            ) : (
                <OuterMessageContainer>
                    {!isUser && last && loading && !streaming && <BouncingCircles text={loadingState}/>}
                    {message.webSearch && message.webSearch.organic_results && <WebSearchInfoHeader webSearch={message.webSearch} />}
                    <StyledMessage theme={theme}>
                        {text}
                    </StyledMessage>
                    {message.webSearch && message.webSearch.organic_results && <WebSearchInfo webSearch={message.webSearch} />}
                    {showInfoExpanded && <ContextItems contexts={message.contextUsed} />}
                    {!isUser && !error && (!last || (last && !streaming)) &&
                        <ButtonsContainerBottom>
                            <IconArea
                                onMouseEnter={() => setShowInfoPopup(true)}
                                onMouseLeave={() => setShowInfoPopup(false)}
                                onClick={handleInfoClick}
                            >
                                <InfoIconPopup hidden={!showInfoPopup} theme={theme} contextUsed={numberOfContexts > 0}>
                                    <CustomText theme={theme} type="small">{numberOfContexts} context{numberOfContexts > 1 ? "s" : ""} used</CustomText>
                                    {numberOfContexts > 0 && <CustomText theme={theme} type="small" style={{fontSize: "0.8rem"}}>Click to view</CustomText>}
                                </InfoIconPopup>
                                <InfoIcon theme={theme} size={20} />
                            </IconArea>
                            <MessageButton
                                onClick={handleCopyClick}
                                icon={<ClipboardIcon theme={theme} size={20} />}
                                infoText={copyText}
                            />
                            <MessageButton
                                onClick={() => onFeedback("good")}
                                icon={<ThumbsUpIcon theme={theme} size={20} />}
                                infoText="Good response"
                            />
                            <MessageButton
                                onClick={() => onFeedback("bad")}
                                icon={<ThumbsDownIcon theme={theme} size={20} />}
                                infoText="Bad response"
                            />
                            {last &&
                                <MessageButton
                                    onClick={onRetry}
                                    icon={<RetryIcon theme={theme} size={20} />}
                                    infoText="Regenerate"
                                />
                            }
                        </ButtonsContainerBottom>
                    }
                    {isUser && last && loading && <BouncingCircles text={loadingState}/>}
                </OuterMessageContainer>
            )}
            {/*{isUser && last && loading && <BouncingCircles text={loadingState}/>}*/}
        </MessageContainer>
    );
};

export default Message;