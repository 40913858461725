import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [streaming, setStreaming] = useState(false);
    const [loadingState, setLoadingState] = useState('');

    return (
        <LoadingContext.Provider value={{ loading, setLoading, streaming, setStreaming, loadingState, setLoadingState }}>
            {children}
        </LoadingContext.Provider>
    );
};