import React from 'react';
import styled from 'styled-components';
import { PiFilePlus } from "react-icons/pi";
import CustomText from "../atoms/CustomText";
import {useTheme} from "../../contexts/ThemeContext";

const DragOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${({ theme }) => theme.name === 'light' ? 0.6 : 0.8});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 10;
  p {
    color: white;
    font-size: 1.5em;
  }
`;

const FileUploadIcon = styled(PiFilePlus)`
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5em;
    margin: 0 5px;
    cursor: pointer;
`;

const FileUploadText = styled(CustomText)`
    font-size: 1.5em;
    margin: 0 5px;
    cursor: pointer;
`;

const FileUploadTextSmall = styled(CustomText)`
  font-size: 1em;
  cursor: pointer;
  margin-top: -10px;
`;

const FileUploadOverlay = () => {
    const { theme } = useTheme();
    return (
        <DragOverlay theme={theme}>
            <FileUploadIcon theme={theme} size={60} />
            <FileUploadText theme={theme} type="semiBold" style={{ color: theme.colors.white }}>
                Drop files here to upload
            </FileUploadText>
            <FileUploadTextSmall theme={theme} type="regular" style={{ color: theme.colors.white }}>
                (pdf, doc, py, js, txt, etc.)
            </FileUploadTextSmall>
        </DragOverlay>
    );
}

export default FileUploadOverlay;
