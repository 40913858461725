import React from 'react';
import styled from 'styled-components';
import { useUser } from "../../contexts/UserContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useConversation } from "../../contexts/ConversationContext";
import CustomText from "../atoms/CustomText";
import { cleanNodeType } from "../../utils/utils";
import { deleteNode } from "../../api/api";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 55%;
  min-width: 35%;
  background: ${props => props.theme.colors.sidebarBackground};
  padding: 20px;
  border-radius: 10px;
  z-index: 1001;
  margin-top: 150px;
  position: relative;
  
  @media (max-width: 768px) {
    max-width: 85%;
    min-width: 85%;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  color: ${props => props.theme.colors.text};
  position: absolute;
  top: 10px;
  right: 10px;
`;

const TypeText = styled(CustomText)`
  font-size: 1.1rem;
  margin-bottom: 20px;
  align-self: flex-start;
  background-color: ${props => props.theme.colors.stroke};
  padding: 3px 8px;
  border-radius: 15px;
`;

const ContentText = styled(CustomText)`
  font-size: 1.1rem;
  margin-bottom: 40px;
`;

const ConvoNameText = styled(CustomText)`
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-size: 1.1rem;
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const DeleteButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.red};
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.theme.colors.redFade};
    color: ${props => props.theme.colors.background};
  }
`;

const ConvoNameTextSmall = styled(CustomText)`
  font-size: 1rem;
  opacity: 0.6;
`;

const ProfileModal = ({ profileData, onClose, color }) => {
    const { user } = useUser();
    const { theme } = useTheme();
    const { conversations } = useConversation();

    const capitalizedType = cleanNodeType(profileData.type);
    const type = capitalizedType.endsWith('s') ? capitalizedType.slice(0, -1) : capitalizedType;
    const convo = profileData.conversationId ? conversations.find(convo => convo.id === profileData.conversationId) : null;
    const convoName = convo ? convo.name : 'Untitled';

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
            if (!profileData.id) {
                return;
            }
            await deleteNode(user.uid, user.selectedBackendProfileId, profileData.id);
        }
    }

    if (!profileData) return null;

    return (
        <ModalBackdrop onClick={onClose}>
            <ModalContainer onClick={e => e.stopPropagation()} theme={theme}>
                <CloseButton onClick={onClose} theme={theme}>&times;</CloseButton>
                <TypeText theme={theme} type="medium" style={{ background: color }}>
                    {type}
                </TypeText>
                <ContentText>{profileData.content}</ContentText>
                <DeleteButton theme={theme} onClick={handleDelete}>
                    <CustomText type="medium" style={{ color: theme.colors.red }}>Delete</CustomText>
                </DeleteButton>
                <ConvoNameText>
                    <ConvoNameTextSmall>
                        Added from:
                    </ConvoNameTextSmall>
                    {convoName}
                </ConvoNameText>
            </ModalContainer>
        </ModalBackdrop>
    );
};

export default ProfileModal;
