import React  from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import BouncingCircles from "../atoms/BouncingLoader";
import {useLoading} from "../../contexts/LoadingContext";
import {PiArrowClockwiseBold, PiClipboardBold, PiThumbsUpBold} from "react-icons/pi";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 1;
  gap: 12px;
  background-color: ${props => props.theme.colors.sidebarBackground};
`;

const StyledMessage = styled(CustomText)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  text-align: left;
  margin: 5px 0;
  border-radius: 10px;
  max-width: 65%;
  min-width: 65%;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  align-self: center;
  word-wrap: break-word;
  
  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const ButtonsContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 65%;
  min-width: 65%;
  gap: 2px;

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const ClipboardIcon = styled(PiClipboardBold)`
    font-size: 1.1rem;
    color: ${props => props.theme.colors.text};
    margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const RetryIcon = styled(PiArrowClockwiseBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
`;

const ThumbsUpIcon = styled(PiThumbsUpBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const ThumbsDownIcon = styled(ThumbsUpIcon)`
    transform: rotate(180deg);
`;

const HumanizeMessage = ({ text, onFeedback, onRetry, last, error }) => {
    const { theme } = useTheme();
    const { loading, loadingState } = useLoading();

    return (
        <MessageContainer theme={theme}>
            <>
                <StyledMessage theme={theme}>
                    {text}
                </StyledMessage>
                {!error &&
                    <ButtonsContainerBottom>
                        <ClipboardIcon theme={theme} size={20} onClick={() => navigator.clipboard.writeText(text)} />
                        <ThumbsUpIcon theme={theme} size={20} onClick={() => onFeedback("good")} />
                        <ThumbsDownIcon theme={theme} size={20} onClick={() => onFeedback("bad")} />
                        <RetryIcon theme={theme} onClick={onRetry} size={20} />
                    </ButtonsContainerBottom>
                }
            </>
            {last && loading && <BouncingCircles text={loadingState}/>}
        </MessageContainer>
    );
};

export default HumanizeMessage;