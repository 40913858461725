import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useConversation } from "../contexts/ConversationContext";
import Sidebar from '../components/organisms/Sidebar';
import ChatWindow from "../components/organisms/ChatWindow";
import CustomSelector from "../components/molecules/CustomSelector";
import FeedbackModal from "../components/organisms/FeedbackModal";
import { PiQuestionDuotone } from "react-icons/pi";
import HelpModal from "../components/organisms/HelpModal";
import {LoadingProvider} from "../contexts/LoadingContext";
import ToolsSelectorModal from "../components/molecules/ToolsSelectorModal";
import ProfileSelector from "../components/organisms/ProfileSelector";
import SubscribeModal from "../components/organisms/SubscribeModal";
import {CONVO_STARTER_SUGGESTIONS} from "../utils/constants";
import {WebSocketProvider} from "../contexts/WebSocketContext";
import {useUser} from "../contexts/UserContext";
import {updateLastUsedContextWindow} from "../services/firebaseFirestore";

const HomeScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height:  100vh;
  
  @media (max-width: 800px) {
    height: auto;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  @media (max-width: 800px) {
    height: auto;
  }
`;

const TopRightButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //gap: 10px;
`;

const HelpButtonContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  
  @media (max-width: 800px) {
    right: 5px;
    bottom: 5px;
    display: ${(props) => props.isExpanded ? 'none' : 'flex'};
  }
`;

const HelpButton = styled(PiQuestionDuotone)`
  color: ${(props) => props.theme.name === "dark" ? props.theme.colors.stroke : props.theme.colors.border};
  opacity: 0.5;
  cursor: pointer;
`;

const HomeScreen = () => {
    const { theme } = useTheme();
    const { user } = useUser();
    const { conversations, selectConversation, createNewConversation, chooseConversation, currentConversation, findOrCreateBlankConversation } = useConversation();
    const { conversationId } = useParams();
    const navigate = useNavigate();

    const [isPersonal, setIsPersonal] = useState(true);
    const [essayWriterOn, setEssayWriterOn] = useState(false);
    const [humanizerOn, setHumanizerOn] = useState(false);
    const [webSearchOn, setWebSearchOn] = useState(true);
    const [imageGenOn, setImageGenOn] = useState(true);
    const [contextWindow, setContextWindow] = useState(user.contextWindow);

    const [isExpanded, setIsExpanded] = useState(window.innerWidth > 1200);

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showSubscribeModal, setSubscribeModalOpen] = useState(false);
    const [tokenDifference, setTokenDifference] = useState(0);
    const [goodBad, setGoodBad] = useState("");

    const [suggestions, setSuggestions] = useState(Object.fromEntries(Object.entries(CONVO_STARTER_SUGGESTIONS).sort(() => Math.random() - Math.random()).slice(0, 4)));

    useEffect(() => {
        updateLastUsedContextWindow(user.uid, contextWindow);
    }, [contextWindow, user.uid]);

    const handleSelectConversation = (id) => {
        selectConversation(id);
    };

    const handleNewConversation = () => {
        // if there is any existing conversation with no messages, it should switch to that one rather than creating a new one
        const emptyConversation = conversations.find((conversation) => conversation.messages.length === 0);
        if (emptyConversation) {
            selectConversation(emptyConversation.id);
            return;
        }
        createNewConversation();
    };

    useEffect(() => {
        const initiateConversationFlow = async () => {
            try {
                if (conversationId && conversationId !== 'undefined') {
                    if (!currentConversation || currentConversation.id !== conversationId) {
                        chooseConversation(conversationId);
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    const blankConversationId = await findOrCreateBlankConversation();
                    navigate(`/c/${blankConversationId}`, {replace: true});
                }
            } catch (e) {
                const blankConversationId = await findOrCreateBlankConversation();
                navigate(`/c/${blankConversationId}`, {replace: true});
            }
        };

        initiateConversationFlow();
    }, [conversationId, conversations, currentConversation, navigate, chooseConversation, findOrCreateBlankConversation]);

    useEffect(() => {
        const handleResize = () => {
            const shouldBeExpanded = window.innerWidth > 1200;
            setIsExpanded(shouldBeExpanded);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <LoadingProvider>
            <WebSocketProvider>
                <HomeScreenContainer theme={theme}>
                    <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} setFeedbackModelOpen={setShowFeedbackModal} />
                    <CenteredContainer>
                        <ProfileSelector setSubscribeModalOpen={setSubscribeModalOpen} />
                        <TopRightButtonContainer>
                            <ToolsSelectorModal
                                isPersonal={isPersonal}
                                setIsPersonal={setIsPersonal}
                                isEssayWriterOn={essayWriterOn}
                                setIsEssayWriterOn={setEssayWriterOn}
                                isHumanizerOn={humanizerOn}
                                setIsHumanizerOn={setHumanizerOn}
                                isWebSearchOn={webSearchOn}
                                setIsWebSearchOn={setWebSearchOn}
                                isImageGenOn={imageGenOn}
                                setIsImageGenOn={setImageGenOn}
                                contextWindow={contextWindow}
                                setContextWindow={setContextWindow}
                            />
                            <CustomSelector
                                placeholder="Select a conversation"
                                items={conversations}
                                onSelect={handleSelectConversation}
                                onNewItem={handleNewConversation}
                                currentItemId={currentConversation?.id}
                                createDelete
                                allowEdit
                            />
                        </TopRightButtonContainer>
                        <ChatWindow
                            suggestions={suggestions}
                            setTokenDifference={setTokenDifference}
                            setSubscribeModalOpen={setSubscribeModalOpen}
                            setShowFeedbackModal={setShowFeedbackModal}
                            setGoodBad={setGoodBad}
                            isPersonal={isPersonal}
                            essayWriter={essayWriterOn}
                            humanizer={humanizerOn}
                            webSearchOn={webSearchOn}
                            imageGenOn={imageGenOn}
                            contextWindow={contextWindow}
                        />
                        <HelpButtonContainer isExpanded={isExpanded}>
                            <HelpButton size={window.innerWidth <= 800 ? 20 : 25} onClick={() => setShowHelpModal(true)} theme={theme} />
                        </HelpButtonContainer>
                    </CenteredContainer>
                    <FeedbackModal open={showFeedbackModal} handleClose={() => setShowFeedbackModal(false)} currentConversation={currentConversation?.id} goodBad={goodBad} />
                    <HelpModal open={showHelpModal} handleClose={() => setShowHelpModal(false)} />
                    <SubscribeModal open={showSubscribeModal} handleClose={() => setSubscribeModalOpen(false)} missingTokens={tokenDifference} />
                </HomeScreenContainer>
            </WebSocketProvider>
        </LoadingProvider>
    );
};

export default HomeScreen;