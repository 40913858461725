import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import { FAQS } from "../../utils/constants";
import FAQ from "../molecules/FAQ";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: 15px 15px;
  border-radius: 20px;
  margin-top: -50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  z-index: 100;
  position: relative;
  width: 700px;
  max-height: 85vh;
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.strokeFaded};
    border-radius: 10px;
  }
  
  @media (max-width: 800px) {
    width: 85%;
    margin-top: 0;
    padding: 15px 10px;
  }
`;

const ContactUs = styled.a`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  font-size: 1em;
  text-decoration: none;
  margin-top: 5px;
  margin-left: 5px;
`;

const HelpModal = ({ open, handleClose }) => {
    const { theme } = useTheme();
    const modalRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClose]);

    if (!open) {
        return null;
    }

    return (
        <ModalBackdrop>
            <ModalContainer ref={modalRef} theme={theme}>
                {FAQS.map((faq, index) => (
                    <FAQ key={index} question={faq.question} answer={faq.answer} />
                ))}
                <ContactUs href="mailto:adeptcontacthelp@gmail.com" theme={theme}>
                    Contact us!
                </ContactUs>
            </ModalContainer>
        </ModalBackdrop>
    );
};

export default HelpModal;
