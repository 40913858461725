import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { useTheme } from "../../contexts/ThemeContext";
import { useUser } from "../../contexts/UserContext";
import CustomText from '../atoms/CustomText';
import { PiInfoBold } from 'react-icons/pi';

const Card = styled(motion.div)`
  position: fixed;
  top: 10px;
  padding: 12px 50px;
  gap: 2px;
  background: ${(props) => props.theme.colors.sidebarBackground};
  border: .5px solid ${(props) => props.theme.colors.stroke};
  border-radius: 13px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 9000;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusCard = () => {
    const { theme } = useTheme();
    const { socket } = useWebSocket();
    const [status, setStatus] = useState('Connecting...');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!socket) return; // Check if the socket exists

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "ProfileUpdated") {
                setStatus(data.message);
                setIsVisible(true);
                setTimeout(() => {
                    setIsVisible(false);
                }, 3000);
            }
        };
    }, [socket]);

    return (
        <Card
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : -50 }}
            transition={{ duration: 0.5 }}
            theme={theme}
        >
            <PiInfoBold size={20} color={theme.colors.text} />
            <CustomText theme={theme} style={{ marginLeft: '5px' }} type="medium">{status}</CustomText>
        </Card>
    );
};

export default StatusCard;