import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";

const ProfileNodeOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background-color: ${props => props.theme.colors.sidebarBackground};
  border-radius: 25px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-5px);
  }
  
  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const ProfileNodeInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 100%;
`;

const ProfileNodeText = styled(CustomText)`
  font-size: 1rem;
  text-align: center;
  word-wrap: break-word;
  width: 95%;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ProfileNode = ({ index, content, conversationID, embedding, type , id, onClick, color }) => {
    const { theme } = useTheme();

    const capitalizedContent = content.charAt(0).toUpperCase() + content.slice(1);
    const shortenedContent = capitalizedContent.length > 45 ? capitalizedContent.substring(0, 45) + "..." : capitalizedContent;

    const handleOnClick = () => {
        onClick({ content, conversationID, embedding, type, id });
    }

    return (
        <ProfileNodeOuter theme={theme} onClick={handleOnClick} style={{ backgroundColor: color }} key={index}>
            <ProfileNodeInner theme={theme}>
                <ProfileNodeText type="medium">
                    {shortenedContent}
                </ProfileNodeText>
            </ProfileNodeInner>
        </ProfileNodeOuter>
    );
}

export default ProfileNode;
