import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Chart, RadarController, PointElement, LineElement, RadialLinearScale, LinearScale, Filler } from 'chart.js';
import { useTheme } from '../../contexts/ThemeContext';

const ChartContainer = styled.div`
  width: 110%;
  height: 100%;
`;

Chart.register(RadarController, PointElement, LineElement, RadialLinearScale, LinearScale, Filler);

const RadarChart = ({ title, scores, labelSize, tickBackground }) => {
    const { theme } = useTheme();

    const capitalizedLabels = Object.keys(scores).map((label) => {
        return label.charAt(0).toUpperCase() + label.slice(1);
    });
    const fontColors = [
        theme.colors.green,
        theme.colors.blue,
        theme.colors.red,
        theme.colors.darkBlue,
        theme.colors.purple,
        theme.colors.orange,
        theme.colors.primary,
    ]
    const chartRef = useRef();
    const data = {
        labels: capitalizedLabels,
        datasets: [{
            label: title,
            data: Object.values(scores),
            backgroundColor: theme.colors.primaryFade,
            borderColor: theme.colors.primary,
            pointBackgroundColor: theme.colors.primary,
            pointBorderColor: theme.colors.strokeFade,
            pointHoverBackgroundColor: theme.colors.strokeFade,
            pointHoverBorderColor: theme.colors.primary
        }]
    };

    useEffect(() => {
        const config = {
            type: 'radar',
            data: data,
            options: {
                responsive: true,
                scales: {
                    r: {
                        ticks: {
                            backdropColor: tickBackground
                        },
                        anglelines: {
                            color: theme.colors.strokeFade,
                        },
                        grid: {
                            color: theme.colors.strokeFade,
                        },
                        pointLabels: {
                            font: {
                                size: labelSize,
                                family: 'PoppinsMedium',
                            },
                            color: fontColors,
                        }
                    },
                },
                elements: {
                    line: {
                        borderWidth: 1.5
                    }
                },
            },
        };
        const chart = new Chart(chartRef.current, config);
        return () => chart.destroy();
    }, []);

    return (
        <ChartContainer>
            <canvas ref={chartRef} />
        </ChartContainer>
    );
};

export default RadarChart;
