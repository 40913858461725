import {useTheme} from "../../contexts/ThemeContext";
import React, {useEffect, useState} from "react";
import CustomText from "./CustomText";
import styled from "styled-components";

const IconArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

const InfoIconPopup = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    bottom: ${props => props.contextUsed ? '-60px;' : '-50px'};
    left: 10px;
    transform: translateX(-50%);
    background-color: ${props => props.theme.colors.background};
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    white-space: nowrap;
`;


const MessageButton = ({ icon, onClick, infoText }) => {
    const { theme } = useTheme();
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    let hoverTimeout = null; // This will hold our timeout

    const handleMouseEnter = () => {
        // Clear any existing timeout to prevent multiple timers running
        clearTimeout(hoverTimeout);

        // Set a new timeout to show the popup after a delay
        hoverTimeout = setTimeout(() => {
            setShowInfoPopup(true);
        }, 500); // 2000 milliseconds delay
    };

    const handleMouseLeave = () => {
        // Clear the timeout when the mouse leaves
        clearTimeout(hoverTimeout);
        setShowInfoPopup(false);
    };

    // Clean up the timeout when the component unmounts to prevent memory leaks
    useEffect(() => {
        return () => clearTimeout(hoverTimeout);
    }, []);

    return (
        <IconArea
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            <InfoIconPopup hidden={!showInfoPopup} theme={theme}>
                <CustomText theme={theme} type="small">{infoText}</CustomText>
            </InfoIconPopup>
            {icon}
        </IconArea>
    );
}

export default MessageButton;