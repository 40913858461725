import { addUserTokens, createOrUpdateConversation } from "../services/firebaseFirestore";
import { checkTokenLimit, convertEssayConstructionToString, convertSentimentAnalysisToString } from "./utils";
import { sendMessage } from "../api/api";
import { handleBase64Response } from "../services/firebaseStorage";

export const handleSend = async (
    text,
    user,
    scrollToBottom,
    setError,
    setLoading,
    setLoadingState,
    setStreaming,
    selectedFiles,
    conversationFiles,
    setSelectedFiles,
    setMessages,
    messages,
    messagesRef,
    setUser,
    currentConversation,
    setConversations,
    contextWindow,
    isPersonal,
    essayWriter,
    humanizer,
    webSearchOn,
    imageGenOn,
    filesUrgent,
    setFilesUrgent,
    setTokenDifference,
    setSubscribeModalOpen,
) => {
    if (!user) {
        return;
    }
    if (!text || text.trim() === '') {
        return;
    }
    if (user.backendProfiles.length === 0) {
        alert('You must create a profile before you can send a message.');
        return;
    }
    if (!user.selectedBackendProfileId) {
        alert('You must select a profile before you can send a message.');
        return;
    }

    scrollToBottom();

    setError(null);
    setLoading(true);
    setLoadingState('');

    const fileNames = Array.from(selectedFiles).map(file => file.name);
    const newMessage = { text, files: fileNames, type: "user" };
    const newMessages = [...messages, newMessage];
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setSelectedFiles([]);

    // extract text only and convert any chart messages to text
    const textOnlyMessages = newMessages.map(message => {
        if (message.isChart || message.type === "chart") {
            const scores = {...message.text};
            delete scores.query;
            return convertSentimentAnalysisToString(scores);
        } else if (message.type === "humanize") {
            return message.text.text;
        } else if (message.type === "essay") {
            return convertEssayConstructionToString(message.text);
        } else if (message.type === "image") {
            return message.revisedPrompt;
        } else {
            return message.text;
        }
    });

    const isClass = user.backendProfiles.find(profile => profile.id === user.selectedBackendProfileId)?.isClass || false;

    const settings = {
        model: 'gpt-4-turbo-preview',
        contextWindow: contextWindow,
        isPersonal: isPersonal,
        isClass: isClass,
        urgentFiles: filesUrgent,
        toolSettings: {
            humanizer: humanizer,
            webSearch: webSearchOn,
            imageGen: imageGenOn,
        }
    }
    setFilesUrgent(false);

    const messageData = {
        settings: settings,
        userID: user.uid,
        profileID: user.selectedBackendProfileId,
        conversationID: currentConversation?.id || '',
        messages: textOnlyMessages,
    };

    try {
        const { canSend, difference } = await checkTokenLimit(user.tokensUsed, user.tokenLimit);

        if (!canSend) {
            setTokenDifference(difference);
            setSubscribeModalOpen(true);
            setLoading(false);
            return;
        }

        const sendResponse = await sendMessage(messageData, Array.from(conversationFiles));
        await handleResponse(
            sendResponse,
            newMessage,
            setUser,
            user,
            setMessages,
            messages,
            messagesRef,
            currentConversation,
            setConversations,
            setStreaming,
        );
    } catch (error) {
        // console.error('Error sending message:', error);
        setError(error);
    }

    setLoading(false);
};

const handleResponse = async (
    sendResponse,
    newMessage,
    setUser,
    user,
    setMessages,
    messages,
    messagesRef,
    currentConversation,
    setConversations,
    setStreaming
) => {
    if (!sendResponse.success) {
        return;
    }

    if (sendResponse.type === "text") {
        setStreaming(false);
        await handleStreamEnd(user, messagesRef, sendResponse.contextUsed, sendResponse.webSearch, currentConversation, setConversations, setMessages);

        const reducedTokens = Math.round(sendResponse.tokensUsed / 50);
        setUser((prevUser) => {
            return {
                ...prevUser,
                tokensUsed: prevUser.tokensUsed + reducedTokens,
            }
        });
        await addUserTokens(user.uid, reducedTokens);

        return;
    }

    const receivedMessage = {
        text: sendResponse.response,
        type: sendResponse.type === "humanize" ? "humanize"
            : sendResponse.type === "image" ? "image"
                : "ai", // sendResponse.type === "sentiment" ? "chart" // sendResponse.type === "essay" ? "essay"
        revisedPrompt: sendResponse.type === "image" ? sendResponse.revisedPrompt : null,
        contextUsed: sendResponse.contextUsed,
        webSearch: sendResponse.webSearch || null,
    };

    if (sendResponse.type === "image") {
        receivedMessage.text = await handleBase64Response(sendResponse.response);
    }

    const updatedMessages = [...messages, newMessage, receivedMessage];

    const name = updatedMessages[0].text.substring(0, 20);
    const newConversation = {
        ...currentConversation,
        lastUpdatedAt: new Date(),
        messages: updatedMessages,
        name: name,
    }

    setMessages(updatedMessages);
    setConversations((prevConversations) => {
        const conversationIndex = prevConversations.findIndex((conversation) => conversation.id === currentConversation.id);
        const updatedConversations = [...prevConversations];
        updatedConversations[conversationIndex] = newConversation;
        return updatedConversations;
    });
    await createOrUpdateConversation(user.uid, newConversation);

    const reducedTokens = Math.round(sendResponse.tokensUsed / 50);
    setUser((prevUser) => {
        return {
            ...prevUser,
            tokensUsed: prevUser.tokensUsed + reducedTokens,
        }
    });
    await addUserTokens(user.uid, reducedTokens);
};

export const handleMsg = async (
    msg,
    setLoadingState,
    receivedTextPartIDs,
    setReceivedTextPartIDs,
    setMessages,
    setLoading,
) => {
    if (!msg) {
        return;
    }
    try {
        if (msg.type === "text_part") {
            setLoading(false);
            handleStreamResponse(msg, receivedTextPartIDs, setReceivedTextPartIDs, setMessages);
        } else if (msg.type === "FunctionHandled") {
            setLoadingState(msg.message);
        }
    } catch (error) {
        console.error("Error parsing WebSocket message:", error);
    }
}

const handleStreamResponse = (
    textPart,
    receivedTextPartIDs,
    setReceivedTextPartIDs,
    setMessages,
) => {
    const { id, message } = textPart;

    if (receivedTextPartIDs.includes(id)) {
        return;
    }

    setReceivedTextPartIDs(prevIDs => [...prevIDs, id]);

    setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        const isLastMessageAI = newMessages.length > 0 && newMessages[newMessages.length - 1].type === "ai";
        if (isLastMessageAI) {
            const lastMessage = newMessages.pop();
            if (lastMessage.type === "ai") {
                lastMessage.text += message;
            }
            newMessages.push(lastMessage);
        } else {
            newMessages.push({ text: message, type: "ai" });
        }
        return newMessages;
    });
};

const handleStreamEnd = async (
    user,
    messagesRef,
    contextUsed,
    webSearch,
    currentConversation,
    setConversations,
    setMessages,
) => {
    const messagesCurrent = messagesRef.current;

    // Add contextUsed and webSearch to the last AI message
    if (messagesCurrent.length > 0 && messagesCurrent[messagesCurrent.length - 1].type === "ai") {
        messagesCurrent[messagesCurrent.length - 1].contextUsed = contextUsed;
        messagesCurrent[messagesCurrent.length - 1].webSearch = webSearch;
    }

    if (currentConversation === null) {
        console.error('No current conversation');
        return;
    }

    const name = messagesCurrent[0]?.text.substring(0, 20) || 'New Conversation'; // Added a fallback for name
    const newConversation = {
        ...currentConversation,
        lastUpdatedAt: new Date(),
        messages: messagesCurrent,
        name,
    };

    setConversations((prevConversations) => {
        const conversationIndex = prevConversations.findIndex((conversation) => conversation.id === currentConversation.id);
        const updatedConversations = [...prevConversations];
        updatedConversations[conversationIndex] = newConversation;
        return updatedConversations;
    });
    setMessages(messagesCurrent);

    await createOrUpdateConversation(user.uid, newConversation);
}