import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import CustomSelector from "../molecules/CustomSelector";
import CustomText from "../atoms/CustomText";
import CustomButton from "../atoms/CustomButton";
import CustomInput from "../atoms/CustomInput";
import { useTheme } from "../../contexts/ThemeContext";
import { useConversation } from "../../contexts/ConversationContext";
import { sendFeedback } from "../../api/api";
import {useUser} from "../../contexts/UserContext";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: 20px 28px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -100px;
  z-index: 100;
  position: relative;
  width: 500px;

  @media (max-width: 800px) {
    width: 80%;
    margin-top: 0;
  }
`;

const ModalHeader = styled(CustomText)`
  font-size: 1.3em;
  margin-left: -5px;
  margin-bottom: 10px;
  opacity: 0.9;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0 20px -5px;
`;

const ModalButtonsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: -5px;
  gap: 10px;
`;

const FeedbackModal = ({ open, handleClose, currentConversation, goodBad }) => {
    const { theme } = useTheme();
    const { user } = useUser();
    const { conversations } = useConversation();
    const modalRef = useRef();
    const [feedback, setFeedback] = useState('');
    const [selectedConversation, setSelectedConversation] = useState(currentConversation);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClose]);

    const handleSubmit = async () => {
        let feedbackText = goodBad + ' feedback: ' + feedback;
        const feedbackData = {
            feedback: feedbackText,
            conversationId: selectedConversation,
            userId: user.uid,
            userEmail: user.email
        };

        await sendFeedback(feedbackData);
        handleClose();
    }

    if (!open) {
        return null;
    }

    return (
        <ModalBackdrop>
            <ModalContainer ref={modalRef} theme={theme}>
                <ModalHeader type="semiBold">Give us your Feedback!</ModalHeader>
                <CustomInput
                    placeholder="Type your feedback here..."
                    value={feedback}
                    minRows={3}
                    onChange={(e) => setFeedback(e.target.value)}
                />
                <ModalFooter>
                    <CustomText style={{marginRight: '10px', fontSize: '0.95rem'}}>
                        Link a specific conversation?
                    </CustomText>
                    <CustomSelector
                        placeholder="Select a conversation"
                        items={conversations}
                        onSelect={setSelectedConversation}
                        onNewItem={null}
                        currentItemId={selectedConversation}
                        createDelete={false}
                    />
                </ModalFooter>
                <ModalButtonsContainer>
                    <CustomButton onClick={handleClose} type="small" style={{ backgroundColor: theme.colors.background, boxShadow: 'none', color: theme.colors.text }}>
                        Cancel
                    </CustomButton>
                    <CustomButton onClick={handleSubmit} type="small">
                        Submit
                    </CustomButton>
                </ModalButtonsContainer>
            </ModalContainer>
        </ModalBackdrop>
    );
};

export default FeedbackModal;
