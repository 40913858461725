import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useUser } from "../contexts/UserContext";
import { useConversation } from "../contexts/ConversationContext";
import Sidebar from "../components/organisms/Sidebar";
import ProfileSelector from "../components/organisms/ProfileSelector";
import FeedbackModal from "../components/organisms/FeedbackModal";
import HelpModal from "../components/organisms/HelpModal";
import SubscribeModal from "../components/organisms/SubscribeModal";
import {PiQuestionDuotone} from "react-icons/pi";
import CustomText from "../components/atoms/CustomText";
import CustomButton from "../components/atoms/CustomButton";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100vh;
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.strokeFaded};
    border-radius: 5px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 20px;
  }
`;

const InnerContainer = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  min-width: 70%;
  max-width: 70%;
  padding-bottom: 100px;
  
  @media (max-width: 800px) {
    margin-top: 60px;
    min-width: 90%;
    max-width: 90%;
    flex-direction: column;
  }
`;

const InnerContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

const InnerContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border: .1px solid ${(props) => props.theme.colors.strokeFaded};
  border-radius: 12px;
  padding: 10px 15px;
  width: 100%;
  
  @media (max-width: 800px) {
    padding: 10px;
    margin-left: -10px;
  }
`;

const InnerContainerDivider = styled.div`
  margin: 8px;
`;

const SupportButton = styled(CustomButton)`
  margin-top: 10px;
  width: 50%;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 1em;
  align-self: center;
`;

const HelpButtonContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  
  @media (max-width: 800px) {
    right: 5px;
    bottom: 5px;
    display: ${(props) => props.isExpanded ? 'none' : 'flex'};
  }
`;

const HelpButton = styled(PiQuestionDuotone)`
  color: ${(props) => props.theme.name === "dark" ? props.theme.colors.stroke : props.theme.colors.border};
  opacity: 0.5;
  cursor: pointer;
`;

const Title = styled(CustomText)`
  font-size: 1.3em;
  margin-bottom: 5px;
`;

const Text = styled(CustomText)`
  font-size: 1.1em;
  display: flex;
  gap: 3px;
  align-items: center;
`;

const SmallText = styled(CustomText)`
  font-size: 0.9em;
  opacity: 0.8;
  //margin-top: 5px;
  
  &:hover {
    cursor: pointer;
  }
`;

const TokenBalance = styled(CustomText)`
  font-size: 1.3em;
  opacity: 0.9;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  // border: .1px solid ${(props) => props.theme.colors.strokeFaded};
  padding: 20px 0;
  border-radius: 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const UpgradeButton = styled(CustomButton)`
  margin-top: 10px;
  align-self: center;
`;

const AccountScreen = () => {
    const { theme } = useTheme();
    const { user } = useUser();
    const { conversations } = useConversation();

    const [isExpanded, setIsExpanded] = useState(window.innerWidth > 1200);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showSubscribeModal, setSubscribeModalOpen] = useState(false);

    const hasDisplayName = user?.displayName;
    const totalConversations = conversations?.length || 0;
    const totalMessages = conversations?.reduce((acc, curr) => acc + curr.messages.length, 0) || 0;
    const tokenUsagePercentage = user?.tokensUsed / user?.tokenLimit * 100 || 0;
    const roundedTokenUsagePercentage = Math.round(tokenUsagePercentage * 100) / 100;
    const totalProfiles = user?.backendProfiles?.length || 0;

    const styleNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleContactSupport = () => {
        window.open('mailto:adeptcontacthelp@gmail.com');
    }

    const openStripeCustomerPortal = async () => {
        window.open(process.env.REACT_APP_STRIPE_PORTAL_LINK, '_blank');
    }

    return (
        <ScreenContainer theme={theme}>
            <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} setFeedbackModelOpen={setShowFeedbackModal} />
            <CenteredContainer theme={theme}>
                <ProfileSelector setSubscribeModalOpen={setSubscribeModalOpen} />

                <InnerContainer>
                    <InnerContainerSection theme={theme}>
                        <Title theme={theme} type="medium">Account</Title>
                        {hasDisplayName && <Text theme={theme} type="medium" style={{opacity: 0.7}}>{user?.displayName}</Text>}
                        <Text theme={theme} type="medium" style={{opacity: 0.7}}>{user?.email}</Text>
                        <InnerContainerDivider/>
                        <Title theme={theme} type="medium">Subscription</Title>
                        {user?.tokenLimit === 1000 && <Text theme={theme} type="medium" style={{color: theme.colors.red}}>No active subscription</Text>}
                        <Text theme={theme} type="medium" style={{opacity: 0.6}}>{styleNumber(user?.tokenLimit)} tokens/mo</Text>
                        <TokenBalance theme={theme} type="semiBold">
                            <span style={{color: theme.colors.primary}}>{styleNumber(user?.tokensUsed)}/{styleNumber(user?.tokenLimit)}</span>
                            <SmallText>tokens used</SmallText>
                        </TokenBalance>
                        <UpgradeButton onClick={() => setSubscribeModalOpen(true)} theme={theme}>{user?.tokenLimit !== 1000 ? 'Upgrade' : 'Buy Subscription'}</UpgradeButton>
                        {user?.tokenLimit !== 1000 && <SmallText theme={theme} type="medium" onClick={openStripeCustomerPortal} style={{alignSelf: 'center', marginTop: '20px'}}>Manage subscription</SmallText>}
                    </InnerContainerSection>
                    <InnerContainerColumn>
                        <InnerContainerSection theme={theme}>
                            <Title theme={theme} type="medium">Usage</Title>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2px'}}>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{styleNumber(user?.tokensUsed)}</span>
                                        <SmallText>tokens used</SmallText>
                                    </Text>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{styleNumber(user?.tokenLimit)}</span>
                                        <SmallText>tokens available</SmallText>
                                    </Text>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{roundedTokenUsagePercentage}%</span>
                                        <SmallText>used</SmallText>
                                    </Text>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', gap: '2px'}}>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{styleNumber(totalMessages)}</span>
                                        <SmallText>messages sent</SmallText>
                                    </Text>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{styleNumber(totalConversations)}</span>
                                        <SmallText>conversation{totalConversations !== 1 && 's'}</SmallText>
                                    </Text>
                                    <Text theme={theme} type="medium">
                                        <span style={{color: theme.colors.primary}}>{totalProfiles}</span>
                                        <SmallText>profile{totalProfiles !== 1 && 's'}</SmallText>
                                    </Text>
                                </div>
                            </div>
                        </InnerContainerSection>
                        <InnerContainerSection theme={theme}>
                            <Title theme={theme} type="medium" style={{ alignSelf: 'center' }}>Get Support</Title>
                            <Text theme={theme} type="medium" style={{ alignSelf: 'center', textAlign: 'center', maxWidth: '80%', marginBottom: '10px' }}>Need help or more information? Reach out to us!</Text>
                            <SupportButton onClick={handleContactSupport}>Contact us</SupportButton>
                            <SupportButton onClick={() => setShowFeedbackModal(true)}>Feedback</SupportButton>
                        </InnerContainerSection>
                    </InnerContainerColumn>
                </InnerContainer>


            </CenteredContainer>
            <HelpButtonContainer isExpanded={isExpanded}>
                <HelpButton size={window.innerWidth <= 800 ? 20 : 25} onClick={() => setShowHelpModal(true)} theme={theme} />
            </HelpButtonContainer>
            <FeedbackModal open={showFeedbackModal} handleClose={() => setShowFeedbackModal(false)} />
            <HelpModal open={showHelpModal} handleClose={() => setShowHelpModal(false)} />
            <SubscribeModal open={showSubscribeModal} handleClose={() => setSubscribeModalOpen(false)} />
        </ScreenContainer>
    );
};

export default AccountScreen
