import React  from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import BouncingCircles from "../atoms/BouncingLoader";
import RadarChart from "../atoms/RadarChart";
import {useLoading} from "../../contexts/LoadingContext";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 1;
  background-color: ${props => props.theme.colors.sidebarBackground};
`;

const InnerContainer = styled.div`
  max-width: 65%;
  min-width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 800px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const RadarContainer = styled.div`
  background-color: transparent;
  width: 35%;
  
  @media (max-width: 1600px) {
    width: 40%;
  }
  
  @media (max-width: 1400px) {
    width: 45%;  
  }
    
  @media (max-width: 1200px) {
    width: 50%;
  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const StyledMessage = styled(CustomText)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  text-align: left;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  align-self: center;
  word-wrap: break-word;
`;

const Message = ({ title, summary, scores, last }) => {
    const { theme } = useTheme();
    const { loading, loadingState } = useLoading();

    return (
        <Container theme={theme}>
            <InnerContainer>
                <StyledMessage theme={theme}>
                    {summary}
                </StyledMessage>
                <RadarContainer theme={theme}>
                    <RadarChart
                        title={title}
                        scores={scores}
                        labelSize={window.innerWidth < 768 ? 10 : 15}
                        tickBackground={theme.colors.aiMessageBackground}
                    />
                </RadarContainer>
                {last && loading && <BouncingCircles text={loadingState}/>}
            </InnerContainer>
        </Container>
    );
};

export default Message;