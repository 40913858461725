import React from 'react';
import styled from 'styled-components';
import Loader from "../components/atoms/Loader";

const LoaderScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #1e1e1e;
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LoaderScreenNoTheme = () => {
    return (
        <LoaderScreenContainer>
            <CenteredContainer>
                <Loader color={'#6b72fd'} />
            </CenteredContainer>
        </LoaderScreenContainer>
    );
};

export default LoaderScreenNoTheme;