import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from '../atoms/CustomText';
import { FiChevronRight } from "react-icons/fi";

const SuggestionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin: 5px 5px;
  padding: 12px;
  border: 1px solid ${(props) => (props.theme.name === "dark" ? props.theme.colors.strokeFaded : props.theme.colors.strokeFade)};
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;

  &:hover {
    transform: scale(1.007);
    background-color: ${(props) => props.theme.colors.sidebarBackground};
    cursor: pointer;
    opacity: 1;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  //gap: 5px;
  flex-grow: 1;
`;

const SuggestionButtonHookText = styled(CustomText)`
  font-size: 0.9em;
  opacity: 0.65;
  transition: opacity 0.3s ease;
`;

const SuggestionButtonText = styled(CustomText)`
  font-size: 0.9em;
  opacity: 0.4;
  transition: opacity 0.3s ease;
`;

const ChevronIcon = styled(FiChevronRight)`
  color: ${(props) => (props.theme.colors.border)};
  margin-left: 15px;
  transition: transform 0.3s ease;
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  
  ${SuggestionButtonContainer}:hover & {
    display: flex;
  }
`;

const SuggestionButton = ({ suggestionHook, suggestionFollowUp, onSuggestionClick }) => {
    const { theme } = useTheme();

    const onClick = () => {
        onSuggestionClick(suggestionHook + ' ' + suggestionFollowUp);
    }

    return (
        <SuggestionButtonContainer theme={theme} onClick={onClick}>
            <TextContainer>
                <SuggestionButtonHookText theme={theme} type="medium">
                    {suggestionHook}
                </SuggestionButtonHookText>
                <SuggestionButtonText theme={theme}>
                    {suggestionFollowUp}
                </SuggestionButtonText>
            </TextContainer>
            <ChevronIcon theme={theme} size={20} />
        </SuggestionButtonContainer>
    );
}

export default SuggestionButton;
