import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { getConversations, onConversationUpdate, deleteConversation, createOrUpdateConversation } from '../services/firebaseFirestore';
import { v4 as uuidv4 } from 'uuid';

const ConversationContext = createContext();

export const useConversation = () => useContext(ConversationContext);

export const ConversationProvider = ({ children }) => {
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const { user, setUser, loading } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            setLoadingConversations(true);
            return;
        }

        const fetchConversations = async () => {
            const userConversations = await getConversations(user.uid);
            const sortedConversations = userConversations.sort((a, b) => {
                const aDate = a.lastUpdatedAt || a.createdAt;
                const bDate = b.lastUpdatedAt || b.createdAt;
                return bDate - aDate;
            });
            if (sortedConversations.length > 0) {
                setConversations(sortedConversations);
                setCurrentConversation(sortedConversations[0] || null);
            } else {
                setConversations([]);
                setCurrentConversation({
                    id: uuidv4(),
                    backendUserId: user.selectedBackendProfileId,
                    latestInteractionId: '',
                    name: 'Untitled',
                    messages: [],
                    createdAt: new Date(),
                    lastUpdatedAt: new Date(),
                });
            }
            setLoadingConversations(false);
        };

        fetchConversations();
    }, [user, loading]);

    useEffect(() => {
        let unsubscribe;
        if (currentConversation?.id) {
            unsubscribe = onConversationUpdate(currentConversation.id, (updatedConversation) => {
                setCurrentConversation(updatedConversation);
            });
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [currentConversation?.id]);

    const sortConversationsByLastUpdatedAt = (conversations) => {
        return conversations.sort((a, b) => {
            const aDate = a.lastUpdatedAt || a.createdAt;
            const bDate = b.lastUpdatedAt || b.createdAt;
            return bDate - aDate;
        });
    }

    const chooseConversation = (conversationId) => {
        const conversation = conversations.find(c => c.id === conversationId);
        if (conversation) {
            setCurrentConversation(conversation);
        } else {
            // console.error('Conversation not found:', conversationId);
        }
    }

    const selectConversation = (conversationId) => {
        const conversation = conversations.find(c => c.id === conversationId);
        if (conversation) {
            setCurrentConversation(conversation);
            setUser(prevUser => ({ ...prevUser, selectedBackendProfileId: conversation.backendUserId }));
            navigate(`/c/${conversationId}`);
        } else {
            // console.error('Conversation not found:', conversationId);
        }
    };

    const findOrCreateBlankConversation = async () => {
        const emptyConversation = conversations.find(convo => convo.messages.length === 0);

        if (emptyConversation) {
            return emptyConversation.id;
        } else {
            return await createNewConversation();
        }
    };

    const createNewConversation = async () => {
        try {
            const newConversation = {
                id: uuidv4(),
                backendUserId: user.selectedBackendProfileId,
                latestInteractionId: '',
                name: 'Untitled',
                messages: [],
                createdAt: new Date(),
                lastUpdatedAt: new Date(),
            };
            await createOrUpdateConversation(user.uid, newConversation);
            setConversations(prevConversations => [newConversation, ...prevConversations]);
            setCurrentConversation(newConversation);
            navigate(`/c/${newConversation.id}`);
            return newConversation.id;
        } catch (error) {
            console.error('Error creating new conversation:', error);
        }
    };

    const deleteUserConversation = async (conversationId) => {
        try {
            await deleteConversation(conversationId);
            const updatedConversations = conversations.filter(conversation => conversation.id !== conversationId);
            setConversations(updatedConversations);
            setCurrentConversation(updatedConversations[0] || null);

            if (currentConversation?.id === conversationId) {
                navigate(`/c/${updatedConversations[0]?.id}`);
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const updateConversationName = async (conversationId, newName) => {
        try {
            const conversationIndex = conversations.findIndex(c => c.id === conversationId);
            if (conversationIndex !== -1) {
                const updatedConversation = { ...conversations[conversationIndex], name: newName };
                await createOrUpdateConversation(user.uid, updatedConversation);
                setConversations(prev => [
                    ...prev.slice(0, conversationIndex),
                    updatedConversation,
                    ...prev.slice(conversationIndex + 1),
                ]);
            }
        } catch (error) {
            console.error('Error updating conversation name:', error);
        }
    };

    const value = {
        loadingConversations,
        conversations,
        setConversations,
        currentConversation,
        setCurrentConversation,
        chooseConversation,
        selectConversation,
        findOrCreateBlankConversation,
        createNewConversation,
        deleteUserConversation,
        updateConversationName
    };

    return (
        <ConversationContext.Provider value={value}>
            {children}
        </ConversationContext.Provider>
    );
};
