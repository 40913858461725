import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from "../contexts/UserContext";
import { useConversation } from "../contexts/ConversationContext";
import FullLoaderScreen from "./FullLoaderScreen";

const PrivateRoute = ({ children }) => {
    const { user, loading } = useUser();
    const { loadingConversations } = useConversation();

    if (loading || (loadingConversations && user)) {
        return <FullLoaderScreen />;
    }

    return user ? children : <Navigate to="/welcome" />;
};

export default PrivateRoute;