import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/organisms/Sidebar';
import { useTheme } from "../contexts/ThemeContext";
import { useUser } from "../contexts/UserContext";
import { getProfile, resetProfile } from "../api/api";
import FeedbackModal from "../components/organisms/FeedbackModal";
import ProfileSelector from "../components/organisms/ProfileSelector";
import SubscribeModal from "../components/organisms/SubscribeModal";
import ProfileHeaderNode from "../components/molecules/ProfileHeaderNode";
import ProfileNode from "../components/molecules/ProfileNode";
import CustomText from "../components/atoms/CustomText";
import ProfileModal from "../components/molecules/ProfileModal";
import { getTypeColor } from "../utils/utils";
import ProfileCard from "../components/molecules/ProfileCard";
import CustomButton from "../components/atoms/CustomButton";
import AddNodeModal from "../components/organisms/AddNodeModal";
import ExpandableScrollList from "../components/molecules/ExpandableScrollList";
import CustomRegularInput from "../components/atoms/CustomRegularInput";
import CustomInput from "../components/atoms/CustomInput";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.background};
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  gap: 20px;
  min-width: 85%;
  max-width: 85%;
  padding-bottom: 100px;
  
  @media (max-width: 768px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const InnerTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const HorizontalTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    position: relative;
`;

const DescriptionText = styled(CustomText)`
  font-size: 1.1rem;
  max-width: 100%;
`;

const WrapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  position: relative;
  
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const VerticalListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    position: relative;
`;

const VerticalTypeItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
    position: relative;
`;

const VerticalTypeItemLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
`;

const InfoText = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.4;
`;

const WrapContainerInfoText = styled(CustomText)`
  font-size: 1rem;
  opacity: 0.4;
  position: absolute;
  top: -35px;
  left: 5px;
`;

const WrapContainerToolsNode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 140px;
  height: 140px;
  border-radius: 25px;
  background-color: ${props => props.theme.name === "dark" ? props.theme.colors.border : props.theme.colors.stroke};
  position: relative;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const ResetButton = styled(CustomButton)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  
  &:hover {
    background-color: ${props => props.theme.colors.red};
  }
`;

const YouScreen = () => {
    const { theme } = useTheme();
    const { user } = useUser();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showSubscribeModal, setSubscribeModalOpen] = useState(false);
    const [addNodeModalOpen, setAddNodeModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(window.innerWidth > 1200);
    const [viewMode, setViewMode] = useState('vertical');
    const [searchInput, setSearchInput] = useState('');

    const [userData, setUserData] = useState(null);
    const [randomColors, setRandomColors] = useState({});

    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const profileData = await getProfile(user.selectedBackendProfileId);
                if (profileData.profile) {
                    setUserData(profileData.profile);

                    const profileTypes = Object.keys(profileData.profile);
                    let colorsMap = {};
                    profileTypes.forEach((type, index) => {
                        colorsMap[type] = getTypeColor(type, theme.colors, theme.randomColors);
                    });
                    setRandomColors(colorsMap);
                }
            } catch (error) {
                console.error('Error getting profile data:', error);
            }
        };

        getUserData();
    }, [user, theme]);

    const handleProfileClick = (profileData) => {
        setModalData(profileData); // Set the data for the modal
    };

    const handleReset = async  () => {
        if (Object.keys(userData).length === 0) return; // If there is no data, don't do anything (this should never happen
        //  confirm user is sure and reload page
        if (window.confirm('Are you sure you want to reset your graph? This will delete all of your saved context. (You can always just create another profile)')) {
            try {
                await resetProfile(user.uid, user.selectedBackendProfileId);
                const profileData = await getProfile(user.selectedBackendProfileId);
                if (profileData.profile && profileData.profile.length > 0) {
                    setUserData(profileData.profile);

                    const profileTypes = Object.keys(profileData.profile);
                    let colorsMap = {};
                    profileTypes.forEach((type, index) => {
                        colorsMap[type] = getTypeColor(type, theme.colors, theme.randomColors);
                    });
                    setRandomColors(colorsMap);
                }
            } catch (error) {
                console.error('Error resetting graph:', error);
            }
        }
    }

    const profileTypes = userData ? Object.keys(userData) : [];

    return (
        <ScreenContainer theme={theme}>
            <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} setFeedbackModelOpen={setShowFeedbackModal} />
                <CenteredContainer>
                    <ProfileSelector setSubscribeModalOpen={setSubscribeModalOpen} />
                    <InnerContainer theme={theme}>
                        <InnerTopContainer>
                            <DescriptionText>
                                Explore and manage your profiles crafted by OwnAI. Add new memories or delete existing ones to keep your profiles up-to-date. For a fresh start, you can reset your entire profile anytime.
                            </DescriptionText>
                            <ProfileCard profileData={userData} />
                        </InnerTopContainer>
                        <HorizontalTopContainer>
                            <CustomInput
                                type="text"
                                placeholder="🔎 Search"
                                theme={theme}
                                style={{ width: '200px' }}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <CustomButton onClick={() => setAddNodeModalOpen(true)} theme={theme} type="small">
                                    Add
                                </CustomButton>
                                <ResetButton onClick={handleReset} theme={theme} type="small">
                                    Reset
                                </ResetButton>
                                {/*<CustomButton type="small" onClick={() => setViewMode(viewMode === 'horizontal' ? 'vertical' : 'horizontal')}>*/}
                                {/*    Switch to {viewMode === 'horizontal' ? 'Vertical' : 'Horizontal'} View*/}
                                {/*</CustomButton>*/}
                            </div>
                        </HorizontalTopContainer>
                        {userData && (
                            viewMode === 'horizontal' ? (
                                <WrapContainer>
                                    {/*{*/}
                                    {/*    profileTypes.map(type => (*/}
                                    {/*        <React.Fragment key={type}>*/}
                                    {/*            <ProfileHeaderNode*/}
                                    {/*                key={type}*/}
                                    {/*                type={type}*/}
                                    {/*                count={typesCounts[type]}*/}
                                    {/*                onClick={() => toggleExpandType(type)}*/}
                                    {/*                expanded={expandedTypes[type]}*/}
                                    {/*                color={randomColors[type]}*/}
                                    {/*            />*/}
                                    {/*            {expandedTypes[type] && userData[type].map(profile => (*/}
                                    {/*                <ProfileNode*/}
                                    {/*                    key={profile.id}*/}
                                    {/*                    index={profile.id}*/}
                                    {/*                    {...profile}*/}
                                    {/*                    color={randomColors[type]}*/}
                                    {/*                    onClick={handleProfileClick}*/}
                                    {/*                />*/}
                                    {/*            ))}*/}
                                    {/*        </React.Fragment>*/}
                                    {/*    ))*/}
                                    {/*}*/}
                                </WrapContainer>
                            ) : (
                                <ExpandableScrollList outerItems={profileTypes} innerItems={userData} handleNodeClick={handleProfileClick} searchInput={searchInput} />
                            )
                        )}
                    </InnerContainer>
                </CenteredContainer>
            {modalData && <ProfileModal profileData={modalData} onClose={() => setModalData(null)} color={randomColors[modalData.type]} />}
            <FeedbackModal open={showFeedbackModal} handleClose={() => setShowFeedbackModal(false)} />
            <SubscribeModal open={showSubscribeModal} handleClose={() => setSubscribeModalOpen(false)} />
            <AddNodeModal open={addNodeModalOpen} handleClose={() => setAddNodeModalOpen(false)} setUserData={setUserData} />
        </ScreenContainer>
    );
};

export default YouScreen;