import React, {useEffect, useRef, useState} from 'react';
import MessageList from '../molecules/MessageList';
import MessageInput from "../atoms/MessageInput";
import styled from 'styled-components';
import {FiArrowDown} from "react-icons/fi";
import StatusCard from "../molecules/StatusCard";
import {useUser} from '../../contexts/UserContext';
import {useTheme} from "../../contexts/ThemeContext";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {useConversation} from "../../contexts/ConversationContext";
import {useLoading} from "../../contexts/LoadingContext";
import {allowedFileTypes} from "../../utils/constants";
import FileUploadOverlay from "../molecules/FileUploadOverlay";
import {handleMsg, handleSend} from "../../utils/chatUtils";

const ChatWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

const ScrollToBottomChevron = styled(FiArrowDown)`
  position: absolute;
  bottom: 100px;
  z-index: 5;
  padding: 8px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.borderFade};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  opacity: 1;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;

const ChatWindow = ({ suggestions, setTokenDifference, setSubscribeModalOpen, setShowFeedbackModal, setGoodBad, isPersonal, essayWriter, humanizer, webSearchOn, imageGenOn, contextWindow }) => {
    const { user, setUser } = useUser();
    const { currentConversation, setConversations } = useConversation();
    const { theme } = useTheme();
    const { loading, setLoading, setStreaming, setLoadingState } = useLoading();
    const { socket, isConnected } = useWebSocket();

    const [messages, setMessages] = useState([]);
    const messagesRef = useRef(messages);
    const [receivedTextPartIDs, setReceivedTextPartIDs] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [conversationFiles, setConversationFiles] = useState([]);
    const [filesUrgent, setFilesUrgent] = useState(false);
    const [audioFile, setAudioFile] = useState(null);

    const [showScrollToBottom, setShowScrollToBottom] = useState(false);

    const [dragging, setDragging] = useState(false);
    const messageListRef = useRef(null);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (currentConversation?.messages) {
            setMessages(currentConversation.messages);
            setConversationFiles([]);
            setError(null);
        }
    }, [currentConversation.id]);

    useEffect(() => {
        messagesRef.current = messages;
    }, [messages]);

    useEffect(() => {
        // Automatically scroll down when the messages array changes
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (!socket) return;
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleMsg(
                data,
                setLoadingState,
                receivedTextPartIDs,
                setReceivedTextPartIDs,
                setMessages,
                setLoading,
            );
        }
    }, [socket]);

    const onSend = async (text) => {
        if (!text && selectedFiles.length === 0 && !audioFile) {
            return;
        }

        setStreaming(true);

        await handleSend(
            text,
            user,
            scrollToBottom,
            setError,
            setLoading,
            setLoadingState,
            setStreaming,
            selectedFiles,
            conversationFiles,
            setSelectedFiles,
            setMessages,
            messages,
            messagesRef,
            setUser,
            currentConversation,
            setConversations,
            contextWindow,
            isPersonal,
            essayWriter,
            humanizer,
            webSearchOn,
            imageGenOn,
            filesUrgent,
            setFilesUrgent,
            setTokenDifference,
            setSubscribeModalOpen,
        );
    }

    const retryFailedMessage = async () => {
        const newMessages = [...messages];
        const retryText = newMessages.pop().text;
        setMessages(newMessages);

        if (retryText) {
            await onSend(retryText);
        }
    };

    const retrySuccessfulMessage = async () => {
        const newMessages = [...messages];
        newMessages.pop();
        const retryText = newMessages.pop().text;
        setMessages(newMessages);
        if (retryText) {
            await onSend(retryText);
        }
    }

    const scrollToBottom = () => {
        if (messageListRef.current && autoScrollEnabled) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        const handleUserScroll = () => {
            if (!messageListRef.current) return;

            const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
            const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 50; // 50px threshold

            setAutoScrollEnabled(isScrolledToBottom);
        };

        const messageListElement = messageListRef.current;
        messageListElement?.addEventListener('scroll', handleUserScroll);

        return () => {
            messageListElement?.removeEventListener('scroll', handleUserScroll);
        };
    }, []);

    const handleAudioFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAudioFile(e.target.files[0]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const validFiles = Array.from(e.dataTransfer.files).filter(file => {
                return allowedFileTypes.split(', ').some(type => file.name.endsWith(type.trim()));
            });

            if (validFiles.length !== e.dataTransfer.files.length) {
                alert('Some files were ignored because they are not allowed.');
            }

            setSelectedFiles(validFiles);
            setConversationFiles(conversationFiles.concat(validFiles));
            setFilesUrgent(true);
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const validFiles = Array.from(e.target.files).filter(file => {
                return allowedFileTypes.split(', ').some(type => file.name.endsWith(type.trim()));
            });

            if (validFiles.length !== e.target.files.length) {
                alert('Some files were ignored because they are not allowed.');
            }

            setSelectedFiles(validFiles);
            setConversationFiles(conversationFiles.concat(validFiles));
            setFilesUrgent(true);
        }
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
        setConversationFiles(newFiles);
        if (newFiles.length === 0) {
            setFilesUrgent(false);
        }
    };

    const handleFeedback = (feedback) => {
        setShowFeedbackModal(true);
        if (feedback === "good") {
            setGoodBad("good");
        } else if (feedback === "bad") {
            setGoodBad("bad");
        }
    }

    useEffect(() => {
        const handleWindowDragOver = (e) => e.preventDefault();

        window.addEventListener('dragover', handleWindowDragOver);

        const chatWindowContainer = document.getElementById("chatWindowContainer");
        if (chatWindowContainer) {
            chatWindowContainer.addEventListener('dragover', handleDragOver);
            chatWindowContainer.addEventListener('dragleave', handleDragLeave);
            chatWindowContainer.addEventListener('drop', handleDrop);
        }

        return () => {
            window.removeEventListener('dragover', handleWindowDragOver);

            if (chatWindowContainer) {
                chatWindowContainer.removeEventListener('dragover', handleDragOver);
                chatWindowContainer.removeEventListener('dragleave', handleDragLeave);
                chatWindowContainer.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    return (
        <ChatWindowContainer id="chatWindowContainer">
            {dragging &&
                <FileUploadOverlay />
            }
            <StatusCard />
            <MessageList
                ref={messageListRef}
                messages={messages}
                handleSendEssay={null}
                loading={loading}
                error={error}
                retrySendMessage={retryFailedMessage}
                retrySuccessfulMessage={retrySuccessfulMessage}
                onFeedback={handleFeedback}
            />
            {showScrollToBottom && (
                <ScrollToBottomChevron size={24} onClick={scrollToBottom} theme={theme}/>
            )}
            <MessageInput
                placeholder="Message OwnAI..."
                onSend={onSend}
                selectedFiles={selectedFiles}
                handleFileSelect={handleFileSelect}
                handleRemoveFile={handleRemoveFile}
                handleAudioFileSelect={handleAudioFileSelect}
                loading={loading}
                suggestions={suggestions}
                showSuggestions={messages.length === 0}
                isConnected={isConnected}
            />
        </ChatWindowContainer>
    );
};


export default ChatWindow;