import styled from "styled-components";
import CustomText from "./CustomText";
import {FaChevronDown} from "react-icons/fa6";
import {useTheme} from "../../contexts/ThemeContext";
import React, {useState} from "react";
import CustomButton from "./CustomButton";

const WebSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
`;

const WebSearchInnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
`;

const WebSearchHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1px;
`;

const WebSearchSubtitle = styled(CustomText)`
    font-size: 0.9rem;
    opacity: 0.7;
`;

const WebSearchSmallSubtitle = styled(CustomText)`
    font-size: 0.8rem;
    opacity: 0.7;
`;

const WebSearchDescription = styled(CustomText)`
    font-size: 1rem;
`;

const WebSearchColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
`;

const OrganicResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    
    background-color: ${({ theme }) => theme.colors.background};
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.strokeFade};
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    
    &:hover {
        transform: scale(1.01);
    }
`;

const OrganicResultTitle = styled(CustomText)`
    font-size: 1.1rem;
`;

const OrganicResultSnippet = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.7;
`;

const TopStoryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    
    background-color: ${({ theme }) => theme.colors.background};
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.strokeFade};
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    
    &:hover {
        transform: scale(1.02);
    }
`;

const TopStoryTitle = styled(CustomText)`
    font-size: 1.1rem;
`;

const TopStoryThumbnail = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 5px;
    
    @media (max-width: 1000px) {
        width: 75px;
        height: 75px;
    }
`;

const RelatedQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    
    background-color: ${({ theme }) => theme.colors.background};
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.strokeFade};
    width: calc(100% - 20px);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    
    &:hover {
        transform: scale(1.02);
    }
`;

const RelatedQuestionHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const DownChevron = styled(FaChevronDown)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.9rem;
    cursor: pointer;
    opacity: 0.7;
    
    transform: ${props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

const RelatedQuestionTitle = styled(CustomText)`
    font-size: 1rem;
`;

const RelatedQuestionAnswer = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.7;
`;

const WebSearchAttribute = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.7;
`;

const WebSearchAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
`;

const WebSearchAnswerText = styled(CustomText)`
    font-size: 1.2rem;
`;

const WebAnswerSource = styled(CustomText)`
    font-size: 1rem;
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    text-decoration: underline;
    
    &:hover {
        opacity: 1;
        color: ${({ theme }) => theme.colors.primary};
    }
`;

const RelatedQuestion = ({ question, answer }) => {
    const { theme } = useTheme();
    const [showExpanded, setShowExpanded] = useState(false);

    return (
        <RelatedQuestionContainer onClick={() => setShowExpanded(!showExpanded)} theme={theme}>
            <RelatedQuestionHeaderContainer>
                <RelatedQuestionTitle>
                    {question}
                </RelatedQuestionTitle>
                <DownChevron theme={theme} expanded={showExpanded} />
            </RelatedQuestionHeaderContainer>
            {showExpanded &&
                <RelatedQuestionAnswer>{answer}</RelatedQuestionAnswer>
            }
        </RelatedQuestionContainer>
    );

}

const RelatedQuestions = ({ relatedQuestions }) => {
    return (
        <WebSearchColumnContainer>
            <WebSearchDescription type="medium">
                Related Questions
            </WebSearchDescription>
            {relatedQuestions.map((question, index) => (
                <RelatedQuestion key={index} question={question.question} answer={question.answer} />
            ))}
        </WebSearchColumnContainer>
    );
}

const QuestionsAndAnswers = ({ relatedQuestions }) => {
    return (
        <WebSearchColumnContainer>
            <WebSearchDescription type="medium">
                Questions & answers
            </WebSearchDescription>
            {relatedQuestions.map((question, index) => (
                <RelatedQuestion key={index} question={question.question} answer={question.answer} />
            ))}
        </WebSearchColumnContainer>
    );
}

const TopStory = ({ story }) => {
    const { theme } = useTheme();

    return (
        <TopStoryContainer theme={theme} onClick={() => window.open(story.link, "_blank")}>
            <TopStoryThumbnail src={story.thumbnail} alt={story.title} />
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "5px"}}>
                <TopStoryTitle>
                    {story.title}
                </TopStoryTitle>
                <WebSearchAnswerContainer>
                    <WebSearchSubtitle>
                        {story.source}
                    </WebSearchSubtitle>
                    <WebSearchSmallSubtitle>
                        {story.date}
                    </WebSearchSmallSubtitle>
                </WebSearchAnswerContainer>
            </div>
        </TopStoryContainer>
    );
}

const TopStories = ({ topStories }) => {
    return (
        <WebSearchColumnContainer>
            <WebSearchDescription type="medium">
                Top Stories
            </WebSearchDescription>
            {topStories.slice(0, 3).map((story, index) => (
                <TopStory key={index} story={story} />
            ))}
        </WebSearchColumnContainer>
    );
}

export const WebSearchInfoHeader = ({ webSearch }) => {
    return (
        <WebSearchHeaderContainer>
            <WebSearchSubtitle>
                Searched for "{webSearch.search_parameters.q}"
            </WebSearchSubtitle>
            {/*<WebSearchSmallSubtitle>*/}
            {/*    in {webSearch.search_information.time_taken_displayed} seconds*/}
            {/*</WebSearchSmallSubtitle>*/}
        </WebSearchHeaderContainer>
    );
}

export const WebSearchInfo = ({ webSearch }) => {
    const {theme} = useTheme();
    const [showExpanded, setShowExpanded] = useState(false);

    return (
        <WebSearchContainer>
            <CustomButton type="fadedSmall" theme={theme} onClick={() => setShowExpanded(!showExpanded)} style={{marginTop: "5px", marginBottom: "10px"}}>
                {showExpanded ? "Hide" : "Show"} Search Results
            </CustomButton>
            {showExpanded &&
                <WebSearchInnerContainer>
                    {webSearch.top_stories && <TopStories topStories={webSearch.top_stories} />}
                    <WebSearchColumnContainer>
                        <WebSearchDescription type="medium">
                            Results
                        </WebSearchDescription>
                        {webSearch.organic_results && webSearch.organic_results.slice(0, 3).map((result, index) => (
                            <OrganicResult key={index} theme={theme} onClick={() => window.open(result.link, "_blank")}>
                                <OrganicResultTitle theme={theme} type="small">{result.title}</OrganicResultTitle>
                                <OrganicResultSnippet theme={theme} type="small">{result.snippet}</OrganicResultSnippet>
                            </OrganicResult>
                        ))}
                    </WebSearchColumnContainer>
                    {webSearch.related_questions && <RelatedQuestions relatedQuestions={webSearch.related_questions} />}
                    {webSearch.questions_and_answers && <QuestionsAndAnswers relatedQuestions={webSearch.questions_and_answers} />}
                </WebSearchInnerContainer>
            }
        </WebSearchContainer>
    )
}