import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import Loader from "../components/atoms/Loader";

const LoaderScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.background};
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LoaderScreen = () => {
    const { theme } = useTheme();
    return (
        <LoaderScreenContainer theme={theme}>
            <CenteredContainer theme={theme}>
                <Loader color={theme.colors.primary} />
            </CenteredContainer>
        </LoaderScreenContainer>
    );
};

export default LoaderScreen;