import React, { createContext, useContext, useState, useEffect } from 'react';

const lightTheme = {
    name: 'light',
    colors: {
        dark: '#2E3D49',
        white: '#FFFFFF',
        background: '#FFFFFF',
        backgroundFade: '#FFFFFFCC',
        aiMessageBackground: '#EFEFEF',
        sidebarBackground: '#f5f5f5',
        sidebarBackgroundFade: 'rgba(230,230,230,0.8)',
        welcomeBackground: '#FFFFFF',
        text: '#2E3D49',
        primary: '#6b72fd',
        primaryDark: '#4d53b9',
        primaryFade: 'rgba(107, 114, 253, 0.6)',
        primaryFaded: 'rgba(107, 114, 253, 0.2)',
        activeColor: '#636bea',
        brighterActiveColor: '#DDDDDD',
        sidebarIcon: '#2E3D49',
        lightBorder: '#bdbdbd',
        border: '#595959',
        borderFade: 'rgba(89, 89, 89, 0.3)',
        stroke: '#DDDDDD',
        strokeFade: 'rgba(176, 190, 197, 0.5)',
        strokeFaded: 'rgba(176, 190, 197, 0.3)',
        pink: '#ECB5C9',
        darkPink: '#723939',
        purple: '#b37fea',
        darkPurple: '#752e6a',
        purpleFade: 'rgba(203, 107, 190, 0.3)',
        orange: '#FFA641',
        darkOrange: '#774d1c',
        orangeFade: 'rgba(255, 166, 65, 0.3)',
        green: '#8DCC93',
        darkGreen: '#2c442c',
        greenFade: 'rgba(141, 204, 147, 0.3)',
        blue: '#80abfa',
        darkBlue: '#394b9d',
        blueFade: 'rgba(128, 171, 250, 0.3)',
        yellow: '#ffd67e',
        darkYellow: '#b78933',
        yellowFade: 'rgba(255, 214, 126, 0.5)',
        paleRed: '#ff9595',
        darkPaleRed: '#723939',
        paleRedFade: 'rgba(255, 149, 149, 0.5)',
        red: '#E55050',
        redFade: 'rgba(229, 80, 80, 0.5)',
        lightBlue: '#9AD0EC',
        darkLightBlue: '#4f6c7e',
        lightBlueFade: 'rgba(154, 208, 236, 0.3)',
        offWhite: '#F7F7F7',
        darkGray: '#4A4A4A',
        mediumGray: '#9E9E9E',
        lightGray: '#C4C4C4',
        lightGrayFade: 'rgba(196, 196, 196, 0.3)',
        buttonHover: '#4D5B9E',
        buttonActive: '#3D4A8D',
        link: '#2979FF',
        linkFade: 'rgba(41, 121, 255, 0.3)',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
        gridColor: '#222'
    },
    randomColors: [
        '#b37feaaf',
        '#F0A7A0cf',
        '#BFEDC1bf',
        '#FF970Faf',
        '#FFB30Faf',
        '#E55050bf',
        '#5674f1af',
        '#FFA641af',
        '#80abfaaf',
        '#FFD67Eaf',
        '#FF9595cf',
    ]
};

const darkTheme = {
    name: 'dark',
    colors: {
        dark: '#2E3D49',
        white: '#FFFFFF',
        background: '#1E1E1E',
        backgroundFade: '#1E1E1ECC',
        aiMessageBackground: '#2E2E2E',
        sidebarBackground: '#2E2E2E',
        sidebarBackgroundFade: 'rgba(73,73,73,0.8)',
        welcomeBackground: '#1E1E1E',
        text: '#FFFFFF',
        primary: '#5B63EB',
        primaryDark: '#5B63EB',
        primaryFade: 'rgba(91, 99, 235, 0.5)',
        primaryFaded: 'rgba(91, 99, 235, 0.3)',
        activeColor: '#5aa8fc',
        brighterActiveColor: '#172948',
        sidebarIcon: '#DDDDDD',
        lightBorder: 'rgba(176, 190, 197, 0.6)',
        border: '#595959',
        borderFade: 'rgba(89, 89, 89, 0.3)',
        stroke: '#DDDDDD',
        strokeFade: 'rgba(176, 190, 197, 0.5)',
        strokeFaded: 'rgba(176, 190, 197, 0.3)',
        pink: '#ECB5C9',
        darkPink: '#723939',
        purple: '#916bcb',
        darkPurple: '#752e6a',
        purpleFade: 'rgba(203, 107, 190, 0.3)',
        green: '#8DCC93',
        darkGreen: '#2c442c',
        greenFade: 'rgba(141, 204, 147, 0.3)',
        blue: '#62BEC1',
        blueFade: 'rgba(98, 190, 193, 0.3)',
        yellow: '#ffa641',
        darkYellow: '#855723',
        yellowFade: 'rgba(255, 213, 100, 0.3)',
        orange: '#FFA641',
        darkOrange: '#774d1c',
        orangeFade: 'rgba(255, 166, 65, 0.3)',
        paleRed: '#ff9595',
        paleRedFade: 'rgba(255, 149, 149, 0.5)',
        darkPaleRed: '#723939',
        red: '#E55050',
        redFade: 'rgba(229, 80, 80, 0.5)',
        lightBlue: '#9AD0EC',
        darkLightBlue: '#4f6c7e',
        lightBlueFade: 'rgba(154, 208, 236, 0.3)',
        offWhite: '#F7F7F7',
        darkGray: '#9A9A9A',
        mediumGray: '#9E9E9E',
        lightGray: '#C4C4C4',
        lightGrayFade: 'rgba(196, 196, 196, 0.3)',
        buttonHover: '#4D5B9E',
        buttonActive: '#3D4A8D',
        link: '#2979FF',
        linkFade: 'rgba(41, 121, 255, 0.3)',
        error: '#FF1744',
        success: '#00E676',
        warning: '#FF9100',
        info: '#2196F3',
        infoFade: 'rgba(33, 150, 243, 0.3)',
        gridColor: 'rgba(176, 190, 197, 0.3)',
    },
    randomColors: [
        '#b37feaaf',
        '#F0A7A0af',
        '#8DCC93af',
        '#FF970Faf',
        '#E55050af',
        '#5674f1af',
        '#FFA641af',
        '#FFB30Faf',
        '#80abfaaf',
    ]
}

const ThemeContext = createContext(lightTheme);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(darkTheme);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleMediaChange = (e) => {
            setTheme(e.matches ? darkTheme : lightTheme);
        }

        mediaQuery.addListener(handleMediaChange);
        handleMediaChange(mediaQuery);

        return () => mediaQuery.removeListener(handleMediaChange);
    }, []);

    const toggleTheme = () => {
        setTheme(theme.name === 'light' ? darkTheme : lightTheme);
    }

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};