import React from 'react';
import styled from 'styled-components';
import ContextItem from "./ContextItem";
import CustomText from "../atoms/CustomText";

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
`;

const ContextItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

const TitleText = styled(CustomText)`
  font-size: 20px;
`;

const ContextItems = ({ contexts }) => {
    return (
        <OuterContainer>
            <TitleText type="medium">Contexts</TitleText>
            <ContextItemsContainer>
                {contexts && contexts.length > 0 && contexts.map((context, index) => <ContextItem key={index} context={context} />)}
            </ContextItemsContainer>
        </OuterContainer>
    );
}

export default ContextItems;