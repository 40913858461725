import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { useLoading } from "../../contexts/LoadingContext";
import BouncingCircles from "../atoms/BouncingLoader";
import {
    PiDownloadSimpleBold,
    PiXBold,
    PiInfoBold,
    PiClipboardBold,
    PiArrowClockwiseBold,
    PiThumbsUpBold
} from "react-icons/pi";
import CustomText from "../atoms/CustomText";
import MessageButton from "../atoms/MessageButton";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${props => props.theme.colors.sidebarBackground};
  gap: 8px;
`;

const DownloadButton = styled.button`
  position: absolute;
  top: 15px;
  left: 5px;
  align-self: flex-start;
  border: none;
  background-color: ${props => props.theme.colors.strokeFade};
  border-radius: 7px;
  color: ${props => props.theme.colors.text};
  font-size: .9em;
  font-family: 'Poppins', serif;
  display: none;
  opacity: 0.8;
  
  &:hover {
    opacity: .95;
    transform: scale(1.03);
    background-color: ${props => props.theme.colors.stroke};
    transition: opacity .2s cubic-bezier(.17,.67,.83,.67), transform .2s cubic-bezier(.17,.67,.83,.67);
  }
`;

const InnerContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 40%;
  position: relative;

  @media (max-width: 768px) {
    max-width: 90%;
  }
  
  &:hover {
    ${DownloadButton} {
      display: block;
    }
  }
`;

const ImageContainer = styled.img`
  align-self: center;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
`;

const FullScreenImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

const FullScreenMainContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FullScreenImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
`;

const ButtonsContainerBottom = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;]
  max-width: 65%;
  min-width: 65%;
  gap: 2px;

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const IconArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

const InfoIconPopup = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    bottom: ${props => props.contextUsed ? '-60px;' : '-50px'};
    left: 10px;
    transform: translateX(-50%);
    background-color: ${props => props.theme.colors.background};
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    white-space: nowrap;
`;

const ClipboardIcon = styled(PiClipboardBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const RetryIcon = styled(PiArrowClockwiseBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
`;

const ThumbsUpIcon = styled(PiThumbsUpBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const ThumbsDownIcon = styled(ThumbsUpIcon)`
    transform: rotate(180deg);
`;

const FullScreenSideBar = styled.div`
  width: 25%;
  height: 100%;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.sidebarBackground};
  border-radius: 10px 0 0 10px;
  display: ${props => props.isInfoExpanded ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  justify-content: flex-start;
  transition: all .2s cubic-bezier(.17,.67,.83,.67);
  
  @media (max-width: 768px) {
    width: 70%;
    border-radius: 10px;
  }
`;

const FullScreenSideBarLabel = styled(CustomText)`
  font-size: 1.1rem;
  opacity: 0.8;
  margin-bottom: 10px;
  margin-top: 40px;
  
  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 1rem;
  }
`;

const FullScreenSideBarTag = styled(CustomText)`
  font-size: 0.9rem;
  opacity: 0.6;
  position: absolute;
  bottom: 20px;
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const FullScreenSideBarText = styled(CustomText)`
  max-width: 95%;
  font-size: 1.1rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FullScreenSideBarCopyButton = styled.button`
  background: ${props => props.theme.colors.strokeFaded};
  border-radius: 7px;
  padding: 7px 12px;
  border: none;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  font-family: 'Poppins', serif;
  opacity: 0.8;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 90px;
`;


const FullScreenCloseButton = styled.button`
  position: absolute;
  top: 30px;
  left: 30px;
  border: none;
  background-color: transparent;
`;

const FullScreenInfoButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  background-color: transparent;
`;

const InfoIcon = styled(PiInfoBold)`
  color: ${props => props.theme.colors.white};
`;

const InfoIcon2 = styled(PiInfoBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const XIcon = styled(PiXBold)`
  color: ${props => props.theme.colors.white};
`;

const DownloadIcon = styled(PiDownloadSimpleBold)`
  margin-bottom: -2px;
  color: ${props => props.theme.colors.white};
  opacity: 0.8;
  
  ${DownloadButton}:hover & {
    color: ${props => props.theme.colors.dark};
  }
`;

const ImageMessage = ({ message, last, onFeedback, onRetry, error }) => {
    const { theme } = useTheme();
    const { loading, loadingState } = useLoading();
    const image = message.text;
    const prompt = message.revisedPrompt ? message.revisedPrompt : "";
    const numberOfContexts = message.contextUsed ? message.contextUsed.length : 0;
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [showInfoExpanded, setShowInfoExpanded] = useState(false);
    const [isImageExpanded, setIsImageExpanded] = useState(false);
    const [isInfoExpanded, setIsInfoExpanded] = useState(false);

    const toggleImageExpansion = () => {
        if (isInfoExpanded) {
            setIsInfoExpanded(false);
        }
        setIsImageExpanded(!isImageExpanded);
    };

    const downloadImage = async () => {
        try {
            const response = await fetch(image);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            const date = new Date();
            link.download = `OwnAI_Generated_Image_${date.getFullYear()}-${date.getMonth()}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.jpg`;
            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(url); // Clean up the object URL
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    const handleOpenInfo = (e) => {
        e.stopPropagation();
        setIsInfoExpanded(!isInfoExpanded);
    }

    const handleInfoClick = () => {
        if (numberOfContexts > 0) {
            setShowInfoExpanded(!showInfoExpanded);
        }
    }

    const renderFullScreenImage = (theme) => (
        <FullScreenImageContainer>
            <FullScreenMainContent>
                <FullScreenCloseButton onClick={toggleImageExpansion}>
                    <XIcon size={30} theme={theme} />
                </FullScreenCloseButton>
                <FullScreenInfoButton onClick={handleOpenInfo}>
                    <InfoIcon size={30} theme={theme} />
                </FullScreenInfoButton>
                <FullScreenImage src={image} alt="Expanded" />
            </FullScreenMainContent>
            <FullScreenSideBar theme={theme} isInfoExpanded={isInfoExpanded}>
                <FullScreenSideBarLabel type="medium">
                    Prompt
                </FullScreenSideBarLabel>
                <FullScreenSideBarText>
                    {prompt}
                </FullScreenSideBarText>
                <FullScreenSideBarCopyButton theme={theme} onClick={() => navigator.clipboard.writeText(prompt)}>
                    <ClipboardIcon size={20} theme={theme} />
                    Copy
                </FullScreenSideBarCopyButton>
                <FullScreenSideBarTag type="medium">
                    Generated by DALL•E 3
                </FullScreenSideBarTag>
            </FullScreenSideBar>
        </FullScreenImageContainer>
    );

    return (
        <MessageContainer theme={theme}>
            <InnerContainer>
                <ImageContainer src={image} alt="Generated Image" onClick={toggleImageExpansion} />
                <DownloadButton theme={theme} onClick={downloadImage}>
                    <DownloadIcon size={22} theme={theme} />
                </DownloadButton>
            </InnerContainer>
            {!error &&
                <ButtonsContainerBottom>
                    <IconArea
                        onMouseEnter={() => setShowInfoPopup(true)}
                        onMouseLeave={() => setShowInfoPopup(false)}
                        onClick={handleInfoClick}
                    >
                        <InfoIconPopup hidden={!showInfoPopup} theme={theme} contextUsed={numberOfContexts > 0}>
                            <CustomText theme={theme} type="small">{numberOfContexts} context{numberOfContexts > 1 ? "s" : ""} used</CustomText>
                            {numberOfContexts > 0 && <CustomText theme={theme} type="small" style={{fontSize: "0.8rem"}}>Click to view</CustomText>}
                        </InfoIconPopup>
                        <InfoIcon2 theme={theme} size={20} />
                    </IconArea>
                    <MessageButton
                        onClick={() => onFeedback("good")}
                        icon={<ThumbsUpIcon theme={theme} size={20} />}
                        infoText="Good response"
                    />
                    <MessageButton
                        onClick={() => onFeedback("bad")}
                        icon={<ThumbsDownIcon theme={theme} size={20} />}
                        infoText="Bad response"
                    />
                    {last &&
                        <MessageButton
                            onClick={onRetry}
                            icon={<RetryIcon theme={theme} size={20} />}
                            infoText="Regenerate"
                        />
                    }
                </ButtonsContainerBottom>
            }
            {isImageExpanded && renderFullScreenImage(theme)}
            {last && loading && <BouncingCircles text={loadingState}/>}
        </MessageContainer>
    );
};

export default ImageMessage;
