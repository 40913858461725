import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styled from 'styled-components';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.lightBorder};
  padding: 10px;
  margin-left: -10px;
 `;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  align-self: center;
  color: ${(props) => props.theme.colors.text};
  font-size: 1.1em;
  font-family: 'Poppins', serif;
  font-weight: 500;
  resize: none;
  overflow: hidden;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  margin-left: -40px;
  cursor: pointer;
`;

const CustomRegularInput = ({ type, name, value, onChange, onKeyPress, placeholder, style, secure, toggleSecure }) => {
    const { theme } = useTheme();

    const handleChange = (event) => {
        if (type === 'number') {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <InputContainer theme={theme}>
            <Input
                type={secure ? 'password' : 'text'}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyPress}
                placeholder={placeholder}
                theme={theme}
                style={style}
            />
            {type === 'password' && (
                <Icon onClick={toggleSecure}>
                    {secure ? <PiEyeSlashBold /> : <PiEyeBold />}
                </Icon>
            )}
        </InputContainer>
    );
};

export default CustomRegularInput;