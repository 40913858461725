import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styled, { css } from 'styled-components';
import googleLogo from '../../assets/images/logos/google.png';

const ButtonStyles = {
    fadedSmall: css`
        background-color: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.colors.primary};
        transition: background-color 0.3s, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        padding: 0px;
        font-size: 0.8em;
        border-radius: 12px;
        
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
    `,
  faded: css`
    background-color: ${(props) => props.theme.colors.primaryFaded};
    color: ${(props) => props.theme.colors.primary};
    transition: background-color 0.3s, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    
    &:hover {
        background-color: transparent;
        box-shadow: none;
    }
  `,
  large: css`
    padding: 14px 28px;
    font-size: 1.2em;
    border-radius: 14px;
  `,
  small: css`
    padding: 8px 16px;
    font-size: 0.8em;
    border-radius: 12px;
  `,
  circular: css`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  circularSmall: css`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  rounded: css`
      border-radius: 50px;
      padding: 12px 18px;
      font-size: 1em;
  `,
  roundedLarge: css`
      border-radius: 30px;
      padding: 10px 18px;
      font-size: 1em;
  `,
};

const Button = styled.button`
  border: none;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-family: 'PoppinsMedium', serif;
  font-size: 1em;
  padding: 12px 25px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.2);
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primaryLight};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  }
  
  ${(props) => props.type && ButtonStyles[props.type]}
`;

const GoogleButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-family: 'PoppinsMedium', serif;
  font-size: 17px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
  }
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const CustomButton = ({ children, onClick, type, isGoogleButton, disabled, style, ...props }) => {
    const { theme } = useTheme();

    const ButtonComponent = isGoogleButton ? GoogleButton : Button;

    return (
        <ButtonComponent onClick={onClick} theme={theme} type={type} style={style} {...props} disabled={disabled}>
            {isGoogleButton && <GoogleLogo src={googleLogo} alt="Google" style={{ marginRight: '10px' }} />}
            {children}
        </ButtonComponent>
    );
};

export default CustomButton;