import React, {useState} from 'react';
import styled, {keyframes} from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "./CustomText";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const flashAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
`;

const ConnectionStatusPopup = styled.div`
    position: absolute;
    top: -60px;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.sidebarBackground};
    border-radius: 10px;
    padding: 10px;
    z-index: 1;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for popup */
`;

const ConnectionStatus = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.isConnected ? props.theme.colors.green : props.theme.colors.red};
    box-shadow: 0 0 8px ${(props) => props.isConnected ? props.theme.colors.green : props.theme.colors.red};
    animation: ${flashAnimation} 2s infinite; /* Flashing effect */
    opacity: 0.8;
`;

const ConnectionStatusText = styled(CustomText)`
    font-size: 16px;
`;

const StatusIndicator = ({ isConnected }) => {
    const { theme } = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    if (isConnected) {
        return null;
    }

    return (
        <Container>
            <ConnectionStatus
                isConnected={isConnected}
                theme={theme}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            <ConnectionStatusPopup theme={theme} style={{ display: isHovered ? 'flex' : 'none', opacity: isHovered ? 1 : 0 }}>
                <ConnectionStatusText theme={theme}>
                    {isConnected ? 'Connected' : 'Disconnected'}
                </ConnectionStatusText>
            </ConnectionStatusPopup>
        </Container>
    );
};

export default StatusIndicator;
