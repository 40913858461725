import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import CustomText from "../atoms/CustomText";
import CustomButton from "../atoms/CustomButton";
import CustomInput from "../atoms/CustomInput";
import { useTheme } from "../../contexts/ThemeContext";
import { createNode, getProfile } from "../../api/api";
import { useUser } from "../../contexts/UserContext";
import { getTypeColor } from "../../utils/utils";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: 20px 28px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -100px;
  z-index: 100;
  position: relative;
  width: 500px;
  gap: 20px;
`;

const ModalTitle = styled(CustomText)`
  font-size: 1.2rem;
`;

const ModalError = styled(CustomText)`
  font-size: 1rem;
  margin-top: -10px;
  margin-bottom: -5px;
`;

const ModalInputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

const CancelButton = styled(CustomButton)`
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: none;
  color: ${(props) => props.theme.colors.text};
  
  &:hover {
    background-color: ${(props) => props.theme.colors.background};
    box-shadow: none;
    color: ${(props) => props.theme.colors.text};
  }
`;


const AddNodeModal = ({ open, handleClose, setUserData, setTypesCounts, setRandomColors }) => {
    const { theme } = useTheme();
    const { user } = useUser();
    const modalRef = useRef();
    const [error, setError] = useState(null);
    const [nodeType, setNodeType] = useState(null);
    const [nodeContent, setNodeContent] = useState(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClose]);

    const handleSubmit = async () => {
        if (!nodeType || !nodeContent) {
            window.alert('Please fill out all fields.');
            return;
        }

        setError(null);

        const nodeData = {
            userID: user.uid,
            profileID: user.selectedBackendProfileId,
            nodeType: nodeType,
            content: nodeContent
        };

        try {
            const resp = await createNode(nodeData);

            if (resp.success) {
                window.location.reload();
                handleClose();
            } else {
                switch (resp.error) {
                    case "Content exceeds token limit":
                        setError("Content exceeds token limit");
                        break;
                    default:
                        setError("There was an error creating the node. Please try again later.");
                        break;
                }
            }
        } catch (error) {
            // console.erro /r('Error creating node:', error);
        }
    }

    if (!open) {
        return null;
    }

    return (
        <ModalBackdrop>
            <ModalContainer ref={modalRef} theme={theme}>
                <ModalTitle>
                    Create a new context
                </ModalTitle>
                {error && <ModalError theme={theme} style={{ color: theme.colors.red }}>
                    {error}
                </ModalError>}
                <ModalInputRow>
                    <CustomInput
                        placeholder="Type"
                        value={nodeType}
                        onChange={(e) => setNodeType(e.target.value)}
                    />
                </ModalInputRow>
                <ModalInputRow>
                    <CustomInput
                        placeholder="Content"
                        minRows={3}
                        value={nodeContent}
                        onChange={(e) => setNodeContent(e.target.value)}
                    />
                </ModalInputRow>
                <ModalButtons>
                    <CancelButton onClick={handleClose} type="small" theme={theme}>
                        Cancel
                    </CancelButton>
                    <CustomButton onClick={handleSubmit} type="small">
                        Add
                    </CustomButton>
                </ModalButtons>
            </ModalContainer>
        </ModalBackdrop>
    );
};

export default AddNodeModal;
