import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";

const ExampleContainer = styled.div`
  transform: translateY(${props => props.isVisible ? 0 : 40}px);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: opacity, transform;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 65%;
  background-color: ${props => props.theme.colors.background};
  border: .5px solid ${props => props.theme.colors.strokeFade};
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
  border-radius: 10px;
  
  @media (max-width: 1440px) {
    width: 80%;
  }
  
  @media (max-width: 1024px) {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.background};
  }
`;

const ExampleContainerSideBySide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 49%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  padding: 10px 20px;
  //border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 0;
  }
`;

const ExampleContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  
  @media (max-width: 768px) {
    padding: 20px;
    gap: 0;
  }
`;

const ExampleImage = styled.img`
  width: 70%;
  margin: 40px 0;
  height: auto;
  border-radius: 10px;
  
  @media (max-width: 1024px) {
    width: 70%;
  }
  
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const ExampleTitle = styled(CustomText)`
  color: ${props => props.theme.colors.text};
  font-size: 2.3em;
  max-width: 95%;
  
  @media (max-width: 1440px) {
    font-size: 2em;
  }
  
  @media (max-width: 1024px) {
    font-size: 1.8em;
  }
  
  @media (max-width: 768px) {
    font-size: 1.6em;
    padding-left: 10px;
    margin-top: 0;
  }
`;

const ExampleDescription = styled(CustomText)`
  color: ${props => props.theme.colors.text};
  text-align: left;
  max-width: 95%;
  font-size: 1.3em;
  
  @media (max-width: 1440px) {
    font-size: 1.1em;
  }
  
  @media (max-width: 1024px) {
    font-size: 1em;
  }
  
  @media (max-width: 768px) {
    font-size: 1em;
    padding-left: 10px;
  }
`;

const TailoredTag = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${props => props.theme.colors.primaryFade};
  //border: 1px solid ${props => props.theme.colors.primaryFade};
  color: ${props => props.theme.colors.text};
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px;
`;

const ExampleComponent = ({ image, title, tag, description, color, colorFade }) => {
    const { theme } = useTheme();
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    return (
        <ExampleContainer ref={ref} isVisible={isVisible} theme={theme}>
            <ExampleContainerSideBySide theme={theme} style={{ backgroundColor: colorFade }}>
                <ExampleImage src={image} alt={title} />
            </ExampleContainerSideBySide>
            <ExampleContainerSideBySide theme={theme}>
                <ExampleContainerInner>
                    <TailoredTag theme={theme} style={{ backgroundColor: colorFade, borderColor: colorFade }}>
                        <CustomText type="medium" style={{ color: color }}>
                            {tag}
                        </CustomText>
                    </TailoredTag>
                    <ExampleTitle theme={theme} type="bold">{title}</ExampleTitle>
                    <ExampleDescription theme={theme} type="regular">{description}</ExampleDescription>
                </ExampleContainerInner>
            </ExampleContainerSideBySide>
        </ExampleContainer>
    );
};

export default ExampleComponent;