import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTheme} from '../../contexts/ThemeContext';
import {useUser} from "../../contexts/UserContext";
import CustomText from "../atoms/CustomText";
import CustomButton from "../atoms/CustomButton";
import {PiCheckCircleDuotone, PiMinusBold, PiPlusBold} from "react-icons/pi";

const CheckoutCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  z-index: 1;
  border-radius: 20px;
  background-color: ${props => props.mostPopular ? props.theme.colors.primaryFaded : props.theme.colors.background};
  position: relative;

  @media (max-width: 800px) {
    flex-direction: row;
    height: 100%;
  }
`;

const CheckoutCardInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 1;
  border-radius: 20px;
  background-color: transparent;
  min-width: 300px;
  max-width: 300px;
  position: relative;
  gap: 20px;
  
  @media (max-width: 800px) {
    min-width: 250px;
    max-width: 250px;
    margin: 0 10px;
  }
`;

const CheckoutCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

const CheckoutCardTitle = styled(CustomText)`
  font-size: 1.3rem;
  text-align: center;
`;

const CheckoutCardSubtitle = styled(CustomText)`
  opacity: 0.6;
  font-size: 1em;
`;

const CheckoutCardSubtext = styled(CustomText)`
  opacity: 0.6;
  font-size: 1.1em;
`;

const CheckoutCardDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.stroke};
  margin: 5px 0;
`;

const CheckoutCardPriceSubtext = styled(CustomText)`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.3rem;
  text-align: center;
  gap: 3px;
`;

const CheckoutCardPrice = styled(CustomText)`
    font-size: 2.3rem;
    text-align: center;
`;

const CheckoutCardColoredTag = styled.div`
  height: 25px;
  background-color: ${props => props.theme.colors.primaryFade};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CheckoutCardTag = styled.div`
  height: 25px;
  background-color: ${props => props.theme.colors.background};
  border: 2px solid ${props => props.theme.colors.stroke};
  padding: 0 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

const CheckoutCardCheckIcon = styled(PiCheckCircleDuotone)`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
`;

const CheckoutCardPlusIcon = styled(PiPlusBold)`
  color: ${props => props.theme.colors.green};
  background-color: ${props => props.theme.colors.greenFade};
  border-radius: 10px;
  padding: 10px 10px;

  &:hover {
    color: ${props => props.theme.colors.green};
    transform: scale(1.05);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:active {
    transform: scale(0.97);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const CheckoutCardMinusIcon = styled(PiMinusBold)`
  color: ${props => props.theme.colors.red};
  background-color: ${props => props.theme.colors.redFade};
  border-radius: 10px;
  padding: 10px 10px;
  
  &:hover {
    color: ${props => props.theme.colors.red};
    transform: scale(1.05);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  &:active {
    transform: scale(0.97);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const CheckoutButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const CheckoutCardFeatureList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

const CheckoutCardFeature = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const CheckoutCardSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
`;

const CheckoutCardSliderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const CheckoutCardSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  border-radius: 5px;  
  background: ${props => props.theme.colors.primaryFade};
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 20px;
    border-radius: 6px; 
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    width: 17px;
    height: 20px;
    border-radius: 6px;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`;

const CheckoutCard = ({ title, description, style, features, monthly, onCreateCheckoutSession }) => {
    const { user } = useUser();
    const { theme } = useTheme();

    const tokenAmounts = [5000, 10000, 20000, 30000, 40000, 50000, 75000, 100000];
    const monthlyPrices = monthly ? [10, 20, 39, 57, 75, 92, 136, 180] : [8, 16, 31, 46, 61, 75, 111, 147];
    const yearlyPrices = [96, 192, 372, 552, 732, 900, 1332, 1764];

    const [currentPrice, setCurrentPrice] = useState(monthlyPrices[0]);
    const [currentAnnualPrice, setCurrentAnnualPrice] = useState(yearlyPrices[0]);
    const [currentTokenAmount, setCurrentTokenAmount] = useState(tokenAmounts[0]);

    useEffect(() => {
        const priceIndex = tokenAmounts.indexOf(currentTokenAmount);
        setCurrentPrice(monthlyPrices[priceIndex]);
        setCurrentAnnualPrice(yearlyPrices[priceIndex]);
    }, [currentTokenAmount]);

    const styleAmount = (amount) => {
        // add commas to number
        let amountString = amount.toString();
        let amountArray = amountString.split('');
        let newAmountArray = [];
        let counter = 0;
        for (let i = amountArray.length - 1; i >= 0; i--) {
            if (counter === 3) {
                newAmountArray.push(',');
                counter = 0;
            }
            newAmountArray.push(amountArray[i]);
            counter++;
        }
        newAmountArray = newAmountArray.reverse();
        return newAmountArray.join('');
    }

    const incrementTokenAmount = (e) => {
        e.preventDefault();
        const currentIndex = tokenAmounts.indexOf(currentTokenAmount);
        if (currentIndex < tokenAmounts.length - 1) {
            setCurrentTokenAmount(tokenAmounts[currentIndex + 1]);
        }
    };

    const decrementTokenAmount = (e) => {
        e.preventDefault();
        const currentIndex = tokenAmounts.indexOf(currentTokenAmount);
        if (currentIndex > 0) {
            setCurrentTokenAmount(tokenAmounts[currentIndex - 1]);
        }
    };

    const findClosestTokenAmount = (value) => {
        return tokenAmounts.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
    };

    const handleQuantityChange = (e) => {
        const closestTokenAmount = findClosestTokenAmount(Number(e.target.value));
        const priceIndex = tokenAmounts.indexOf(closestTokenAmount);
        setCurrentTokenAmount(closestTokenAmount);
        setCurrentPrice(monthlyPrices[priceIndex]);
        setCurrentAnnualPrice(yearlyPrices[priceIndex]);
    };

    const handleCreateCheckoutSession = async () => {
        const planType = monthly ? 'm' : 'y';
        const planTitle = currentTokenAmount.toString() + planType;
        if (onCreateCheckoutSession) {
            const resp = await onCreateCheckoutSession(user.uid, user.email, planTitle, user.stripeId);
            if (resp.success) {
                window.location.href = resp.url;
            }
        }
    };

    return (
        <CheckoutCardContainer style={style} theme={theme}>
            <CheckoutCardInnerContainer>
                <CheckoutCardHeader>
                    <CheckoutCardTitle type="semiBold">{title}</CheckoutCardTitle>
                    <CheckoutCardSubtitle type="medium">{description}</CheckoutCardSubtitle>
                    {/*<CheckoutCardDivider theme={theme}/>*/}
                </CheckoutCardHeader>

                <CheckoutCardPriceSubtext type="semiBold">
                    $<CheckoutCardPrice type="bold">{currentPrice}</CheckoutCardPrice>/mo
                </CheckoutCardPriceSubtext>

                <CheckoutCardSliderContainer>
                    <CheckoutCardSlider
                        type="range"
                        min={tokenAmounts[0]}
                        max={tokenAmounts[tokenAmounts.length - 1]}
                        value={currentTokenAmount}
                        onChange={handleQuantityChange}
                        step="1"
                        theme={theme}
                    />
                    <CheckoutCardSliderButtonContainer>
                        <CheckoutCardMinusIcon
                            onClick={decrementTokenAmount}
                            theme={theme}
                            size={18}
                        />
                        <CheckoutCardSubtext type="semiBold">{styleAmount(currentTokenAmount)} tokens/mo</CheckoutCardSubtext>
                        <CheckoutCardPlusIcon
                            onClick={incrementTokenAmount}
                            theme={theme}
                            size={18}
                        />
                    </CheckoutCardSliderButtonContainer>
                </CheckoutCardSliderContainer>

                <CheckoutButtons>
                    <CustomButton
                        onClick={handleCreateCheckoutSession}
                    >
                        Get Started
                    </CustomButton>
                    <CheckoutCardTag theme={theme}>
                        <CustomText type="medium" style={{fontSize: '0.8em'}}>Cancel Anytime</CustomText>
                    </CheckoutCardTag>
                    {!monthly && <CustomText type="medium" style={{opacity: 0.7, fontSize: '0.9em'}}>Charged annually ${currentAnnualPrice}</CustomText>}
                </CheckoutButtons>

                <CheckoutCardFeatureList>
                    {features.map((feature, index) => (
                        <CheckoutCardFeature key={index}>
                            <CheckoutCardCheckIcon theme={theme}/>
                            <CustomText type="medium">{feature}</CustomText>
                        </CheckoutCardFeature>
                    ))}
                </CheckoutCardFeatureList>
            </CheckoutCardInnerContainer>
        </CheckoutCardContainer>
    );
};

export default CheckoutCard;