import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import { FaChevronRight } from "react-icons/fa6";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 20px);
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${(props) => props.theme.colors.background};
  padding: 10px;
  border-radius: 10px;
`;

const QuestionText = styled(CustomText)`
  font-size: 1.1em;
  
  @media (max-width: 800px) {
    font-size: 1em;
  }
`;

const RightChevron = styled(FaChevronRight)`
  color: ${(props) => props.theme.colors.border};
  transform: ${(props) => props.expanded === "true" ? "rotate(90deg)" : "rotate(0deg)"};
  transition: transform 0.2s ease-in-out;
  margin-right: 10px;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  // background: ${(props) => props.theme.colors.strokeFaded};
  padding: 10px;
  border-radius: 10px;
  margin-top: -10px;
`;

const AnswerText = styled(CustomText)`
  font-size: 1.1em;
  text-align: left;
  opacity: 0.8;
  
  @media (max-width: 800px) {
    font-size: 1em;
  }
`;

const FAQ = ({ question, answer }) => {
    const { theme } = useTheme();
    const [expanded, setExpanded] = useState(false);

    return (
        <OuterContainer onClick={() => setExpanded(!expanded)} theme={theme}>
            <QuestionContainer theme={theme}>
                <QuestionText type="medium">{question}</QuestionText>
                <RightChevron size={window.innerWidth < 768 ? 16 : 20} theme={theme} expanded={expanded.toString()} />
            </QuestionContainer>
            {expanded && (
                <AnswerContainer theme={theme}>
                    <AnswerText>{answer}</AnswerText>
                </AnswerContainer>
            )}
        </OuterContainer>
    );
}

export default FAQ;