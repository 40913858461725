import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import '../../assets/fonts/fonts.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark, tomorrow } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { FiCopy } from "react-icons/fi";
import {MathJax, MathJaxContext} from 'better-react-mathjax';

const CodeBlock = styled.code`
  background-color: ${(props) => props.theme.colors.primaryFaded};
  color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.primaryFade};
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 0.9em;
  font-family: monospace;
`;

const SyntaxHighlighterContainer = styled.div`
  position: relative;
  font-size: 0.9em;
`;

const CopyButton = styled.button`
  position: absolute;
  top: 0;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  font-size: 0.8em;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stroke};
`;

const CopyIcon = styled(FiCopy)`
  font-size: 1.2em;
  color: ${(props) => props.theme.colors.stroke};
`;

const Link = styled.a`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 500;
    &:hover {
        text-decoration: underline;
    }
`;

const CustomText = ({ type = 'regular', style, children, ...props }) => {
    const { theme } = useTheme();

    const fontStyles = {
        regular: { fontFamily: 'Poppins', fontWeight: 400 },
        bold: { fontFamily: 'Poppins', fontWeight: 700 },
        italic: { fontFamily: 'Poppins', fontStyle: 'italic' },
        light: { fontFamily: 'Poppins', fontWeight: 300 },
        medium: { fontFamily: 'PoppinsMedium', fontWeight: 500 },
        semiBold: { fontFamily: 'Poppins', fontWeight: 600 },
        black: { fontFamily: 'Poppins', fontWeight: 900 },
    };

    const combinedStyle = { ...fontStyles[type], color: theme.colors.text, ...style };

    const [copyText, setCopyText] = useState("Copy Text");

    const copyToClipboard = useCallback((text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyText("Copied!");
            setTimeout(() => setCopyText("Copy Text"), 1000);
        }, (err) => {
            console.error("Failed to copy text to clipboard", err);
        });
    }, []);

    const parseText = (text) => {
        const codeBlockRegex = /(```[\s\S]*?```)/;

        // First, split the text based on multiline code blocks
        const parts = text.split(codeBlockRegex);

        return parts.map((part, index) => {
            if (part.startsWith('```') && part.endsWith('```')) {
                // Multiline code block
                const match = part.match(/```(\w+)?\s([\s\S]*)```/);
                const language = match[1] || 'javascript';
                const code = match[2].trim();

                return (
                    <SyntaxHighlighterContainer key={index}>
                        <SyntaxHighlighter language={language} style={theme.name === "light" ? tomorrow : coldarkDark} customStyle={{ borderRadius: '20px' }}>
                            {code}
                        </SyntaxHighlighter>
                        <CopyButton onClick={() => copyToClipboard(code)} theme={theme}>
                            <CopyIcon theme={theme}/> {copyText}
                        </CopyButton>
                    </SyntaxHighlighterContainer>
                );
            } else {
                return processNonCodeText(part, index);
            }
        }).flat();
    };

    const processNonCodeText = (text, index) => {
        const latexPattern = /(\$\$[\s\S]*?\$\$|\$[^\$]*\$|\\\([\s\S]*?\\\))/g;
        const newLinePattern = /(\n)/g;

        return text.split(newLinePattern).map((line, lineIndex) => {
            if (line === '\n') {
                return <br key={`${index}-${lineIndex}`}/>;
            } else {
                return line.split(latexPattern).map((fragment, fragmentIndex) => {
                    if (latexPattern.test(fragment)) {
                        return (
                            <MathJax key={`${index}-${lineIndex}-${fragmentIndex}`}>
                                <span>{fragment}</span>
                            </MathJax>
                        );
                    } else {
                        return processStyledText(fragment, `${index}-${lineIndex}-${fragmentIndex}`);
                    }
                }).flat();
            }
        }).flat();
    };

    const processStyledText = (text, index) => {
        const inlineCodeRegex = /(`[^`]*`)/g;
        const specialBoldPattern = /(\d+\.\s*\*\*(.*?)\*\*:\s*)/g;
        const boldItalicsRegex = /\*\*\*(.*?)\*\*\*|\*\*(.*?)\*\*|\*(.*?)\*/g;
        const titlePattern = /###\s(.*?)(\n|$)/g;
        const numberPattern = /(\d+\.\s)/;
        const markdownLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

        return text.split(markdownLinkRegex).map((fragment, fragmentIndex) => {
            // Every third element in the array is a URL due to how split works with capturing groups
            if ((fragmentIndex - 1) % 3 === 0) { // Link text
                const linkText = fragment;
                const linkUrl = text.split(markdownLinkRegex)[fragmentIndex];
                return <Link href={linkUrl} key={`${index}-${fragmentIndex}`} target="_blank" theme={theme}
                          rel="noopener noreferrer">{linkText}</Link>;
            } else if ((fragmentIndex - 2) % 3 === 0) { // Skip URL parts for text processing
                return null;
            } else {
                return fragment.split(titlePattern).map((titleFragment, titleIndex) => {
                    if (titleIndex % 2 === 1) {
                        // Title text
                        return (
                            <span key={`${index}-${titleIndex}`}
                                  style={{...fontStyles.bold, textTransform: 'capitalize'}}>
                            {titleFragment}
                        </span>
                        );
                    } else {
                        // Process inline code, bold, italic, and other text within this part
                        return titleFragment.split(inlineCodeRegex).map((innerPart, innerIndex) => {
                            if (innerPart.startsWith('`') && innerPart.endsWith('`')) {
                                // Inline code
                                const code = innerPart.slice(1, -1);
                                return <CodeBlock key={`${index}-${innerIndex}`}
                                                  theme={theme}>{code}</CodeBlock>;
                            } else {
                                return innerPart.split(numberPattern).map((innerFragment, numIndex) => {
                                    if (numIndex % 2 === 1) {
                                        // Insert line breaks for numbers followed by a period
                                        return (
                                            <React.Fragment key={`${index}-${innerIndex}-${numIndex}`}>
                                                {/*<br/>*/}
                                                {innerFragment}
                                            </React.Fragment>
                                        );
                                    } else {
                                        // Process other styles
                                        return innerFragment.split(specialBoldPattern).map((part, specialBoldIndex) => {
                                            if (specialBoldIndex % 2 === 0) {
                                                // Non-bold text or text before the bold pattern
                                                // Split by number pattern to handle numbered lists
                                                return part.split(numberPattern).map((numberFragment, numberIndex) => {
                                                    if (numberIndex % 2 === 0) {
                                                        // Regular text
                                                        return numberFragment.split(boldItalicsRegex).map((subFragment, fragmentIndex) => {
                                                            if (fragmentIndex % 4 === 1) {
                                                                // Bold and italic
                                                                return <span
                                                                    key={`${index}-${innerIndex}-${specialBoldIndex}-${fragmentIndex}`}
                                                                    style={{...fontStyles.semiBold, ...fontStyles.italic}}>{subFragment}</span>;
                                                            } else if (fragmentIndex % 4 === 2) {
                                                                // Bold
                                                                return <span
                                                                    key={`${index}-${innerIndex}-${specialBoldIndex}-${fragmentIndex}`}
                                                                    style={fontStyles.semiBold}>{subFragment}</span>;
                                                            } else if (fragmentIndex % 4 === 3) {
                                                                // Italic
                                                                return <span
                                                                    key={`${index}-${innerIndex}-${specialBoldIndex}-${fragmentIndex}`}
                                                                    style={fontStyles.italic}>{subFragment}</span>;
                                                            } else {
                                                                // Regular text
                                                                return subFragment;
                                                            }
                                                        }).flat();
                                                    } else {
                                                        // Numbered list item
                                                        return <React.Fragment
                                                            key={`${index}-${innerIndex}-${specialBoldIndex}-${numberIndex}`}>
                                                            <br/>
                                                            {numberFragment}
                                                        </React.Fragment>;
                                                    }
                                                });
                                            } else {
                                                return <div
                                                    key={`${index}-${innerIndex}-${specialBoldIndex}`}
                                                    style={{...fontStyles.bold, display: 'block'}}>
                                                    {fragment.replace(/\*\*(.*?)\*\*/, "$1")}
                                                </div>;
                                            }
                                        }).flat();
                                    }
                                }).flat();
                            }
                        }).flat();
                    }
                }).flat();
            }
        }).flat();
    }

    return (
        <MathJaxContext>
            <div style={combinedStyle} {...props}>
                {typeof children === 'string' ? parseText(children) : children}
            </div>
        </MathJaxContext>
    );
};

export default CustomText;