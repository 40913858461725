import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Sidebar from '../components/organisms/Sidebar';
import { useTheme } from "../contexts/ThemeContext";
import { useUser } from "../contexts/UserContext";
import SettingToggleButtons from "../components/molecules/SettingToggleButton";
import CustomText from "../components/atoms/CustomText";
import CustomButton from "../components/atoms/CustomButton";
import {
    PiBrainBold, PiCalendarBlankBold, PiChartPolarBold, PiNewspaperBold,
    PiChatCircleBold, PiGearBold, PiMagnifyingGlassBold, PiBackpackBold,
    PiPathBold, PiQuotesBold, PiRepeatBold, PiSmileyBold, PiSmileyMehBold,
    PiBellBold, PiBookBookmarkBold, PiChairBold
} from "react-icons/pi";
import { TbPodium } from "react-icons/tb";
import { LuLanguages } from "react-icons/lu";
import { toggleUserToggle } from "../services/firebaseFirestore";
import FeedbackModal from "../components/organisms/FeedbackModal";
import ProfileSelector from "../components/organisms/ProfileSelector";
import SubscribeModal from "../components/organisms/SubscribeModal";

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${(props) => props.theme.colors.background};
`;

const CenteredContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  margin-top: 70px;
  padding-bottom: 100px;
  
  @media (max-width: 800px) {
    width: 95%;
    margin-top: 60px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 30px;
  
  @media (max-width: 800px) {
    margin-bottom: 30px;
    max-width: 95%;
  }
`;

const SaveButton = styled(CustomButton)`
  margin-top: 25px;
  align-self: flex-end;
`;

const FilterScreen = () => {
    const { theme } = useTheme();
    const { user } = useUser();

    const isClass = user.backendProfiles.find(profile => profile.id === user.selectedBackendProfileId)?.isClass || false;

    const [isExpanded, setIsExpanded] = useState(window.innerWidth > 1200);
    const [showSubscribeModal, setSubscribeModalOpen] = useState(false);

    const [initialToggleStates, setInitialToggleStates] = useState(user.toggles);
    const [initialClassToggleStates, setInitialClassToggleStates] = useState(user.classToggles);
    const [userToggleStates, setUserToggleStates] = useState(user.toggles);
    const [classToggleStates, setClassToggleStates] = useState(user.classToggles);

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => {
        setInitialToggleStates(user.toggles);
        setInitialClassToggleStates(user.classToggles);
        setUserToggleStates(user.toggles);
        setClassToggleStates(user.classToggles);
    }, [user.toggles, user.classToggles, isClass]);

    const handleUserToggleClick = (toggleType) => {
        setUserToggleStates(prevState => {
            const updatedState = { ...prevState, [toggleType]: !prevState[toggleType] };
            const isStateChanged = Object.keys(initialToggleStates).some(key => initialToggleStates[key] !== updatedState[key]);
            setUnsavedChanges(isStateChanged);
            return updatedState;
        });
    }

    const handleClassToggleClick = (toggleType) => {
        setClassToggleStates(prevState => {
            const updatedState = { ...prevState, [toggleType]: !prevState[toggleType] };
            const isStateChanged = Object.keys(initialClassToggleStates).some(key => initialClassToggleStates[key] !== updatedState[key]);
            setUnsavedChanges(isStateChanged);
            return updatedState;
        });
    }

    const classToggles = [
        {
            section: "Class Information",
            toggleText: "Info",
            toggleIcon: <PiMagnifyingGlassBold size={30} />,
            toggled: classToggleStates["Info"],
            onToggleClick: () => handleClassToggleClick("Info")
        },
        {
            section: "Class Information",
            toggleText: "Syllabus",
            toggleIcon: <PiPathBold size={30} />,
            toggled: classToggleStates["Syllabus"],
            onToggleClick: () => handleClassToggleClick("Syllabus")
        },
        {
            section: "Class Information",
            toggleText: "Rules",
            toggleIcon: <PiChartPolarBold size={30} />,
            toggled: classToggleStates["Rules"],
            onToggleClick: () => handleClassToggleClick("Rules")
        },
        {
            section: "Class Resources",
            toggleText: "Teacher Profile",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: classToggleStates["Teacher Profile"],
            onToggleClick: () => handleClassToggleClick("Teacher Profile")
        },
        {
            section: "Class Content",
            toggleText: "Readings",
            toggleIcon: <PiNewspaperBold size={30} />,
            toggled: classToggleStates["Readings"],
            onToggleClick: () => handleClassToggleClick("Readings")
        },
        {
            section: "Class Content",
            toggleText: "Lectures",
            toggleIcon: <TbPodium size={30} />,
            toggled: classToggleStates["Lectures"],
            onToggleClick: () => handleClassToggleClick("Lectures")
        },
        {
            section: "Class Content",
            toggleText: "Assignments",
            toggleIcon: <PiCalendarBlankBold size={30} />,
            toggled: classToggleStates["Assignments"],
            onToggleClick: () => handleClassToggleClick("Assignments")
        },
        {
            section: "Class Resources",
            toggleText: "Educational Resources",
            toggleIcon: <PiBackpackBold size={30} />,
            toggled: classToggleStates["Educational Resources"],
            onToggleClick: () => handleClassToggleClick("Educational Resources")
        },
        {
            section: "Class Resources",
            toggleText: "Learning Objectives",
            toggleIcon: <PiMagnifyingGlassBold size={30} />,
            toggled: classToggleStates["Learning Objectives"],
            onToggleClick: () => handleClassToggleClick("Learning Objectives")
        },
        {
            section: "Class Content",
            toggleText: "Interactions",
            toggleIcon: <PiChairBold size={30} />,
            toggled: classToggleStates["Interactions"],
            onToggleClick: () => handleClassToggleClick("Interactions")
        },
        {
            section: "Class Content",
            toggleText: "Context",
            toggleIcon: <PiBookBookmarkBold size={30} />,
            toggled: classToggleStates["Context"],
            onToggleClick: () => handleClassToggleClick("Context")
        },
        {
            section: "Class Content",
            toggleText: "Announcements",
            toggleIcon: <PiBellBold size={30} />,
            toggled: classToggleStates["Announcements"],
            onToggleClick: () => handleClassToggleClick("Announcements")
        },
    ];

    const userToggles = [
        {
            section: "Personal Traits",
            toggleText: "Traits",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Traits"],
            onToggleClick: () => handleUserToggleClick("Traits")
        },
        {
            section: "Personal Traits",
            toggleText: "Interests",
            toggleIcon: <PiPathBold size={30} />,
            toggled: userToggleStates["Interests"],
            onToggleClick: () => handleUserToggleClick("Interests")
        },
        {
            section: "Personal Traits",
            toggleText: "Hobbies",
            toggleIcon: <PiPathBold size={30} />,
            toggled: userToggleStates["Hobbies"],
            onToggleClick: () => handleUserToggleClick("Hobbies")
        },
        {
            section: "Personal Traits",
            toggleText: "Quotes",
            toggleIcon: <PiQuotesBold size={30} />,
            toggled: userToggleStates["Quotes"],
            onToggleClick: () => handleUserToggleClick("Quotes")
        },
        {
            section: "Personal Traits",
            toggleText: "Understandings",
            toggleIcon: <PiChatCircleBold size={30} />,
            toggled: userToggleStates["Understandings"],
            onToggleClick: () => handleUserToggleClick("Understandings")
        },
        {
            section: "Cognitive and Emotional",
            toggleText: "Language Patterns",
            toggleIcon: <LuLanguages size={30} />,
            toggled: userToggleStates["Language Patterns"],
            onToggleClick: () => handleUserToggleClick("Language Patterns")
        },
        {
            section: "Cognitive and Emotional",
            toggleText: "Emotional States",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Emotional States"],
            onToggleClick: () => handleUserToggleClick("Emotional States")
        },
        {
            section: "Cognitive and Emotional",
            toggleText: "Cognitive Biases",
            toggleIcon: <PiSmileyMehBold size={30} />,
            toggled: userToggleStates["Cognitive Biases"],
            onToggleClick: () => handleUserToggleClick("Cognitive Biases")
        },
        {
            section: "Cognitive and Emotional",
            toggleText: "Learning Styles",
            toggleIcon: <PiBrainBold size={30} />,
            toggled: userToggleStates["Learning Styles"],
            onToggleClick: () => handleUserToggleClick("Learning Styles")
        },
        {
            section: "Cognitive and Emotional",
            toggleText: "Social Dynamics",
            toggleIcon: <PiChatCircleBold size={30} />,
            toggled: userToggleStates["Social Dynamics"],
            onToggleClick: () => handleUserToggleClick("Social Dynamics")
        },
        {
            section: "Preferences and Values",
            toggleText: "Goals",
            toggleIcon: <PiPathBold size={30} />,
            toggled: userToggleStates["Goals"],
            onToggleClick: () => handleUserToggleClick("Goals")
        },
        {
            section: "Preferences and Values",
            toggleText: "Preferences",
            toggleIcon: <PiGearBold size={30} />,
            toggled: userToggleStates["Preferences"],
            onToggleClick: () => handleUserToggleClick("Preferences")
        },
        {
            section: "Preferences and Values",
            toggleText: "Behavioural Patterns",
            toggleIcon: <PiRepeatBold size={30} />,
            toggled: userToggleStates["Behavioural Patterns"],
            onToggleClick: () => handleUserToggleClick("Behavioural Patterns")
        },
        {
            section: "Preferences and Values",
            toggleText: "Value Systems",
            toggleIcon: <PiChartPolarBold size={30} />,
            toggled: userToggleStates["Value Systems"],
            onToggleClick: () => handleUserToggleClick("Value Systems")
        },
        {
            section: "Preferences and Values",
            toggleText: "Morals",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Morals"],
            onToggleClick: () => handleUserToggleClick("Morals")
        },
        {
            section: "Preferences and Values",
            toggleText: "Ethical Beliefs",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Ethical Beliefs"],
            onToggleClick: () => handleUserToggleClick("Ethical Beliefs")
        },
        {
            section: "Memory, Events, and Wellness",
            toggleText: "Events",
            toggleIcon: <PiCalendarBlankBold size={30} />,
            toggled: userToggleStates["Events"],
            onToggleClick: () => handleUserToggleClick("Events")
        },
        {
            section: "Memory, Events, and Wellness",
            toggleText: "Contextual Memories",
            toggleIcon: <PiMagnifyingGlassBold size={30} />,
            toggled: userToggleStates["Contextual Memories"],
            onToggleClick: () => handleUserToggleClick("Contextual Memories")
        },
        {
            section: "Memory, Events, and Wellness",
            toggleText: "Health & Wellness",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Health & Wellness"],
            onToggleClick: () => handleUserToggleClick("Health & Wellness")
        },
        {
            section: "Memory, Events, and Wellness",
            toggleText: "Wellness Activities",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Wellness Activities"],
            onToggleClick: () => handleUserToggleClick("Wellness Activities")
        },
        {
            section: "Memory, Events, and Wellness",
            toggleText: "Wellness Goals",
            toggleIcon: <PiSmileyBold size={30} />,
            toggled: userToggleStates["Wellness Goals"],
            onToggleClick: () => handleUserToggleClick("Wellness Goals")
        }
    ];

    const handleSave = async () => {
        try {
            const togglesToSave = isClass ? classToggleStates : userToggleStates;
            await toggleUserToggle(user.uid, togglesToSave, isClass);
            if (isClass) {
                setInitialClassToggleStates(togglesToSave);
            } else {
                setInitialToggleStates(togglesToSave);
            }
            setUnsavedChanges(false);
        } catch (error) {
            console.error('Error saving toggles:', error);
        }
    };

    return (
        <ScreenContainer theme={theme}>
            <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} setFeedbackModelOpen={setShowFeedbackModal} />
            <CenteredContainer>
                {/*<FeedbackButtonContainer*/}
                {/*    isExpanded={isExpanded}*/}
                {/*>*/}
                {/*    <FeedbackButton onClick={() => setShowFeedbackModal(true)} />*/}
                {/*    <DiscordButton />*/}
                {/*</FeedbackButtonContainer>*/}
                <ProfileSelector setSubscribeModalOpen={setSubscribeModalOpen} />
                <InnerContainer>
                    <InfoContainer theme={theme}>
                        <CustomText type="regular">Adjust what your AI tracks during interactions. Toggle features to your preference and feel free to suggest enhancements. Your privacy settings are always respected.</CustomText>
                    </InfoContainer>
                    {/*{isClass ?*/}
                    {/*    <SettingToggleButtons toggleButtons={classToggles} unsavedChanges={unsavedChanges} />*/}
                    {/*    :*/}
                    {/*    <SettingToggleButtons toggleButtons={userToggles} unsavedChanges={unsavedChanges} />*/}
                    {/*}*/}
                    {/*<SettingToggleButtons toggleButtons={isClass ? classToggles : userToggles} unsavedChanges={unsavedChanges} />*/}
                    <SettingToggleButtons toggleButtons={userToggles} unsavedChanges={unsavedChanges} />
                    {unsavedChanges && <SaveButton onClick={handleSave}>Save Changes</SaveButton>}
                </InnerContainer>
            </CenteredContainer>
            <FeedbackModal open={showFeedbackModal} handleClose={() => setShowFeedbackModal(false)} />
            <SubscribeModal open={showSubscribeModal} handleClose={() => setSubscribeModalOpen(false)} />
        </ScreenContainer>
    );
};

export default FilterScreen;
