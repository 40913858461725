import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from './CustomText';

const LetterIconContainer = styled.div`
  display: flex;
  align-self:  center;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primaryFaded};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => (props.isExpanded ? '15px' : '50%')};
  width: ${(props) => (props.isExpanded ? '100%' : '30px')};
  height: 30px;
  margin: 5px 0;
`;

const LetterIconText = styled(CustomText)`
  font-size: 1.2em;
`;

const LetterIcon = ({ letter, onClick, isExpanded }) => {
    const { theme } = useTheme();

    return (
        <LetterIconContainer theme={theme} onClick={onClick} isExpanded={isExpanded}>
            <LetterIconText type="semiBold" theme={theme} style={{ color: theme.colors.primary }}>
                {letter}
            </LetterIconText>
        </LetterIconContainer>
    );
}

export default LetterIcon;