import React from 'react';
import styled, { keyframes } from 'styled-components';
import CustomText from './CustomText';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const CircleContainer = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 13s linear infinite;
  
  @media (max-width: 800px) {
    width: 70px;
    height: 70px;
  }
`;

const CircularText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Letter = styled(CustomText)`
  position: absolute;
  font-size: 1.2em;
  height: 100%;
  transform-origin: center center;
  
  @media (max-width: 800px) {
    font-size: 1em;
  }
`;

const SpinningCircleText = ({ text }) => {
    const letters = text.split('');
    const degreeIncrement = 360 / letters.length;

    return (
        <CircleContainer>
            <CircularText>
                {letters.map((letter, index) => (
                    <Letter
                        key={index}
                        style={{
                            transform: `rotate(${degreeIncrement * index}deg) translateY(-50%)`,
                        }}
                        type="bold"
                    >
                        {letter}
                    </Letter>
                ))}
            </CircularText>
        </CircleContainer>
    );
};

export default SpinningCircleText;