import React from 'react';
import styled from 'styled-components';
import { PiInfoBold } from "react-icons/pi";
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from "../atoms/CustomText";

const ToolTipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

const InfoIcon = styled(PiInfoBold)`
  color: ${props => props.theme.colors.border};
  margin-right: 5px;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  
  ${ToolTipContainer}:hover & {
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

const ToolTipText = styled(CustomText)`
  max-width: 300px;
  font-size: 1.1em;
  text-align: center;
`;

const ToolTipTextContainer = styled.div`
  display: none;
  width: max-content;
  background-color: ${props => props.theme.colors.sidebarBackground};
  border: 1px solid ${props => props.theme.colors.strokeFade};
  box-shadow: 0 0 5px 0 ${props => props.theme.colors.strokeFade};
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  
  bottom: ${props => props.showBelow ? 'unset' : '100%'};
  top: ${props => props.showBelow ? '100%' : 'unset'};
  transform: translateX(-50%);

  ${ToolTipContainer}:hover & {
    display: block;
  }
`;

const InfoTooltip = ({ text, showBelow = false, size }) => {
    const { theme } = useTheme();

    return (
        <ToolTipContainer>
            <InfoIcon size={size} theme={theme} />
            <ToolTipTextContainer showBelow={showBelow} theme={theme}>
                <ToolTipText theme={theme}>
                    {text}
                </ToolTipText>
            </ToolTipTextContainer>
        </ToolTipContainer>
    );
}

export default InfoTooltip;