import React from 'react';
import Message from '../atoms/Message';
import styled from 'styled-components';
import RadarMessage from "./RadarMessage";
import HumanizeMessage from "./HumanizeMessage";
import EssayMessage from "./EssayMessage";
import ImageMessage from "./ImageMessage";
import ErrorMessage from "./ErrorMessage";

const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-top: 45px;
  padding-bottom: 150px;
  box-sizing: border-box;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  & {
    -ms-overflow-style: none;
  }
  
  &.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
`;

const MessageList = React.forwardRef(({ messages, handleSendEssay, loading, error, retrySendMessage, retrySuccessfulMessage, onFeedback }, ref) => {
    return (
        <MessageListContainer ref={ref} className="hide-scrollbar">
            {messages.map((msg, index) => {
                if (msg.type === "chart" || msg.isChart) {
                    const scores = {...msg.text};
                    delete scores.query;
                    delete scores.summary;

                    return (
                        <RadarMessage
                            title={msg.text.query}
                            summary={msg.text.summary}
                            scores={scores}
                            last={index === messages.length - 1}
                            key={index}
                            error={error}
                        />
                    );
                } else if (msg.type === "humanize") {
                    return (
                        // <HumanizeMessage
                        //     text={msg.text}
                        //     key={index}
                        //     last={index === messages.length - 1}
                        //     onFeedback={onFeedback}
                        //     onRetry={retrySuccessfulMessage}
                        //     error={error}
                        // />
                        <Message
                            message={msg}
                            key={index}
                            last={index === messages.length - 1}
                            onFeedback={onFeedback}
                            onRetry={retrySuccessfulMessage}
                            error={error}
                        />
                    );
                } else if (msg.type === "essay") {
                    return (
                        <EssayMessage
                            response={msg.text}
                            key={index}
                            last={index === messages.length - 1}
                            handleSendEssay={handleSendEssay}
                            error={error}
                        />
                    );
                } else if (msg.type === "image") {
                    return (
                        <ImageMessage
                            message={msg}
                            key={index}
                            last={index === messages.length - 1}
                            onFeedback={onFeedback}
                            onRetry={retrySuccessfulMessage}
                            error={error}
                        />
                    );
                } else {
                    return (
                        <Message
                            message={msg}
                            key={index}
                            last={index === messages.length - 1}
                            onFeedback={onFeedback}
                            onRetry={retrySuccessfulMessage}
                            error={error}
                        />
                    );
                }
            })}
            {error && <ErrorMessage error={error} onRetry={retrySendMessage} onFeedback={onFeedback} />}
        </MessageListContainer>
    );
});

export default MessageList;