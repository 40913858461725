import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import CustomText from "../components/atoms/CustomText";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 55%;
  min-width: 55%;
  margin-top: 40px;
  position: relative;
  padding-bottom: 100px;
  
  @media (max-width: 1200px) {
    max-width: 70%;
    min-width: 70%;
  }
  
  @media (max-width: 800px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const Title = styled(CustomText)`
  font-size: 1.5em;
  margin-bottom: 20px;
  align-self: center;
`;

const Subtitle = styled(CustomText)`
  font-size: 1.1em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 3px;
`;

const DescriptionText = styled(CustomText)`
  font-size: 1.2em;
  opacity: 0.75;
  text-align: left;
`;

const BackButton = styled(IoArrowBack)`
  font-size: 1.7em;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.8;
  
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    opacity: 1;
    transition: transform 0.3s ease-in-out;
  }
  
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
`;

const TermsConditionsScreen = () => {
    const { theme } = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        // scroll to top on load
        window.scrollTo(0, 0);
    }, []);

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <ScreenContainer theme={theme}>
            <BackButton onClick={handleBack} theme={theme} />
            <InnerContainer theme={theme}>
                <Title type="medium">Terms and Conditions</Title>
                <DescriptionText>Welcome to OwnAI, a personalized AI chat service. By using OwnAI, you agree to these terms and conditions.</DescriptionText>

                <Subtitle type="semiBold">Data and Privacy</Subtitle>
                <DescriptionText>OwnAI collects and uses personal information to provide a personalized experience. Your data is not shared with third parties.</DescriptionText>

                <Subtitle type="semiBold">User Conduct</Subtitle>
                <DescriptionText>Users are expected to use OwnAI responsibly and ethically. Prohibited actions include any illegal or harmful behavior.</DescriptionText>

                <Subtitle type="semiBold">Content Ownership</Subtitle>
                <DescriptionText>Content generated through OwnAI is owned by the user, with OwnAI retaining a license to use it for service improvement.</DescriptionText>

                <Subtitle type="semiBold">Liability and Warranty</Subtitle>
                <DescriptionText>OwnAI is provided "as is" without warranties of any kind. OwnAI is not liable for any direct or indirect damages from its use.</DescriptionText>

                <Subtitle type="semiBold">Account Termination</Subtitle>
                <DescriptionText>Users can terminate their accounts and delete their data at any time.</DescriptionText>

                <Subtitle type="semiBold">Subscription and Payments</Subtitle>
                <DescriptionText>OwnAI offers a tiered subscription model. Changes, cancellations, and refunds are subject to our policies.</DescriptionText>

                <Subtitle type="semiBold">Usage Limits</Subtitle>
                <DescriptionText>Usage is tier-based. Free users get 5 messages per day, Pro users get 30, and Unlimited users have no restrictions.</DescriptionText>

                <Subtitle type="semiBold">Service Availability</Subtitle>
                <DescriptionText>OwnAI strives to maintain uptime but there may be occasional downtime.</DescriptionText>

                <Subtitle type="semiBold">Governing Law</Subtitle>
                <DescriptionText>These terms are governed by the laws of Boston, MA.</DescriptionText>

                <DescriptionText>OwnAI reserves the right to modify these terms. Continued use of the service after changes constitutes acceptance of the new terms.</DescriptionText>
            </InnerContainer>
        </ScreenContainer>
    );
};

export default TermsConditionsScreen;