import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { useUser } from "../../contexts/UserContext";
import SidebarItem from "../molecules/SidebarItem";
import SidebarAction from "../molecules/SidebarAction";
import AccountMenu from "../molecules/AccountMenu";
import LetterIcon from "../atoms/LetterIcon";
import { FaBars, FaDiscord} from 'react-icons/fa6';
import {
    PiHouseBold,
    PiGraphBold,
    PiSlidersHorizontalBold,
    PiXBold,
    PiThumbsUpBold,
    PiUserBold
} from 'react-icons/pi';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.isExpanded ? '165px' : '60px')};
  height: 100vh;
  padding: ${(props) => (props.isMobile ? '0' : '10px')};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border-right: 1px solid ${(props) => props.isMobile ? 'transparent' : props.theme.colors.strokeFade};
  transition: width 0.3s, padding 0.3s;
  z-index: 200;
  position: ${(props) => (props.isMobile ? 'absolute' : 'static')};
  left: ${(props) => (props.isMobile && !props.isExpanded ? '-165px' : '0')};

  @media (max-width: 800px) {
    width: ${(props) => (props.isExpanded ? '100%' : '0px')};
    padding: ${(props) => (props.isExpanded ? '10px' : '0')};
    justify-content: ${(props) => (props.isExpanded ? 'space-between' : 'flex-start')};
  }
`;

const SidebarItemsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (max-width: 800px) {
    display: ${(props) => (props.expanded ? 'flex' : 'none')};
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

const MobileOuterExpandButton = styled.button`
  display: none;
  
  @media (max-width: 800px) {
    display: flex;
    position: fixed;
    top: 10px;
    transition: transform 0.3s;
    left: ${(props) => (props.isexpanded ? '' : '10px')};
    right: ${(props) => (props.isexpanded ? '0px' : '')};
    background-color: ${(props) => props.theme.colors.primaryFaded};
    border: none;
    color: ${(props) => props.theme.colors.primary};
    margin: 0 8px 2px 0;
    padding: 8px 8px;
    border-radius: 50px;
    align-self: center;
    text-align: center;
    z-index: 101;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Sidebar = ({ isExpanded, setIsExpanded, setFeedbackModelOpen }) => {
    const { theme } = useTheme();
    const { user } = useUser();
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const isMobile = window.innerWidth < 800;

    const toggleAccountMenu = () => setShowAccountMenu(!showAccountMenu);

    const handleMouseEnter = () => {
        if (window.innerWidth <= 1200) {
            setIsExpanded(true);
        }
    };

    const handleMouseLeave = () => {
        if (window.innerWidth <= 1200) {
            setIsExpanded(false);
        }
    };

    const accountLetter = user?.displayName?.charAt(0).toUpperCase() + user?.displayName?.charAt(1) || 'P';

    const onDiscordClick = () => {
        window.open('https://discord.gg/85bYEEgqXa', '_blank');
    };

    return (
        <SidebarContainer
            theme={theme}
            isexpanded={isExpanded}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isExpanded={isExpanded}
            isMobile={isMobile}
        >
            <MobileOuterExpandButton onClick={() => setIsExpanded(!isExpanded)} theme={theme} isexpanded={isExpanded}>
                {isExpanded ?
                    <PiXBold size={20}/>
                    :
                    <FaBars size={21}/>
                }
            </MobileOuterExpandButton>
            {isMobile ?
                <>
                    <SidebarItemsGroup expanded={isExpanded} style={{ marginTop: '100px' }}>
                        <SidebarItem
                            to="/"
                            icon={PiHouseBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Home
                        </SidebarItem>
                        <SidebarItem
                            to="/profiles"
                            icon={PiGraphBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Profiles
                        </SidebarItem>
                        <SidebarItem
                            to="/account"
                            icon={PiUserBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Account
                        </SidebarItem>
                        <SidebarAction
                            icon={FaDiscord}
                            onClick={onDiscordClick}
                            isExpanded={isExpanded}
                            show
                        >
                            Discord
                        </SidebarAction>
                        <SidebarAction
                            icon={PiThumbsUpBold}
                            onClick={() => setFeedbackModelOpen(true)}
                            isExpanded={isExpanded}
                            show
                        >
                            Feedback
                        </SidebarAction>
                    </SidebarItemsGroup>
                    <SidebarItemsGroup expanded={isExpanded} style={{ marginBottom: '10px' }}>
                        <LetterIcon letter={accountLetter} onClick={toggleAccountMenu} isExpanded={isExpanded} />
                        <AccountMenu
                            show={showAccountMenu}
                            hide={toggleAccountMenu}
                        />
                    </SidebarItemsGroup>
                </>
            :
                <>
                    <SidebarItemsGroup expanded={isExpanded}>
                        <SidebarItem
                            to="/"
                            icon={PiHouseBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Home
                        </SidebarItem>
                        <SidebarItem
                            to="/profiles"
                            icon={PiGraphBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Profiles
                        </SidebarItem>
                    </SidebarItemsGroup>
                    <SidebarItemsGroup expanded={isExpanded}>
                        <SidebarAction
                            icon={FaDiscord}
                            onClick={onDiscordClick}
                            isExpanded={isExpanded}
                            show
                        >
                            Discord
                        </SidebarAction>
                        <SidebarAction
                            icon={PiThumbsUpBold}
                            onClick={() => setFeedbackModelOpen(true)}
                            isExpanded={isExpanded}
                            show
                        >
                            Feedback
                        </SidebarAction>
                        <SidebarItem
                            to="/account"
                            icon={PiUserBold}
                            isExpanded={isExpanded}
                            hover
                        >
                            Account
                        </SidebarItem>
                        <LetterIcon letter={accountLetter} onClick={toggleAccountMenu} isExpanded={isExpanded} />
                        <AccountMenu
                            show={showAccountMenu}
                            hide={toggleAccountMenu}
                        />
                    </SidebarItemsGroup>
                </>
            }
        </SidebarContainer>
    );
};

export default Sidebar;
