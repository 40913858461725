import { auth, db } from './firebaseConfig';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider
} from 'firebase/auth';
import { createDefaultUser } from "./firebaseFirestore";
import { doc, getDoc } from "firebase/firestore";

const googleProvider = new GoogleAuthProvider();

export const signUpWithEmailAndPassword = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        await createDefaultUser(userCredential.user);

        // Sign in the user immediately after sign up
        await loginWithEmailAndPassword(email, password);

        return { success: true, user: userCredential.user };
    } catch (error) {
        console.error('Error signing up:', error.message);
        return { success: false, error: error.message };
    }
}

export const loginWithEmailAndPassword = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        // Initialize new user if they don't exist in the backend
        const usersRef = doc(db, 'users', userCredential.user.uid);
        const usersDoc = await getDoc(usersRef);
        if (!usersDoc.exists()) {
            await createDefaultUser(userCredential.user);
        }

        return { success: true, user: userCredential.user };
    } catch (error) {
        console.error('Error logging in:', error.message);
        return { success: false, error: error.message };
    }

}

export const signInWithGooglePopup = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);

        // Initialize new user if they don't exist in the backend
        const usersRef = doc(db, 'users', result.user.uid);
        const usersDoc = await getDoc(usersRef);
        if (!usersDoc.exists()) {
            await createDefaultUser(result.user);
        }

        return result.user;
    } catch (error) {
        console.error('Error during Google popup sign-in:', error.message);
        return null;
    }
};

// Function to sign in a user with email and password
export const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        console.error('Error signing in:', error.message);
        throw error;
    }
};

// Function to sign out the current user
export const signOutUser = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error('Error signing out:', error.message);
        throw error;
    }
};

// Function to subscribe to user authentication state changes
export const onAuthStateChange = (callback) => {
    return onAuthStateChanged(auth, callback);
};