import React  from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CustomText from "../atoms/CustomText";
import { PiArrowClockwiseBold, PiThumbsUpBold, PiClipboardBold } from "react-icons/pi";
import { getErrorMessage } from "../../utils/utils";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.isUser ? '30px' : '20px'};
  z-index: 1;
  gap: 12px;
  background-color: ${props => props.theme.colors.sidebarBackground};
`;

const InnerMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 65%;
  min-width: 65%;
  background-color: ${props => props.theme.colors.redFade};
  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 65%;
  min-width: 65%;
  gap: 2px;

  @media (max-width: 768px) {
    max-width: 90%;
    min-width: 90%;
  }
`;

const StyledMessage = styled(CustomText)`
  background-color: transparent;
  text-align: left;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 1.3;
  word-wrap: break-word;
  padding: 10px;
`;

const ClipboardIcon = styled(PiClipboardBold)`
    font-size: 1.1rem;
    color: ${props => props.theme.colors.text};
    margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const RetryIcon = styled(PiArrowClockwiseBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
`;

const ThumbsUpIcon = styled(PiThumbsUpBold)`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};
  margin-right: 5px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const ThumbsDownIcon = styled(ThumbsUpIcon)`
    transform: rotate(180deg);
`;

const ErrorMessage = ({ error, onRetry, onFeedback }) => {
    const { theme } = useTheme();

    const errorMessage = getErrorMessage(error);

    return (
        <MessageContainer theme={theme}>
            <InnerMessageContainer theme={theme}>
                <StyledMessage theme={theme}>
                    {errorMessage}
                </StyledMessage>
            </InnerMessageContainer>
            <ButtonsContainer>
                <ClipboardIcon theme={theme} size={20} onClick={() => navigator.clipboard.writeText(errorMessage)} />
                <ThumbsUpIcon theme={theme} size={20} onClick={() => onFeedback("good")} />
                <ThumbsDownIcon theme={theme} size={20} onClick={() => onFeedback("bad")} />
                <RetryIcon theme={theme} onClick={onRetry} size={20} />
            </ButtonsContainer>
        </MessageContainer>
    );
};

export default ErrorMessage;