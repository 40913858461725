import React from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import { useUser } from "../../contexts/UserContext";
import CustomText from "../atoms/CustomText";
import { cleanNodeType } from "../../utils/utils";
import { deleteAllTypeNodes } from "../../api/api";

const HeaderNodeOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //width: 100%;
  width: 140px;
  height: 140px;
  border-radius: 25px;
  background-color: ${props => props.theme.name === "dark" ? props.theme.colors.border : props.theme.colors.stroke};
  position: relative;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  
  &:hover {
    transform: translateY(-5px);
    border: .1px solid ${props => props.theme.colors.stroke};
  }
  
  &:active {
    transform: scale(0.98);
  }
  
  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const HeaderNodeInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
`;

const HeaderNodeText = styled(CustomText)`
  font-size: 1.1rem;
  max-width: 95%;
  overflow-wrap: break-word;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CountText = styled(CustomText)`
  display: flex;
  align-items: center;
  font-size: 1.35rem;
  opacity: 0.6;
  position: absolute;
  bottom: 8px;
  right: 12px;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 3px;
  right: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 30px;
  display: none;
  opacity: 0.6;
  
  ${HeaderNodeOuter}:hover & {
    display: flex;
  }
  
  &:hover {
    transform: scale(1.1);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
const ProfileHeaderNode = ({ type, count, onClick, expanded, color }) => {
    const { theme } = useTheme();
    const { user } = useUser();

    const capitalizedType = cleanNodeType(type);

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete every ${type}?`)) {
            await deleteAllTypeNodes(user.uid, user.selectedBackendProfileId, type);
            window.location.reload();
        }
    }

    return (
        <HeaderNodeOuter theme={theme} onClick={() => onClick(type)} style={{ backgroundColor: color }}>
            <HeaderNodeInner theme={theme}>
                <DeleteButton onClick={handleDelete} theme={theme}>
                    &times;
                </DeleteButton>
                <CountText type="medium">
                    {count}
                </CountText>
                {/*<MemoryCard size={20} theme={theme} />*/}
                <HeaderNodeText type="medium">
                    {capitalizedType}
                </HeaderNodeText>
                {/*<ChevronRight size={22} theme={theme}  />*/}
            </HeaderNodeInner>
        </HeaderNodeOuter>
    );
}

export default ProfileHeaderNode;