import axios from 'axios';

const apiClient = axios.create({
    baseURL: window.location.hostname === "therai.me" ? process.env.REACT_APP_API_BASE_URL_THERAI : window.location.hostname === "dept.me" ? process.env.REACT_APP_API_BASE_URL_ADEPT : process.env.REACT_APP_API_BASE_URL_OWNAI,
});

// Function to get a user's profile
export const getProfile = async (profileID) => {
    try {
        const response = await apiClient.get(`/api/profile/${profileID}`);
        return response.data;
    } catch (error) {
        // console.error('Error retrieving user profile:', error.response.data);
        throw error;
    }
};

// Function to create checkout session
export const createCheckoutSession = async (firebaseUserId, userEmail, planTitle, stripeId) => {
    try {
        // Replace this URL with your backend endpoint for creating Stripe Checkout sessions
        const response = await apiClient.post('/api/checkout/stripe', {
            userId: firebaseUserId,
            stripeId: stripeId,
            userEmail: userEmail,
            planTitle: planTitle
        });
        return response.data;
    } catch (error) {
        console.error('Error creating checkout session:', error);
    }
};

// Function to send a message
export const sendMessage = async (messageData, files) => {
    const formData = new FormData();

    // Append message data as JSON
    formData.append('messageData', JSON.stringify(messageData));

    // Append files
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
    }

    try {
        const response = await apiClient.post('/api/message/send', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        // console.error('Error sending message:', error.response.data);
        throw error;
    }
};

export const speechToText = async (audioFile) => {
    const formData = new FormData();
    formData.append('audioFile', audioFile);

    try {
        const response = await apiClient.post('/api/stt', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        // console.error('Error converting speech to text:', error.response.data);
        throw error;
    }
}

export const deleteNode = async (userID, profileID, nodeID) => {
    try {
        const response = await apiClient.delete(`/api/user/${userID}/profile/${profileID}/node/${nodeID}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting node:', error.response.data);
        throw error;
    }
}

export const deleteAllTypeNodes = async (userID, profileID, nodeType) => {
    try {
        const response = await apiClient.delete(`/api/user/${userID}/profile/${profileID}/nodeType/${nodeType}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting nodes:', error.response.data);
        throw error;
    }
}

export const createNode = async (nodeData) => {
    try {
        const response = await apiClient.post(`/api/node/create`, nodeData);
        return response.data;
    } catch (error) {
        console.error('Error creating node:', error.response.data);
        throw error;
    }
}

export const resetProfile = async (userID, profileID) => {
    try {
        const response = await apiClient.delete(`/api/user/${userID}/profile/${profileID}`);
        return response.data;
    } catch (error) {
        console.error('Error resetting profile:', error.response.data);
        throw error;
    }
}

// Function to send feedback
export const sendFeedback = async (feedbackData) => {
    try {
        const response = await apiClient.post('/api/feedback/send', feedbackData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response.data);
        throw error;
    }
}