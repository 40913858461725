import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "./firebaseConfig";

// Function to upload a Blob to Firebase Storage
const uploadBlobToFirebase = async (blob, imageName) => {
    const storageRef = ref(storage, `images/${imageName}`);
    try {
        await uploadBytes(storageRef, blob);
        return await getDownloadURL(storageRef);
    } catch (error) {
        console.error("Error uploading blob to Firebase Storage", error);
        throw error;
    }
};

// Function to convert base64 to Blob
const base64ToBlob = (base64, mimeType) => {
    // Remove data URL prefix if present
    const base64Cleaned = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');

    try {
        const byteString = atob(base64Cleaned);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeType });
    } catch (error) {
        console.error("Error converting base64 to Blob:", error);
        throw error;
    }
};

// Handle the base64 JSON response
export const handleBase64Response = async (base64Json) => {
    const base64String = base64Json; // Assuming the base64 data is in the 'data' field
    const mimeType = "image/png"; // Change as needed
    const imageBlob = base64ToBlob(base64String, mimeType);
    const imageName = `image-${Date.now()}`;

    try {
        return await uploadBlobToFirebase(imageBlob, imageName);
    } catch (error) {
        console.error("Error handling base64 response:", error);
    }
};