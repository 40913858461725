import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from "../../contexts/ThemeContext";
import CustomText from '../atoms/CustomText';
import CustomToggle from '../atoms/Toggle';

const SettingToggleButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  width: 100%;
  position: relative;
  
  @media (max-width: 1600px) {
    gap: 20px;
  }
    
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
    width: calc(100% - 30px);
  }
`;

const SettingToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: ${(props) => props.theme.colors.sidebarBackground};
  border-radius: 15px;
  padding: 12px;
  cursor: pointer;
  opacity: ${(props) => (props.enabled ? '1' : '0.6')};
`;

const SettingToggleButtonDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
`;

const SettingToggleButtonText = styled(CustomText)`
  font-size: 1.05em;
  margin-left: 10px;
  white-space: nowrap;
  
  @media (max-width: 800px) {
    font-size: 0.9em;
    margin-left: 5px;
  }
`;

const SettingToggleButton = ({ toggleText, toggleIcon, toggled, onToggleClick }) => {
    const { theme } = useTheme();
    const [enabled, setEnabled] = useState(toggled);

    const iconSize = window.innerWidth < 1400 ? window.innerWidth < 800 ? 20 : 22 : 24;

    const handleToggle = () => {
        const newToggledState = !enabled;
        setEnabled(newToggledState);
        onToggleClick(newToggledState);
    };

    return (
        <SettingToggleButtonContainer theme={theme} enabled={enabled}>
            <SettingToggleButtonDescriptionContainer  onClick={handleToggle}>
                {React.cloneElement(toggleIcon, { size: iconSize, color: theme.colors.darkGray })}
                <SettingToggleButtonText theme={theme}>
                    {toggleText}
                </SettingToggleButtonText>
            </SettingToggleButtonDescriptionContainer>
            <CustomToggle isToggled={enabled} onToggle={handleToggle} />
        </SettingToggleButtonContainer>
    );
}

const UnsavedChangesText = styled(CustomText)`
  position: absolute;
  top: -25px;
  right: 0;
  font-size: .8em;
  opacity: 0.6;
  
  &:hover {
    cursor: pointer;
  }
`;

const SettingToggleButtons = ({ toggleButtons, unsavedChanges }) => {
    const { theme } = useTheme();

    const scrollDownToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }

    return (
        <SettingToggleButtonsContainer>
            <UnsavedChangesText theme={theme} onClick={scrollDownToBottom}>
                {unsavedChanges ? 'Unsaved changes' : ''}
            </UnsavedChangesText>
            {toggleButtons.map((toggleButton, index) => {
                return (
                    <SettingToggleButton
                        key={index}
                        toggleText={toggleButton.toggleText}
                        toggleIcon={toggleButton.toggleIcon}
                        toggled={toggleButton.toggled}
                        onToggleClick={toggleButton.onToggleClick}
                    />
                );
            })}
        </SettingToggleButtonsContainer>
    );
};

export default SettingToggleButtons;